<template lang="pug">
    .ccp.text-center
        p {{ voucher.suggested_for }}

        .ccp__btn {{ voucher.redeemable ? "Redeem" : "Share" }}
</template>

<script>
export default {
    props: ['voucher'],
}
</script>

<style lang="scss" scoped>
.ccp {
    p {
        font-size: 0.7rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 5px;
    }

    &__btn {
        display: inline-block;
        font-size: 0.7rem;
        font-weight: 500;
        background: $nack-primary;
        color: white;
        padding: 5px;
        border-radius: 10px;
        text-transform: uppercase;
    }
}
</style>
