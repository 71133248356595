<template lang="pug">
    .card
        .card__body.card__body--lg.editor__card-body
            .alert.alert-warning.mt-3(v-if='alert') {{ alert }}

            template(v-if='model.vouchers.length === 0')
                h5.editor__title Looking good! What type of gift would you like to send?
                .gift-options
                    .gift(@click='choose(2)')
                        .gift__title Enjoy + Share
                        .gift__image: img(src='./icons/two_coffees.png')
                        .gift__description.
                            <strong>This is the one we recommend.</strong>
                            Your recipient will get one coffee they can enjoy,
                            and one they can share with whomever they wish.
                            The second coffee will still have your branding.
                    .gift(@click='choose(1)')
                        .gift__title Enjoy
                        .gift__image: img(src='./icons/one_coffee.png')
                        .gift__description.
                            Your recipient will get one coffee they can enjoy.
                            They'll also have the option to share it instead.
                    .gift(@click='choose(-1)')
                        .gift__title Share
                        .gift__image: img(src='./icons/one_coffee.png')
                        .gift__description.
                            Your recipient will get one coffee they can share.
                            They will not have the option to claim it for their consumption.

                .text-center Nothing catching your eye? <a href="#" @click.prevent='choose(1)'>Send a custom gift!</a>

            template(v-else)
                template(v-if='!readonly')
                    h5.editor__title Nice pick! Care to customize the coffees?
                    p.editor__subtitle.mb-0 Feel free to skip this step if you're happy with the defaults.
                template(v-else)
                    h5.editor__title Here are the coffees you created for each gift.
                    p.editor__subtitle.mb-0 For consistency, they can't be changed.

                .custom-coffee.form(v-for='voucher, idx in model.vouchers')
                    h5.editor__title.text-left
                        | {{ numbers[idx] ? `${numbers[idx]} Coffee` : `Coffee ${idx + 1}` }}
                    .custom-coffee__fields
                        .custom-coffee__for
                            img(src='~/corporate/components/campaign-editor/icons/one_coffee.png')
                            span This coffee is
                            input.form-control(v-model='voucher.suggested_for' :disabled='readonly')
                        .custom-coffee__redeemable
                            b-form-checkbox(v-model='voucher.redeemable' :disabled='readonly') Redeemable by recipient
                        .custom-coffee__remove(v-if='idx > 0 && !readonly' @click='remove(idx)'): i.fal.fa-trash-alt

                .mt-5
                    .float-left
                        b-btn(variant='-white' @click='add' v-if='!readonly')
                            i.fas.fa-plus.mr-2
                            | Add Another
                    .float-right
                        b-btn(variant='primary' @click='$emit("next")') Next
</template>

<script>
import _ from 'lodash'

const FOR_YOU = { commodity: 1, redeemable: true, suggested_for: 'for you' }
const FOR_A_FRIEND = { commodity: 1, redeemable: false, suggested_for: 'for a friend' }

export default {
    props: ['model'],

    data() {
        return {
            alert: false,
            readonly: !!this.model.id,
            numbers: ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth'],
        }
    },

    methods: {
        choose(value) {
            if (value === 1) {
                this.model.vouchers = [{ ...FOR_YOU }]
            } else if (value === 2) {
                this.model.vouchers = [{ ...FOR_YOU }, { ...FOR_A_FRIEND }]
            } else if (value === -1) {
                this.model.vouchers = [{ ...FOR_A_FRIEND }]
            }
            this.alert = false
        },

        add() {
            this.model.vouchers = [...this.model.vouchers, { ...FOR_A_FRIEND }]
        },

        remove(idx) {
            this.model.vouchers.splice(idx, 1)
        },

        validate() {
            this.alert = false

            if (!this.model.vouchers.length) {
                this.alert = 'Please make a selection.'
            }

            _.each(this.model.vouchers, (v) => {
                if (!v.suggested_for) this.alert = 'Please specify who the coffees are for.'
                if (v.suggested_for.length > 20) this.alert = 'Coffee suggestions must be 20 characters or less.'
            })

            return !this.alert
        },
    },
}
</script>

<style lang="scss" scoped>
.gift-options {
    display: flex;
    margin: 0 auto;
}

.gift {
    @include shadow;
    flex: 1 1 1px;
    border: 2px solid #ededed;
    border-radius: 20px;
    padding: 15px;
    margin: 20px;
    text-align: center;

    &:hover {
        cursor: pointer;
        border-color: #d1d1d1;
    }

    &__image {
        text-align: center;
        padding: 10px 0;

        img {
            height: 75px;
        }
    }

    &__title {
        font-size: 1.2rem;
        font-weight: 500;
        color: #767676;
        text-transform: uppercase;
    }

    &__description {
        color: #444;
        font-size: 0.7rem;
    }
}

.custom-coffee {
    margin: 30px 0;

    &__number {
        font-weight: 500;
        color: #767676;
        margin-bottom: 5px;
    }

    span {
        padding-right: 5px;
    }

    input.form-control {
        display: inline-block;
        width: 200px;
    }

    &__fields {
        display: flex;
    }

    &__redeemable {
        flex: 1 1;
        padding-left: 30px;
        padding-top: 12px;
    }

    img {
        height: 24px;
        position: relative;
        top: -3px;
        margin-right: 5px;
    }

    span {
        color: #767676;
        margin-right: 5px;
    }

    &__remove {
        flex: 0 0 20px;
        display: inline-block;
        margin-left: 10px;
        font-size: 1.4rem;
        align-self: center;
        color: $nack-orange;

        &:hover {
            cursor: pointer;
        }
    }
}
</style>
