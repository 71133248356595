<template lang="pug">
    .auth
        .auth__photo
        .auth__content.text-center
            v-form(:context='this')
                h5 Please enter your password.
                v-field(
                    :model='model.password'
                    field='input'
                    placeholder='Password'
                    type='password'
                )
                b-btn.btn-block.mt-2(variant='primary' type='submit') Continue
</template>

<script>
import { FormMixin } from 'core/components/forms/mixin'

export default {
    mixins: [FormMixin],

    fields: ['password'],

    dispatch: 'upgradeSession',
}
</script>
