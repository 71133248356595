<template lang="pug">
    .card
        .card__body.card__body--lg.editor__card-body
            h5.editor__title What type of call to action would you like?
            p.editor__subtitle.
                A call to action is a button that encourages the recipient to do something.
                You can also require it.

            .multipicker.mb-3
                .multipicker__item(
                    v-for='opt in ctaOptions'
                    :key='opt.value'
                    @click='model.cta_type = opt.value'
                    :class='{"multipicker__item--active": model.cta_type == opt.value}'
                )
                    .multipicker__icon: i(:class='opt.icon')
                    .multipicker__label {{ opt.name }}

            .form
                v-form-group(
                    v-if='model.cta_type'
                    disabled
                    description='If required, the recipient must do this before claiming their coffee.'
                )
                    b-form-checkbox(disabled v-model='model.cta_required') Required (Coming Soon)

                v-form-group(
                    v-if='model.cta_type'
                    label='Call to Action Label'
                    description='What would you like the button to say?'
                    :error='errors.cta_label'
                )
                    b-form-input(v-model='model.cta_label')

                template(v-if='model.cta_type == "link"')
                    v-form-group(
                        label='Link'
                        description='Where would you like to send the recipient?'
                        :error='errors.cta_url'
                    )
                        b-form-input(v-model='model.cta_url' placeholder='https://myawesomesite.com')
                template(v-else-if='model.cta_type == "app"')
                    v-form-group(
                        label='iOS App ID'
                        description="You can find this in your App Store link.<br/>Here's an example: https://itunes.apple.com/us/app/example/id<strong>123456789</strong>?mt=8"
                        :error='errors.cta_ios_app_id'
                    )
                        b-form-input(v-model='model.cta_ios_app_id' placeholder='123456789')

                    v-form-group(
                        label='Android Bundle ID'
                        description="You can find this in your Play Store link.<br/>Here's an example: https://play.google.com/store/apps/details?id=<strong>com.example.app</strong>&hl=en_US"
                        :error='errors.cta_android_bundle_id'
                    )
                        b-form-input(v-model='model.cta_android_bundle_id' placeholder='com.example.app')

                    //- v-form-group(
                        label='App URL Scheme'
                        description="We need your app's URL scheme to verify that your recipient actually downloaded your app."
                        :error='errors.cta_value'
                    //- )
                        b-form-input(v-model='model.cta_value')
                    //- a.small.d-block(href='#' @click.prevent='' style='margin-top: -15px') You can learn more here.
                template(v-if='model.cta_type == "social"')
                    //- TODO: We'll need a social network picker, and then a field for the username/id
                    v-form-group(
                        label='Social Media Page'
                        description='What is your page?'
                        :error='errors.cta_value'
                    )
                        b-form-input(v-model='model.cta_value')

            .float-right
                b-btn(variant='primary' @click='$emit("next")') Next
</template>

<script>
import _ from 'lodash'

const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/

export default {
    props: ['model'],

    data() {
        return {
            ctaOptions: [
                {
                    icon: 'fal fa-times',
                    name: 'None',
                    value: null,
                    label: null,
                },
                {
                    icon: 'fal fa-link',
                    name: 'Visit Link',
                    value: 'link',
                    label: 'Visit our site',
                },
                {
                    icon: 'fal fa-mobile',
                    name: 'Download App',
                    value: 'app',
                    label: 'Get our app',
                },
                // {
                //     icon: 'fal fa-thumbs-up',
                //     name: 'Follow Social Page',
                //     value: 'social',
                //     label: 'Like our page',
                // },
            ],

            errors: {},
        }
    },

    methods: {
        validate() {
            const data = this.model
            this.errors = {}

            if (!data.cta_label && data.cta_type) {
                this.errors.cta_label = 'Please fill this out.'
            }

            if (data.cta_type === 'link') {
                if (!data.cta_url || !data.cta_url.match(urlRegex)) {
                    this.errors.cta_url = 'Please enter a URL. Make sure it starts with http:// or https://'
                }
            } else if (data.cta_type === 'app') {
                if (!data.cta_ios_app_id || !/^\d+$/.test(data.cta_ios_app_id)) {
                    this.errors.cta_ios_app_id = 'Please enter a valid ID. It should only contain numbers.'
                }
                if (!data.cta_android_bundle_id) {
                    this.errors.cta_android_bundle_id = 'Please enter a valid ID.'
                }
            } else if (data.cta_type === 'social') {
                // TODO: Add when social is implemented
            }

            return !Object.keys(this.errors).length
        },
    },

    watch: {
        'model.cta_type': function ctaTypeChanged(val) {
            const options = _.keyBy(this.ctaOptions, 'value')
            this.model.cta_label = options[val].label
            this.model.cta_url = null
            this.model.cta_ios_app_id = null
            this.model.cta_android_bundle_id = null
            this.model.cta_app_protocol = null
            this.errors = {}
        },
    },
}
</script>
