<template lang="pug">
    .card.card__body.card__body--lg
        h4.card__title.text-left
            | Campaigns
            router-link.btn.btn-primary.float-right(to='/corporate/edit-campaign')
                i.fa.fa-plus.mr-2
                | New Campaign

        table.table.table-responsive-lg.table--card
            thead
                th Status
                th Image
                th Label
                th Sender
                th Gifts Claimed
            tbody
                campaign-row(v-for='campaign in sortedCampaigns' :key='campaign.id' :id='campaign.id')
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import CampaignRow from './campaign-row'

export default {
    components: { CampaignRow },

    computed: {
        sortedCampaigns() {
            return _.sortBy(this.campaigns, c => -c.id)
        },

        ...mapState({
            campaigns: state => state.corporate.campaigns,
        }),
    },
}
</script>
