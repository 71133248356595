import { render, staticRenderFns } from "./nearby-shops.vue?vue&type=template&id=05193fac&scoped=true&lang=pug&"
import script from "./nearby-shops.vue?vue&type=script&lang=js&"
export * from "./nearby-shops.vue?vue&type=script&lang=js&"
import style0 from "./nearby-shops.vue?vue&type=style&index=0&id=05193fac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05193fac",
  null
  
)

export default component.exports