<template lang="pug">
    v-form(:context='this')
        modal-header {{ mode }} {{ vendor.name }} Location

        .modal-body
            v-field(
                :model='model.name'
                field='input'
                label='Location Name'
                placeholder='South Broadway'
                hint='This is really just for internal purposes, though we may display it in the future.'
            )

            v-field(
                :model='model.street_address'
                field='input'
                label='Street Address'
                placeholder='1 N Main St'
            )

            .row
                .col-md-6
                    v-field(
                        :model='model.city'
                        field='input'
                        label='City'
                        placeholder='Providence'
                    )
                .col-md-2
                    v-field(
                        :model='model.state'
                        field='input'
                        label='State'
                        placeholder='RI'
                    )
                .col-md-4
                    v-field(
                        :model='model.zip_code'
                        field='input'
                        label='Zip Code'
                        placeholder='02903'
                    )

            v-field(
                :model='model.active'
                hint='Whether the location is still open or not.'
            )
                b-form-checkbox(v-model='model.active.value') Enabled

        .modal-footer
            b-btn.mt-2(variant='primary' type='submit') Continue
</template>

<script>
import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    props: ['vendor'],

    mixins: [ModalFormMixin],

    fields: [
        'name',
        'street_address',
        'city',
        'state',
        'zip_code',
        { name: 'active', default: true },
    ],

    dispatch: 'saveVendorLocation',

    methods: {
        clean(data) {
            data.vendor = this.vendor.id
            return data
        },
    },
}
</script>
