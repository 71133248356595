import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import VueAnalytics from 'vue-analytics'
import Croppa from 'vue-croppa'
import VueScrollTo from 'vue-scrollto'

import '@/scss/styles.scss'
import 'core/util/drf'
import 'core/util/filters'
import 'core/components/registry'
import router from 'core/router'
import store from 'core/store'
import App from 'core/components/app'

// Configure Vue before the rest of the app is imported
Vue.config.productionTip = false
Vue.use(VueAnalytics, {
    id: 'UA-127713099-1',
    router,
})
Vue.use(BootstrapVue)
Vue.use(Croppa)
Vue.use(VueScrollTo)

// Axios
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.headers['Api-Version'] = `${window.API_VERSION}`

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
