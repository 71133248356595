<template lang="pug">
    .footer
        .footer__stripe.footer__stripe--accent

        section
            .container
                .row.row-eq-height
                    .col-md-4.order-md-3
                        .footer__area.footer__area
                            .footer__apps
                                a(href='https://itunes.apple.com/us/app/nack/id1439154311?mt=8' target='_blank')
                                    img.getit__store(
                                        alt='Download on the App Store' src='~landing/images/app-store.svg'
                                    )

                                a(
                                    href='https://play.google.com/store/apps/details?id=com.getnack.barista&hl=en_US'
                                    target='_blank'
                                )
                                    img(alt='Get it on Google Play' src='~landing/images/google-play-badge.png')

                    .col-md-4.order-md-1
                        .footer__area
                            .footer__area-content
                                a(href='mailto:info@getnack.com').footer__info
                                    i.fas.fa-envelope
                                    span info@getnack.com
                                router-link(to='/terms').footer__info
                                    i.fas.fa-handshake
                                    span Terms of Use
                                router-link(to='/privacy').footer__info
                                    i.fas.fa-shield-check
                                    span Privacy Policy

                    .col-md-4.order-md-2.text-center
                        img.footer__rac(
                            alt='Practice Random Acts of Coffee'
                            src='~landing/images/random-acts-of-coffee.png'
                        )


        p.footer__signature.
            Made with <i class="fas fa-heart"></i> in Providence, RI + Portland, ME
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer {
    background: $nack-dark;

    section {
        padding-bottom: 40px;
    }

    &__stripe {
        height: 18px;

        &--white {
            background: white;
        }

        &--accent {
            background: $nack-accent;
        }
    }

    &__rac {
        height: 200px;
    }

    &__area {
        padding: 20px 0;
        @include flex-align-center;
        height: 100%;

        @include media-breakpoint-up(md) {
            justify-content: flex-start;
        }
    }

    &__info {
        display: block;
        color: #dbdbdb;
        margin-bottom: 20px;

        i {
            font-size: 25px;
            width: 35px;
            text-align: center;
        }

        span {
            margin-left: 10px;
            font-size: 20px;
        }
    }

    &__apps {
        text-align: center;

        @include media-breakpoint-up(md) {
            text-align: right;
        }
    }

    &__apps > a {
        display: block;

        img {
            width: 50%;
        }

        &:last-child {
            margin-top: 20px;
        }
    }

    &__signature {
        color: $nack-dark-l-3;
        text-align: center;
        padding-bottom: 10px;
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 0.5px;
    }
}
</style>
