import _ from 'lodash'
import uuid from 'uuid/v4'

// eslint-disable-next-line
export const jsonToFormData = (j) => {
    const data = new FormData()
    _.each(j, (val, key) => {
        if (val !== null) {
            if (val instanceof Blob) {
                let suffix = 'jpg'
                if (key === 'svg' || key === 'png') suffix = key
                data.append(key, val, `${uuid()}.${suffix}`)
            } else {
                data.append(key, val)
            }
        }
    })
    return data
}
