import Vue from 'vue'
import Vuex from 'vuex'

import accountsStore from 'accounts/store'
import adminStore from 'admin/store'
import corporateStore from 'corporate/store'
import giftsStore from 'gifts/store'

export const coreStore = {

}

// Set up the root store
Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        core: coreStore,
        accounts: accountsStore,
        admin: adminStore,
        corporate: corporateStore,
        gifts: giftsStore,
    },
})

export default store
