import Vue from 'vue'
import _ from 'lodash'
import moment from 'moment'

/**
 * Makes lodash functions available as filters.
 */
Vue.filter('startCase', _.startCase)
Vue.filter('truncate', _.truncate)
Vue.filter('capitalize', _.capitalize)

/**
 * Create a string with the passed number and the singular or plural word,
 * as appropriate.
 * @param  {Number} num      the number to display and base singular/plural on
 * @param  {String} singular the word to display when num is 1
 * @param  {String} plural   the world to display when num != 1
 * @return {String}           i.e. "1 function" or "2 functions"
 */
export const pluralize = (num, singular, plural) => {
    if (!plural) plural = `${singular}s`
    return `${num} ${num === 1 ? singular : plural}`
}
Vue.filter('pluralize', pluralize)

/**
 * Convert a date string (or moment) into the specified format.
 * @param  {String} dt                original date string / moment
 * @param  {String} [format='M/D/YY'] a moment-compatible format
 * @return {String}                   formatted date string
 */
export const date = (dt, format = 'M/D/YY') => {
    const m = moment(dt)
    if (!m.isValid()) return undefined
    return m.format(format)
}
Vue.filter('date', date)

/**
 * Convert a datetime string (or moment) into the specified format.
 * @param  {String} dt                       original datetime string / moment
 * @param  {String} [format='M/D/YY h:mm a'] a moment-compatible format
 * @return {String}                          formatted datetime string
 */
export const datetime = (dt, format = 'M/D/YY h:mm a') => {
    const m = moment(dt)
    if (!m.isValid()) return undefined
    return m.format(format)
}
Vue.filter('datetime', datetime)

/**
 * Calculate the difference in time from the passed date to now.
 * @param  {String} dt   date string / moment
 * @return {String}      difference in time
 */
export const timeFromNow = dt => moment(dt).fromNow()
Vue.filter('timeFromNow', timeFromNow)

/**
 * Sorts the items by a key (defaulting to name).
 * @param  {Array}  items items to sort
 * @param  {String} key   key to sort by
 * @return {Array}        sorted items
 */
export const sortItems = (items, key = 'name') => _.sortBy(items, key)
Vue.filter('sort', sortItems)

/**
 * Returns "Add" or "Edit" based on the id.
 *
 * @param  {Integer} int the id
 * @return {String}
 */
Vue.filter('addOrEdit', id => (id ? 'Edit' : 'Add'))
