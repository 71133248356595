import _ from 'lodash'
import Vue from 'vue'

export const genericError = "Sorry, the coffee machine's jammed. Please try again."

export const noInternetError = "It looks like you aren't connected to the internet."

export const errorTranslations = {
    'This field may not be null.': 'Please fill this out.',
    'This field may not be blank.': 'Please fill this out.',
    'This field is required.': 'Please fill this out.',
    'Incorrect type. Expected pk value, received str.': 'Please fill this out.',
    'Please enter a correct username and password. Note that both fields may be case-sensitive.': "Hmm, that information doesn't seem right. Please try again.", // eslint-disable-line
}

export const translateErrors = (errors, customTranslations) => {
    const translations = { ...errorTranslations, ...customTranslations }
    if (typeof errors === 'string') errors = [errors]
    errors = _.map(errors, error => translations[error] || error)
    return errors.join('\n')
}

export const parseError = (error, customTranslations) => {
    // Extract the information from the error
    let data = {}
    let status = null
    if (error.response) ({ data, status } = error.response)

    // Extract the top-level and field errors
    let overallError = null
    const fieldErrors = {}

    if (status >= 500) {
        overallError = genericError
    } else if (status === 400) {
        if (!_.isArray(data)) {
            _.each(data, (err, field) => {
                if (field === 'non_field_errors' || field === '__all__') {
                    overallError = err
                } else {
                    fieldErrors[field] = err
                }
            })
        } else {
            overallError = data
        }
    } else if (data.detail) {
        overallError = data.detail
    } else if (status !== null) {
        overallError = genericError
    } else {
        overallError = noInternetError
    }

    // Make the raw objects available
    const result = {
        raw: { overallError, fieldErrors },
        status,
    }

    // Make translated versions available
    const tFieldErrors = { ...fieldErrors }
    _.each(tFieldErrors, (val, field) => {
        tFieldErrors[field] = translateErrors(val, customTranslations)
    })
    result.translated = {
        overallError: translateErrors(overallError),
        fieldErrors: tFieldErrors,
    }

    // Make a squashed version available
    result.squashed = result.translated.overallError
    if (!result.squashed) {
        result.squashed = translateErrors(_.map(result.translated.fieldErrors).join('\n'), customTranslations)
    }

    return result
}

Vue.prototype.$drf = { parseError }
