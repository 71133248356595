<template lang="pug">
    prepare
        template(slot-scope='props')
            .container.internal-page.pt-4
                .row
                    .col-md-9.col-sm-8.mb-4
                        .steps
                            .steps__step(
                                v-for='s, idx in steps'
                                :class='{"steps__step--active": idx == step, "steps__step--enabled": stepEnabled(idx)}'
                                @click='jumpToStep(idx)'
                            )
                                .steps__circle
                                    .steps__circle-inner
                                        i.steps__icon(:class='s.icon')
                                .steps__label {{ s.name }}

                        template(v-if='!loading')
                            .alert.alert-warning.mt-3(v-if='alert') {{ alert }}
                            component(
                                v-for='s, idx in steps'
                                :is='s.component'
                                v-show='step == idx'
                                :model.sync='model'
                                @next='nextStep'
                                :ref='`step${idx}`'
                                :key='idx'
                            )
                        .card(v-else)
                            .card__body
                                spinner.ml-auto.mr-auto

                    .col-md-3.col-sm-4.mb-4
                        campaign-preview(:model='model' v-if='highestStep > 0' @jump='jumpToStep')
</template>

<script>
import Q from 'q'
import { mapActions } from 'vuex'

import CampaignPreview from './campaign-preview'
import DesignStep from './design-step'
import BasicInfoStep from './basic-info-step'
import BuildGiftStep from './build-gift-step'
import CallToActionStep from './call-to-action-step'
import BudgetStep from './budget-step'
import VendorsStep from './vendors-step'
import DeliveryStep from './delivery-step'

export default {
    components: { CampaignPreview },

    data() {
        return {
            alert: null,
            loading: false,
            model: {
                emoji: [],
                background: null,
                custom_background: null,

                internal_label: null,
                sender_name: this.$store.state.corporate.company.name,
                sender_name2: null,
                sender_photo: null,
                message: null,

                vouchers: [],

                cta_type: null,
                cta_label: null,
                cta_url: null,
                cta_ios_app_id: null,
                cta_android_bundle_id: null,
                cta_app_protocol: null,
                cta_required: false,

                quantity: 1,
                per_voucher_fee: this.$store.state.corporate.company.per_voucher_fee,
                per_gift_fee: this.$store.state.corporate.company.per_gift_fee,

                fixed_vendor: null,

                coffee_code: null,
            },

            step: 0,
            highestStep: 0,
            steps: [
                {
                    name: 'Design',
                    component: DesignStep,
                    icon: 'far fa-image',
                },
                {
                    name: 'Basic Info',
                    component: BasicInfoStep,
                    icon: 'far fa-pencil',
                },
                {
                    name: 'Build Your Gift',
                    component: BuildGiftStep,
                    icon: 'fas fa-gift',
                },
                {
                    name: 'Call to Action',
                    component: CallToActionStep,
                    icon: 'fas fa-directions',
                },
                {
                    name: 'Budget',
                    component: BudgetStep,
                    icon: 'fas fa-dollar-sign',
                },
                {
                    name: 'Vendors',
                    component: VendorsStep,
                    icon: 'fas fa-coffee-togo',
                },
                {
                    name: 'Delivery',
                    component: DeliveryStep,
                    icon: 'fas fa-hand-holding-usd',
                },
            ],
        }
    },

    computed: {
        currentStep() {
            return this.steps[this.step]
        },
    },

    methods: {
        prepare() {
            const queue = [
                this.loadCorporatePresets(),
            ]
            if (this.$route.params.id) {
                queue.push(this.loadCampaign(this.$route.params.id).then((response) => {
                    this.model = response.data
                    this.$set(this.model, 'quantity', response.data.quantity_total)
                }))
                this.highestStep = this.steps.length
            }
            return Q.all(queue)
        },

        nextStep() {
            if (this.steps.length === (this.step + 1)) {
                // Finish
                this.finish()
            } else {
                this.jumpToStep(this.step + 1)
            }
        },

        validateThrough(idx) {
            // Make sure the all the steps up to this step are valid
            for (let s = 0; s < idx; s++) {
                if (!this.$refs[`step${s}`][0].validate()) {
                    this.step = s
                    return false
                }
            }
            return true
        },

        jumpToStep(idx) {
            // Make sure the all the steps up to this step are valid
            if (!this.validateThrough(idx)) return

            // Jump to the step
            this.step = idx

            // Update the highest step if this is higher
            if (this.step > this.highestStep) this.highestStep = this.step
        },

        stepEnabled(idx) {
            if (this.model.id) return true
            return idx <= this.highestStep && idx !== this.step
        },

        finish() {
            if (!this.validateThrough(this.steps.length)) return

            // Clean the data
            const instance = { ...this.model }
            if (instance.fixed_vendor) instance.fixed_vendor = instance.fixed_vendor.id
            if (!instance.background) delete instance.background
            if (!instance.custom_background) delete instance.custom_background
            if (instance.sender_photo && instance.sender_photo.substr(0, 8) === 'https://') delete instance.sender_photo
            if (typeof instance.background === 'object') instance.background = instance.background.id

            this.alert = null
            this.loading = true
            this.saveCampaign({ instance }).then((response) => {
                this.$router.push(`/corporate/campaigns/${response.data.id}`)
                if (instance.custom_background) this.loadBackgrounds({ url: '/api/corporate/backgrounds/' })
            }, (error) => {
                this.alert = this.$drf.parseError(error).squashed
                this.loading = false
            })
        },

        ...mapActions(['loadCampaign', 'loadCorporatePresets', 'saveCampaign', 'loadBackgrounds']),
    },

    watch: {
        currentStep(s) {
            this.$ga.event('campaign-editor', 'view-step', s.name)
        },
    },
}
</script>

<style lang="scss" scoped>
.steps {
    display: flex;
    margin-bottom: 20px;

    &__step {
        flex: 1;
        text-align: center;

        &--enabled {
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    &__circle {
        display: inline-block;
        width: 50px;
        height: 50px;
        background: white;
        border-radius: 50%;
        padding: 3px;
        @include shadow;

        .steps__step--active & {
            background: $nack-primary;
        }
    }

    &__circle-inner {
        @include flex-align-center;
        border: 3px solid $nack-primary;
        border-radius: 50%;
        width: 100%;
        height: 100%;

        .steps__step--active & {
            border-color: white;
        }
    }

    &__icon {
        color: $nack-primary;
        font-size: 1.4rem;

        .steps__step--active & {
            color: white;
        }
    }

    &__label {
        font-size: 0.75rem;
        color: #3E3E3E;
        margin-top: 5px;
        text-transform: uppercase;
        font-weight: 500;
    }

    @include media-breakpoint-down(sm) {
        display: block;
        text-align: center;

        &__step {
            display: inline-block;
            margin: 0 20px 20px 0;
        }
    }
}
</style>

<style lang="scss">
.editor {
    &__card-body {
        padding-top: 15px;
    }

    &__title {
        margin-top: 1rem;
        color: #767676;
        text-align: center;
        font-weight: 500;
    }

    &__subtitle {
        text-align: center;
        color: #767676;
    }
}
</style>
