import { render, staticRenderFns } from "./campaign-page.vue?vue&type=template&id=799908fb&scoped=true&lang=pug&"
import script from "./campaign-page.vue?vue&type=script&lang=js&"
export * from "./campaign-page.vue?vue&type=script&lang=js&"
import style0 from "./campaign-page.vue?vue&type=style&index=0&id=799908fb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "799908fb",
  null
  
)

export default component.exports