<template lang="pug">
    .fixed-scroll(ref='el')
        .fixed-scroll__content(
            ref='content'
            :class='{"fixed-scroll__content--fixed": fixed, "fixed-scroll__content--done": done}'
        )
            slot
        .fixed-scroll__placeholder(:style='`height: ${totalHeight}px;`')
</template>

<script>
export default {
    props: ['distance'],

    data() {
        return {
            fixed: false,
            done: false,

            contentHeight: 0,
            extraHeight: this.distance,
            totalHeight: 0,
        }
    },

    methods: {
        onResize() {
            this.contentHeight = this.$refs.content.offsetHeight
            this.totalHeight = this.contentHeight + this.extraHeight
        },

        checkScroll() {
            const offsetTop = this.$refs.el.getBoundingClientRect().top
            if (!this.fixed) {
                const above = offsetTop > 0
                const below = offsetTop < -this.extraHeight
                if (!above && !below) {
                    this.fixed = true
                    this.done = false
                } else {
                    this.done = below
                }
            } else if (offsetTop > 0 || offsetTop < -this.extraHeight) {
                this.done = offsetTop < -this.extraHeight
                this.fixed = false
            } else {
                this.$emit('scroll', -offsetTop / this.extraHeight)
            }
        },
    },

    mounted() {
        this.onResize()
        window.addEventListener('scroll', this.checkScroll)
        window.addEventListener('resize', this.onResize)
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.checkScroll)
        window.removeEventListener('resize', this.onResize)
    },
}
</script>

<style lang="scss" scoped>
.fixed-scroll {
    position: relative;
    overflow: hidden;

    &__content {
        background: white;
        min-height: 100vh;

        &--fixed {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
        }

        &--done {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }
}
</style>
