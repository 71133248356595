<template lang="pug">
    .gift-background(:style='style')
        slot
</template>

<script>
export default {
    props: {
        background: {},

        thumb: {
            type: Boolean,
        },
    },

    computed: {
        backgroundData() {
            if (typeof this.background === 'number') {
                return this.$store.state.admin.backgrounds[this.background]
            }
            return this.background
        },

        style() {
            const bg = this.backgroundData

            if (bg.colors) {
                if (bg.colors.length === 1) {
                    return {
                        background: bg.colors[0],
                    }
                }
                if (bg.radial) {
                    return {
                        background: `radial-gradient(circle, ${bg.colors.join(', ')})`,
                    }
                }
                return {
                    background: `linear-gradient(${bg.colors.join(', ')})`,
                }
            }
            if (this.thumb) {
                return {
                    'background-image': `url(${bg.thumb || bg.image})`,
                    'background-size': 'cover',
                    'background-position': 'center',
                }
            } else if (bg.tiled) {
                return {
                    'background-image': `url(${bg.image})`,
                    'background-repeat': 'repeat',
                }
            }
            return {
                'background-image': `url(${bg.image})`,
                'background-size': 'cover',
                'background-position': 'center',
            }
        },
    },
}
</script>
