<template lang="pug">
    prepare(ref='prepare')
        template(slot-scope='props')
            .internal-page
                .container
                    h3.text-muted.mb-4
                        | Dashboard
                        b-dropdown.float-right
                            b-dropdown-item(@click='days = 7') 7 days
                            b-dropdown-item(@click='days = 30') 30 days
                            b-dropdown-item(@click='days = 90') 90 days
                            b-dropdown-item(@click='days = 180') 180 days
                            b-dropdown-item(@click='days = 365') 365 days
                    .card.card__body.card__body--lg
                        h4.card__title Activity
                        ActivityChart(:data='data.chart' :days='days' :height='300')

                    div(v-for='sg in statGroups' :key='sg.name')
                        h4.text-muted.mb-0.mt-4 {{ sg.name }}
                        .stats
                            .stat(v-for='stat in sg.stats' :key='stat.id')
                                .stat__data: span {{ stat.format ? stat.format(statsData[stat.id]) : statsData[stat.id].toLocaleString() }}
                                .stat__label {{ stat.name }}

                    div
                        h4.text-muted.mb-0.mt-4 Number of coffees per gift
                        .stats
                            .stat(v-for='count, nv in data.metrics.vouchers_per_gift' :key='nv')
                                .stat__data: span {{ count.toLocaleString() }}
                                .stat__label {{ nv }}
</template>

<script>
import axios from 'axios'

import ActivityChart from './activity-chart'

export default {
    components: { ActivityChart },

    data() {
        return {
            days: 30,
            data: {},
            statGroups: [
                {
                    name: 'Overall Stats',
                    stats: [
                        { id: 'users_registered', name: 'Registered users' },
                        { id: 'users_with_coffee_purchase', name: 'Users that have purchased a coffee' },
                        { id: 'users_with_multiple_purchases', name: 'Users that have 2+ purchases' },
                        { id: 'users_who_received_coffee', name: 'Users that have received a coffee' },
                        { id: 'user_pif_pct', name: 'Percentage of users that have PIF when given a coffee to share', format: v => `${Math.round(v * 100)}%` },
                        { id: 'users_who_received_multi_voucher_gift', name: 'Users that have received two coffees in a gift' },
                    ],
                },
                {
                    name: 'Redeemable Coffee Stats (From App)',
                    stats: [
                        { id: 'consumer_coffees_sent', name: 'Sent' },
                        { id: 'consumer_coffees_claimed', name: 'Claimed' },
                        { id: 'consumer_coffees_unclaimed', name: 'Unclaimed', formula: m => m.consumer_coffees_sent - m.consumer_coffees_claimed },
                        { id: 'consumer_coffees_redeemed', name: 'Redeemed' },
                        { id: 'consumer_coffees_unredeemed', name: 'Unredeemed', formula: m => m.consumer_coffees_claimed - m.consumer_coffees_redeemed },
                    ],
                },
                {
                    name: 'Redeemable Coffee Stats (From Campaign Tools)',
                    stats: [
                        { id: 'corporate_coffees_claimed', name: 'Claimed' },
                        { id: 'corporate_coffees_redeemed', name: 'Redeemed' },
                        { id: 'corporate_coffees_unredeemed', name: 'Unredeemed', formula: m => m.corporate_coffees_claimed - m.corporate_coffees_redeemed },
                    ],
                },
                {
                    name: 'Outstanding Coffees',
                    stats: [
                        { id: 'outstanding_coffees', name: 'Not claimed or redeemed', formula: m => (m.consumer_coffees_sent - m.consumer_coffees_claimed) + (m.consumer_coffees_claimed - m.consumer_coffees_redeemed) + (m.corporate_coffees_claimed - m.corporate_coffees_redeemed) },
                        {
                            id: 'outstanding_money', name: 'Outstanding cost', formula: m => ((m.consumer_coffees_sent - m.consumer_coffees_claimed) + (m.consumer_coffees_claimed - m.consumer_coffees_redeemed) + (m.corporate_coffees_claimed - m.corporate_coffees_redeemed)) * 5, format: v => `$${Math.round(v).toLocaleString()}`,
                        },
                    ],
                },
            ],
        }
    },

    computed: {
        statsData() {
            const data = {}
            for (const group of this.statGroups) {
                for (const stat of group.stats) {
                    data[stat.id] = stat.formula ? stat.formula(this.data.metrics) : this.data.metrics[stat.id]
                }
            }
            return data
        },
    },

    methods: {
        prepare() {
            return axios.get(`/api/admin/dashboard/${this.days}/`).then((response) => {
                this.data = response.data
            })
        },
    },

    watch: {
        days() {
            this.$refs.prepare.prepare()
        },
    },
}
</script>

<style lang="scss" scoped>
.stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.stat {
    margin: 20px;
    text-align: center;
    width: 120px;

    &__data {
        @include shadow;
        @include flex-align-center;
        display: inline-flex;
        width: 100px;
        height: 100px;
        background: white;
        border: 3px solid $nack-primary;
        border-radius: 50%;
        font-size: 1.4rem;
        font-weight: 500;
        color: $nack-primary-d-4;
    }

    &__label {
        margin-top: 5px;
        font-size: 0.8rem;
        font-weight: 500;
        color: #767676;
    }
}
</style>
