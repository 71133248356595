<template lang="pug">
    prepare
        template(slot-scope='props')
            .pay
                get-the-app

                .pay__card
                    gift-header.pay__header(:gift='gift')
                    .pay__sender(row='0')
                        avatar.pay__user(:sender='gift.sender' :size='80')

                    .pay__message {{ gift.message }}

                    .text-center(v-if='ctaUrl')
                        a.btn.btn-primary.pay__cta(
                            :href='ctaUrl'
                            target='_blank'
                        ) {{ gift.campaign.cta_label }}

                    .pay__area(:class='[`pay__area--${codeType}`]')
                        .pay__brand(:style='`background-color: ${card.vendor.color}`')
                            img.pay__logo(:src='card.vendor.logo')

                        .pay__code(v-if='barcode')
                            canvas.pay__code-image(ref='barcode')

                    template(v-if='barcode')
                        .pay__code-text {{ spacedNumber }}
                    template(v-else)
                        .p-20
                            .pay__code-text.pay__code-text--number {{ spacedNumber }}
                            .pay__code-text.pay__code-text--pin Pin: {{ card.security_code }}

                .pay__options
                    .pay__balance Original Balance: $5
                    a.btn.pay__add-to-app(
                        v-if='card.url'
                        :href='card.url'
                        target='_blank'
                        :style='`background-color: ${card.vendor.color}`'
                    ) Add to {{ card.vendor.name }} App
</template>

<script>
import JsBarcode from 'jsbarcode'
import QRCode from 'qrcode'
import Q from 'q'
import { mapActions, mapGetters, mapState } from 'vuex'

import { isIOS } from 'core/util/os'

const codeSizes = {
    CODE_128: {
        width: 2,
        height: 70,
    },

    QR_CODE: {
        width: screenWidth => Math.min(screenWidth * 0.45, 300),
    },
}

export default {
    computed: {
        voucher() {
            return this.vouchers[this.$route.params.voucher]
        },

        gift() {
            return this.normalizeGift(this.gifts[this.voucher.gift])
        },

        card() {
            return this.voucher.gift_card
        },

        codeType() {
            const codeType = this.card.vendor.code_type

            // TODO: Remove this after CODE_128 support has been out long enough
            if (codeType === 'PDF_417') return 'CODE_128'

            // Fall back to QR codes when in doubt
            if (!codeSizes[codeType] && codeType !== 'TEXT') return 'QR_CODE'

            return codeType
        },

        spacedNumber() {
            // Add spaces to the card number
            const spacedNumber = []
            for (let n = 0; n < this.card.card_number.length; n += 4) {
                spacedNumber.push(this.card.card_number.substr(n, 4))
            }
            return spacedNumber.join(' ')
        },

        barcode() {
            return this.codeType === 'CODE_128' || this.codeType === 'QR_CODE'
        },

        ctaUrl() {
            if (!this.gift.campaign) return null
            if (this.gift.campaign.cta_type === 'link') return this.gift.campaign.cta_url
            if (this.gift.campaign.cta_type === 'app') {
                if (isIOS) {
                    return `itms-apps://itunes.apple.com/us/app/app/id${this.gift.campaign.cta_ios_app_id}?mt=8`
                }
                return `market://details?id=${this.gift.campaign.cta_android_bundle_id}`
            }
            return null
        },

        ...mapGetters(['vouchers', 'normalizeGift']),
        ...mapState({
            gifts: state => state.gifts.gifts,
        }),
    },

    methods: {
        prepare() {
            return Q.all([
                this.loadGifts(),
                this.loadEmojisIfNeeded({ url: '/api/emojis/' }),
            ]).then(() => {
                setTimeout(() => this.renderBarcode(), 0)
            })
        },

        renderBarcode() {
            if (this.codeType === 'CODE_128') {
                JsBarcode(this.$refs.barcode, this.card.card_number, {
                    format: 'code128',
                    margin: 0,
                    displayValue: false,
                    ...codeSizes.CODE_128,
                })
            } else if (this.codeType === 'QR_CODE') {
                QRCode.toCanvas(this.$refs.barcode, this.card.card_number, {
                    width: codeSizes.QR_CODE.width(window.innerWidth),
                })
            }
        },

        ...mapActions(['loadGifts', 'loadEmojisIfNeeded']),
    },
}
</script>

<style lang="scss" scoped>
.pay {
    min-height: 100vh;
    background: #eaeaea;
    padding: 20px;

    .get-app {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    &__card {
        @include shadow;
        background-color: white;
        border-radius: 12px;
        border-color: rgba(0, 0, 0, 0.16);
        border-width: 1px;
        max-width: 500px;
        margin: 0 auto;
    }

    &__header {
        height: 25vh;
        border-radius: 12px 12px 0 0;
    }

    &__sender {
        text-align: center;
        margin-top: -20px;
    }

    &__message {
        padding: 20px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        white-space: pre-wrap;
    }

    &__cta {
        margin-top: -10px;
        margin-bottom: 20px;
    }

    &__brand {
        @include flex-align-center;
        width: 100%;
        padding: 10px;

        @media(min-height: 600px) {
            padding: 20px;
        }
    }

    &__logo {
        width: 50px;

        @media(min-height: 600px) {
            width: 80px;
        }
    }

    &__code-text {
        text-align: center;
        color: #A0A0A0;
        font-weight: 500;
        font-size: 14px;
        padding: 10px 0;

        &--number {
            font-size: 20px;
            color: #555;
        }

        &--pin {
            margin-top: 5px;
            font-size: 12px;
            color: #777;
        }
    }

    &__area {
        &--QR_CODE {
            @include flex-align-center;
            display: flex;

            .pay__code {
                @include flex-align-center;
                width: 60%;
            }

            .pay__brand {
                display: inline-flex;
                align-self: stretch;
                width: 40%;
                height: auto;

                .pay__logo {
                    min-width: 50px;
                    max-width: 250px;
                    width: 60%;
                }
            }
        }

        &--CODE_128 {
            .pay__code {
                margin: 20px 0 10px 0;

                .pay__code-image {
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }

    &__options {
        margin-top: 10px;
        text-align: center;
        color: #747474;
        font-size: 14px;
        font-weight: 500;
    }

    &__add-to-app {
        margin-top: 5px;
        color: white;
    }
}
</style>
