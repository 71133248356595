<template lang="pug">
    .gift
        gift-header.gift__header(:gift='gift')
        .gift__sender(row='0')
            avatar.gift__user(:sender='gift.sender' :size='80')

        .gift__message {{ gift.message }}

        .text-center(v-if='ctaUrl')
            a.btn.btn-primary.gift__cta(
                :href='ctaUrl'
                target='_blank'
            ) {{ gift.campaign.cta_label }}

        router-link.gift__pay(
            v-if='voucher.gift_card'
            :to='`/pay/${voucher.id}`'
            :style='`background-color: ${voucher.gift_card.vendor.color}`'
        )
            img.gift__pay-logo(:src='voucher.gift_card.vendor.logo')
            span.gift__pay-text Pay with Gift Card
        .gift__app-needed(v-else) The Nack app is needed to use this gift.
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { isIOS } from 'core/util/os'

export default {
    props: ['voucher'],

    computed: {
        gift() {
            return this.normalizeGift(this.gifts[this.voucher.gift])
        },

        ctaUrl() {
            if (!this.gift.campaign) return null
            if (this.gift.campaign.cta_type === 'link') return this.gift.campaign.cta_url
            if (this.gift.campaign.cta_type === 'app') {
                if (isIOS) {
                    return `itms-apps://itunes.apple.com/us/app/app/id${this.gift.campaign.cta_ios_app_id}?mt=8`
                }
                return `market://details?id=${this.gift.campaign.cta_android_bundle_id}`
            }
            return null
        },

        ...mapGetters(['normalizeGift']),
        ...mapState({
            gifts: state => state.gifts.gifts,
        }),
    },
}
</script>

<style lang="scss" scoped>
.gift {
    @include shadow;
    background: white;
    border-radius: 12px;
    margin-bottom: 20px;

    &__header {
        border-radius: 12px 12px 0 0;
    }

    &__sender {
        text-align: center;
        margin-top: -20px;
    }

    &__message {
        padding: 20px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        white-space: pre-wrap;
    }

    &__cta {
        margin-top: -10px;
        margin-bottom: 20px;
    }

    &__pay {
        @include flex-align-center;
        border-radius: 0 0 12px 12px;
        color: white;
        padding: 20px;
        text-decoration: none;
    }

    &__pay-logo {
        height: 40px;
    }

    &__pay-text {
        color: white;
        margin-left: 10px;
    }

    &__app-needed {
        border-radius: 0 0 12px 12px;
        color: #444;
        background: #afafb2;
        padding: 20px;
        font-size: 0.8rem;
        font-weight: 500;
    }
}
</style>
