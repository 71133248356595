import Vue from 'vue'
import Vuedals, { Component as VuedalsComponent, Bus } from 'vuedals'

Vue.use(Vuedals)

export const Vuedal = VuedalsComponent

export const present = (component, props, className) => {
    Bus.$emit('new', {
        name: className || 'modal-content',
        component,
        props,
        dismissable: false,
    })
}

Vue.prototype.$present = present
