<template lang="pug">
    .card
        .card__body.card__body--lg.editor__card-body
            h5.editor__title Last question! Do you want to create a coffee code?
            p.editor__subtitle You'll get a link that you can send, but coffee codes are convenient for handing out in person.

            .form.text-center
                v-form-group.d-inline-block(
                    label='Coffee Code'
                    :error='errors.coffee_code'
                )
                    b-form-input(v-model='model.coffee_code' placeholder='Optional')

            div
            .float-right
                b-btn(variant='primary' @click='$emit("next")') Finish
</template>

<script>
export default {
    props: ['model'],

    data() {
        return {
            errors: {},
        }
    },

    methods: {
        validate() {
            this.errors = {}
            if (this.model.coffee_code && this.model.coffee_code.length > 25) {
                this.errors.coffee_code = 'Coffee codes must be 25 characters or less.'
                return false
            }
            if (!this.model.coffee_code) this.model.coffee_code = null
            return true
        },
    },
}
</script>
