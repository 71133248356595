<template lang="pug">
    .modal-body
        template(v-if='ready')
            .image-area(:style='{"width": `${width}px`}')
                croppa.crop-area(
                    v-model='croppa'
                    :initial-image='initialImage'
                    :show-remove-button='false'
                    :width='width'
                    :height='height'
                    :quality='quality'
                    :initial-size='initialSize'
                    canvas-color='white'
                )
                vue-slider(
                    v-if='baseScale'
                    v-model='croppa.scaleRatio'
                    :min='baseScale / 10'
                    :max='baseScale * 10'
                    :interval='0.01'
                    tooltip='never'
                )
                b-btn.mt-3(block variant='primary' @click='finish') Save
</template>

<script>
import VueSlider from 'vue-slider-component'

export default {
    props: {
        file: {
            type: File,
            required: true,
        },
        initialSize: {
            type: String,
            default: 'cover',
        },
        width: {
            type: Number,
            default: 300,
        },
        height: {
            type: Number,
            default: 300,
        },
        quality: {
            type: Number,
            default: 512 / 300,
        },
        callback: {
            type: Function,
            required: true,
        },
    },

    components: { VueSlider },

    data() {
        const initialImage = new Image()
        const reader = new FileReader()
        reader.onloadend = () => {
            initialImage.src = reader.result
        }
        initialImage.onload = () => {
            this.ready = true
            setTimeout(() => {
                this.baseScale = this.croppa.scaleRatio
            })
        }
        initialImage.onerror = () => {
            this.$vuedals.close(this)
        }
        reader.readAsDataURL(this.file)

        return {
            ready: false,
            initialImage,
            croppa: null,
            baseScale: null,
        }
    },

    methods: {
        finish() {
            this.croppa.generateBlob((blob) => {
                // Create a base64 string version
                const reader = new FileReader()
                reader.onloadend = () => {
                    this.callback(blob, reader.result)
                    this.$vuedals.close(this)
                }
                reader.readAsDataURL(blob)
            }, 'image/jpeg', 0.8)
        },
    },
}
</script>

<style lang="scss" scoped>
.image-area {
    margin: 0 auto;
}

.crop-area {
    border: 1px solid #eaeaea;
}
</style>
