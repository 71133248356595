<template lang="pug">
    div
        router-view

        vuedal
</template>

<script>
import { mapState } from 'vuex'

import { Vuedal } from 'core/util/modals'

export default {
    components: { Vuedal },

    computed: {
        ...mapState({
            user: state => state.accounts.user,
        }),
    },

    mounted() {
        window.Intercom('boot', {
            app_id: 'p0wk6xco',
            name: this.user ? `${this.user.first_name} ${this.user.last_name}` : null,
            email: this.user?.email,
            phone: this.user ? this.user.phone_number : null,
            user_id: this.user?.id,
            user_hash: this.user ? this.user.id_hash : null,
        })

        if (this.user) {
            this.$ga.set('userId', this.user.id)
        }
    },

    watch: {
        user() {
            this.$ga.set('userId', this.user ? this.user.id : null)
            window.Intercom('update', {
                name: this.user ? `${this.user.first_name} ${this.user.last_name}` : null,
                email: this.user?.email,
                phone: this.user ? this.user.phone_number : null,
                user_id: this.user?.id,
                user_hash: this.user ? this.user.id_hash : null,
            })
        },
    },
}
</script>
