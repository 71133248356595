<template lang="pug">
    .card
        .card__body.card__body--lg.editor__card-body
            h5.editor__title Great choice! Let us know how you want to appear to your recipients.
            p.editor__subtitle You can appear as a company, a department, or even an individual. The sky's the limit!

            .form.row
                .col-md-4.text-center
                    v-form-group(:error='errors.sender_photo')
                        label(for='sender-logo')
                            a.sender-logo-placeholder(v-if='!senderPhoto'): i.fa.fa-file-upload
                            img.sender-logo(v-else :src='senderPhoto' alt='Display Picture')
                            .sender-logo-text
                                i.fas.fa-camera
                                | {{ senderPhoto ? "Change" : "Upload" }}
                        input(
                            id='sender-logo'
                            type='file'
                            @change='logoSelection = $event.target.files[0]; $event.target.value = null'
                        )
                .col-md-8
                    v-form-group(
                        label='Internal Campaign Label'
                        description="This won't be shown to your receipients, it's just to help you remember what this campaign is for."
                        :error='errors.internal_label'
                    )
                        b-form-input(v-model='model.internal_label' placeholder='Customer Appreciation')

                    v-form-group(
                        label='Display Name'
                        description='This is usually the name of your company.'
                        :error='errors.sender_name'
                    )
                        b-form-input(v-model='model.sender_name')

                    v-form-group(
                        label='Detailed Name'
                        description='This could be the name of a department or person.'
                        :error='errors.sender_name2'
                    )
                        b-form-input(v-model='model.sender_name2' placeholder='Optional')

            h5.editor__title.text-left.mt-4 What would you like to tell your recipients?
            v-form-group(
                description="We recommend keeping it short and sweet. If you want to add a link, you'll be able to later."
                :error='errors.message'
            )
                b-form-textarea(v-model='model.message' rows='3')

            .float-right
                b-btn(variant='primary' @click='$emit("next")') Next
</template>

<script>
import { mapState } from 'vuex'

import ImageCropper from 'core/components/image-cropper'

export default {
    props: ['model'],

    data() {
        return {
            logoSelection: null,
            errors: {},
        }
    },

    computed: {
        senderPhoto() {
            if (this.model.sender_photo) return this.model.sender_photo
            if (this.company.logo) return this.company.logo
            return null
        },


        ...mapState({
            company: state => state.corporate.company,
        }),
    },

    methods: {
        validate() {
            const errors = {}

            // Logo
            if (!this.senderPhoto) errors.sender_photo = 'Please select a picture.'

            // Names
            if (!this.model.internal_label) {
                errors.internal_label = 'Please fill this out.'
            }

            if (!this.model.sender_name) {
                errors.sender_name = 'Please fill this out.'
            } else if (this.model.sender_name.length > 30) {
                errors.sender_name = 'This must be 30 characters or less.'
            }

            if (this.model.sender_name2 && this.model.sender_name2.length > 30) {
                errors.sender_name2 = 'This must be 30 characters or less.'
            }

            // Message
            if (!this.model.message) {
                errors.message = 'Please fill this out.'
            } else if (this.model.message.length > 2000) {
                errors.message = 'This must be 2000 characters or less.'
            }

            if (!this.model.sender_name2) this.model.sender_name2 = null

            this.errors = errors
            return !Object.keys(errors).length
        },
    },

    watch: {
        logoSelection(val) {
            if (!val) return
            this.$present(ImageCropper, {
                file: val,
                initialSize: 'contain',
                callback: (blob, img) => {
                    this.model.sender_photo = img
                },
            })
            this.logoSelection = null
        },
    },
}
</script>

<style lang="scss" scoped>
.sender-logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
}

.sender-logo-placeholder {
    @include flex-align-center;
    background: #e5e5e5;
    width: 150px;
    height: 150px;
    border-radius: 50%;

    i {
        font-size: 1.4rem;
        color: #555;
    }

    &:hover {
        cursor: pointer;
    }
}

.sender-logo-text {
    margin-top: 10px;
    color: #b2b2b2;

    &:hover {
        cursor: pointer;
    }

    i {
        margin-right: 5px;
    }
}

#sender-logo {
    display: none;
}
</style>
