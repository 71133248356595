<template lang="pug">
    .splash
        .splash__content
            i.fas.fa-exclamation-square
            h3 {{ title }}
            p {{ message }}
</template>

<script>
export default {
    props: {
        title: {
            default: "Well, that didn't go according to plan.",
        },
        message: {
            default: 'We hit a snag, but our team has been notified and will have it fixed up shortly.',
        },
    },
}
</script>
