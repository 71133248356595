import axios from 'axios'
import _ from 'lodash'

import { jsonToFormData } from 'core/util/server'
import { crudStore, combineStores } from 'core/util/vuex'

const initialState = window.STATE

const corporateStore = combineStores([
    crudStore('campaigns', '/api/corporate/campaigns/'),

    {
        state: {
            company: initialState.company,
            presetCategories: null,
            presets: null,
        },

        getters: {
            hasCompany: state => !!state.company,

            annotatedCampaign: state => (id) => {
                const campaign = { ...state.campaigns[id] }

                // Add in progress information
                campaign.numClaimed = campaign.quantity_total - campaign.quantity_remaining
                campaign.numTotal = campaign.quantity_total
                campaign.progress = Math.floor((campaign.numClaimed / campaign.numTotal) * 100)

                // Add status information
                if (campaign.numClaimed === campaign.numTotal) {
                    campaign.status = 'finished'
                } else if (campaign.enabled) {
                    campaign.status = 'active'
                } else {
                    campaign.status = campaign.numClaimed === 0 ? 'pending' : 'paused'
                }

                return campaign
            },
        },

        mutations: {
            setAuth(state, data) {
                state.company = data.company
            },

            patchCurrentCompany(state, company) {
                state.company = { ...state.company, ...company }
            },

            setCorporatePresets(state, categories) {
                state.presetCategories = _.keyBy(categories, 'id')
                state.presets = {}
                _.each(state.presetCategories, (category) => {
                    state.presets = { ...state.presets, ..._.keyBy(category.presets, 'id') }
                })
            },
        },

        actions: {
            createCompany({ commit }, { instance }) {
                const request = axios.post('/api/corporate/create_company/', jsonToFormData(instance))
                request.then((response) => {
                    commit('setAuth', response.data)
                })
                return request
            },

            loadCorporatePresets({ commit, state }) {
                if (state.presetCategories) return true
                const request = axios.get('/api/presets/corporate/')
                request.then(response => commit('setCorporatePresets', response.data))
                return request
            },

            addFunds({ commit }, { amount, token }) {
                const request = axios.post('/api/corporate/add_funds/', { amount, token })
                request.then(response => commit('patchCurrentCompany', response.data))
                return request
            },

            enableCampaign({ commit, state }, id) {
                const request = axios.post(`/api/corporate/campaigns/${id}/enable/`)
                request.then((response) => {
                    const campaign = state.campaigns[id]
                    commit('setCampaign', { ...campaign, enabled: true })
                    commit('patchCurrentCompany', response.data)
                })
                return request
            },

            disableCampaign({ commit, state }, id) {
                const request = axios.post(`/api/corporate/campaigns/${id}/disable/`)
                request.then((response) => {
                    const campaign = state.campaigns[id]
                    commit('setCampaign', { ...campaign, enabled: false })
                    commit('patchCurrentCompany', response.data)
                })
                return request
            },
        },
    },
])

export default corporateStore
