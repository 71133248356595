<template lang="pug">
    prepare
        template(slot-scope='props')
            .internal-page
                .container
                    .card.card__body.card__body--lg
                        h4.card__title.text-left
                            | Vendors
                            button.btn.btn-primary.float-right(@click='addVendor')
                                i.fa.fa-plus.mr-2
                                | Add Vendor

                        table.table.table-responsive-lg.table--card
                            thead
                                th Logo
                                th Name
                                th Scale
                                th Available Cards
                                th Total Cards
                            tbody
                                tr.vendor(
                                    v-for='vendor in sortedVendors'
                                    :key='vendor.id'
                                    @click='viewVendor(vendor)'
                                    :class='{"vendor--disabled": !vendor.active}'
                                )
                                    td: img(:src='vendor.logo')
                                    td(:style='`color: ${vendor.color}; font-weight: 500`') {{ vendor.name }}
                                    td {{ vendor.scale | capitalize }}
                                    td {{ (vendor.cards_available || 0).toLocaleString() }}
                                    td {{ (vendor.cards_total || 0).toLocaleString() }}
</template>

<script>
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'

import VendorForm from './vendor-form'

export default {
    computed: {
        sortedVendors() {
            return _.sortBy(this.vendors, 'cards_available')
        },

        ...mapState({
            vendors: state => state.admin.vendors,
        }),
    },

    methods: {
        prepare() {
            return this.loadVendors()
        },

        viewVendor(vendor) {
            this.$router.push(`/admin/vendors/${vendor.id}`)
        },

        addVendor() {
            this.$present(VendorForm)
        },

        ...mapActions(['loadVendors']),
    },
}
</script>

<style lang="scss" scoped>
.vendor {
    &:hover {
        background: #f4f4f4;
        cursor: pointer;
    }

    &--disabled {
        opacity: 0.4;
    }

    img {
        width: 75px;
        border-radius: 50%;
    }

    td {
        vertical-align: middle;
    }
}
</style>
