<template lang="pug">
    .auth
        .auth__photo
        .auth__content
            .auth__size
                .text-center
                    h5 Let's get your company set up.
                    p.text-muted You'll be sending coffee in no time!

                v-form(:context='this')
                    v-field.text-center(:model='model.company_logo' label='Company Logo' hint='Optional')
                        label.mb-0(for='company-logo')
                            a.auth__logo-placeholder(v-if='!logoImg'): i.fa.fa-file-upload
                            img.auth__logo(v-else :src='logoImg' alt='Your Company Logo')
                        input(
                            id='company-logo'
                            type='file'
                            @change='logoSelection = $event.target.files[0]; $event.target.value = null'
                        )

                    v-field(
                        :model='model.company_name'
                        field='input'
                        label='Company Name'
                    )

                    h5.mt-4 Tell us about yourself

                    .row
                        .col-md-6
                            v-field(
                                :model='model.user_first_name'
                                field='input'
                                label='First Name'
                            )

                        .col-md-6
                            v-field(
                                :model='model.user_last_name'
                                field='input'
                                label='Last Name'
                            )

                    v-field(
                        :model='model.user_email'
                        field='input'
                        label='Email Address'
                    )

                    v-field(
                        :model='model.user_password'
                        field='input'
                        label='Password'
                        type='password'
                    )

                    b-btn.btn-block(variant='primary' type='submit') Let's send some coffee!
</template>

<script>
import { FormMixin } from 'core/components/forms/mixin'
import accountsStore from 'accounts/store'

import ImageCropper from 'core/components/image-cropper'

export default {
    mixins: [FormMixin],

    fields: [
        'company_name',
        'company_logo',
        { name: 'user_first_name', default: () => accountsStore.state.user.first_name },
        { name: 'user_last_name', default: () => accountsStore.state.user.last_name },
        { name: 'user_email', default: () => accountsStore.state.user.email },
        'user_password',
    ],

    dispatch: 'createCompany',

    data() {
        return {
            logoSelection: null,
            logo: null,
            logoImg: null,
        }
    },

    methods: {
        clean(data) {
            delete data.company_logo
            if (this.logo) data.company_logo = this.logo
            return data
        },
    },

    watch: {
        logoSelection(val) {
            if (!val) return
            this.$present(ImageCropper, {
                file: val,
                initialSize: 'contain',
                callback: (blob, img) => {
                    this.logo = blob
                    this.logoImg = img
                },
            })
            this.logoSelection = null
        },
    },
}
</script>

<style lang="scss">
#company-logo {
    display: none;
}
</style>
