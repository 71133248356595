<template lang="pug">
    .gl
        template(v-if='giftStatus === 200')
            .gl__header
                .text-center
                    .gl__sender-area
                        avatar.gl__sender(:sender='gift.sender' :size='100')
                        img.gl__nack(src='~landing/images/icon.jpg')

                h5.gl__header-text {{ gift.sender.name }} bought you a coffee!

            .container
                .gl__body(v-if='!sendLinkMode')
                    vendor-picker(:fixed='gift.fixed_vendor' :trackLinkAction='trackLinkAction')

                    //- TODO: Can remove the v-if when the app supports "c" urls
                    .gl__downloaded(v-if='itemInfo.kind !== "c"')
                        span Already have the app?
                        a(:href='appUri') Open in App
                .gl__body(v-else)
                    send-link

        template(v-else-if='giftStatus === 404')
            .gl__header
                .text-center
                    img.gl__app-icon(alt='Nack' src='~landing/images/app-icon.png')
            .container
                .gl__body
                    h5.gl__body-header Sorry, looks like that link has expired.
                    h5.gl__body-header If you've already received a coffee, <router-link to='/my-coffees'>click here</router-link> to see all your coffees.
                    h5.gl__body-header While you're here, do you have the Nack app? It lets you share coffees with your friends.
                    .gl__stores
                        a.ios(
                            href='https://itunes.apple.com/us/app/nack/id1439154311?mt=8'
                            target='_blank'
                        )
                            img.getit__store(alt='Download on the App Store' src='~landing/images/app-store.svg')

                        a.android(
                            href='https://play.google.com/store/apps/details?id=com.getnack.barista&hl=en_US'
                            target='_blank'
                        )
                            img(alt='Get it on Google Play' src='~landing/images/google-play-badge.png')

        template(v-else-if='giftStatus === 429')
            .gl__header
                .text-center
                    img.gl__app-icon(alt='Nack' src='~landing/images/app-icon.png')
            .container
                .gl__body
                    h5.gl__body-header You're doing things a bit too fast. Please try again in a bit.
                    h5.gl__body-header If you've already received a coffee, <router-link to='/my-coffees'>click here</router-link> to see all your coffees.
                    h5.gl__body-header While you're here, do you have the Nack app? It lets you share coffees with your friends.
                    .gl__stores
                        a.ios(
                            href='https://itunes.apple.com/us/app/nack/id1439154311?mt=8'
                            target='_blank'
                        )
                            img.getit__store(alt='Download on the App Store' src='~landing/images/app-store.svg')

                        a.android(
                            href='https://play.google.com/store/apps/details?id=com.getnack.barista&hl=en_US'
                            target='_blank'
                        )
                            img(alt='Get it on Google Play' src='~landing/images/google-play-badge.png')
</template>

<script>
import axios from 'axios'

import NearbyShops from './nearby-shops'
import SendLink from './gift-send-link'
import VendorPicker from './vendor-picker'

export default {
    components: { SendLink, VendorPicker },

    computed: {
        giftStatus() {
            return window.GIFT_STATUS
        },

        gift() {
            return window.GIFT
        },

        itemInfo() {
            const { bundleHash, giftHash, code } = this.$route.params
            if (bundleHash) {
                return {
                    hash_code: bundleHash,
                    kind: 'b',
                }
            } else if (giftHash) {
                return {
                    hash_code: giftHash,
                    kind: 'g',
                }
            }
            return {
                hash_code: code,
                kind: 'c',
            }
        },

        partialItemUrl() {
            return `${this.itemInfo.kind}/${this.itemInfo.hash_code}`
        },

        appUri() {
            return `getnack://getnack.com/${this.partialItemUrl}`
        },

        sendLinkMode() {
            return window.location.hash === '#text'
        },
    },

    methods: {
        viewShops() {
            this.$present(NearbyShops)
        },

        trackLinkAction(action) {
            axios.post(`/api/link_view/${this.partialItemUrl}/${action}/`)
        },
    },

    created() {
        if (this.giftStatus === 200) this.trackLinkAction('viewed')
    },
}
</script>

<style lang="scss" scoped>
.gl {
    &__header {
        background: $nack-primary;
        padding: 20px;
    }

    &__sender-area {
        display: inline-block;
        position: relative;
        margin-bottom: 20px;
    }

    &__header-text {
        text-align: center;
        color: white;
        font-weight: 400;
    }

    &__nack {
        @include soft-shadow;
        position: absolute;
        bottom: -10px;
        right: -10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    &__body {
        padding: 20px;
        color: #4b4b4b;
    }

    &__body-header {
        text-align: center;
        font-weight: 400;
    }

    &__downloaded {
        margin-top: 40px;
        text-align: center;
        font-size: 0.7rem;

        span {
            display: block;
            color: #777;
        }
    }

    &__app-icon {
        width: 200px;
        height: 200px;
    }

    &__stores {
        text-align: center;

        a {
            @include shadow;
            display: inline-block;
            width: 200px;
            max-width: 100%;
            margin: 20px 10px;

            img {
                width: 100%;
            }

            &.ios {
                border-radius: 14px;
            }

            &.android {
                border-radius: 10px;
            }
        }
    }
}
</style>
