<template lang="pug">
    form.form(@submit.prevent='context.submit' :class='{"form--submitting": context.submitting}')
        b-alert(variant='warning' :show='!!context.alert') {{ context.alert }}
        .form__spinner
            spinner(cover)
        slot
</template>

<script>
export default {
    props: {
        context: { required: true },
    },
}
</script>
