import { render, staticRenderFns } from "./basic-info-step.vue?vue&type=template&id=3eb668e4&scoped=true&lang=pug&"
import script from "./basic-info-step.vue?vue&type=script&lang=js&"
export * from "./basic-info-step.vue?vue&type=script&lang=js&"
import style0 from "./basic-info-step.vue?vue&type=style&index=0&id=3eb668e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eb668e4",
  null
  
)

export default component.exports