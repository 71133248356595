<template lang="pug">
    .landing
        LandingHeader
        Intro#what
        Video#video
        GetTheApp#get
        HowItWorks#how
        Campaigns#campaigns
        RequestCoffeeShop
        LandingFooter#contact
</template>

<script>
import Intro from './intro'
import Video from './video'
import GetTheApp from './get-the-app'
import HowItWorks from './how-it-works'
import Campaigns from './campaigns'
import RequestCoffeeShop from './request-coffee-shop'

export default {
    components: {
        Intro,
        Video,
        GetTheApp,
        HowItWorks,
        Campaigns,
        RequestCoffeeShop,
    },
}
</script>

<style lang="scss" scoped>
</style>
