<template lang="pug">
    phone(style='min-width: 100%; max-width: 100%')
        .phone-buffer
            .campaign
                a.campaign__header(href='#' @click.prevent='jump(0)')
                    gift-header(:gift='header' style='width: 102%')

                .campaign__sender.clickable(@click='jump(1)')
                    img.campaign__sender-logo(v-if='senderPhoto' :src='senderPhoto')
                    .campaign__sender-logo-placeholder(v-else)
                        i.fa.fa-file-upload
                    h5.campaign__sender-name {{ model.sender_name }}
                    h5.campaign__sender-name(v-if='model.sender_name2') {{ model.sender_name2 }}

                p.campaign__description.clickable(@click='jump(1)') {{ model.message }}

                template(v-if='model.vouchers.length')
                    .campaign__vouchers(v-if='model.vouchers.length <= 2' @click='jump(2)')
                        campaign-coffee-preview(:voucher='model.vouchers[0]')
                        .campaign__gift
                            img(src='./icons/two_coffees.png' v-if='model.vouchers.length == 2')
                            img(src='./icons/one_coffee.png' v-else)
                        campaign-coffee-preview(:voucher='model.vouchers[1]' v-if='model.vouchers.length > 1')
                    .campaign__vouchers.campaign__vouchers--many(v-else @click='jump(2)')
                        .campaign__item(v-for='voucher in model.vouchers')
                            .campaign__gift: img(src='./icons/one_coffee.png')
                            campaign-coffee-preview(:voucher='voucher')

                b-btn.campaign__cta.clickable(variant='primary' v-if='model.cta_label' @click='jump(3)') {{ model.cta_label }}
</template>

<script>
import { mapState } from 'vuex'

import CampaignCoffeePreview from './campaign-coffee-preview'

export default {
    props: ['model'],

    components: { CampaignCoffeePreview },

    computed: {
        senderPhoto() {
            if (this.model.sender_photo) return this.model.sender_photo
            if (this.company.logo) return this.company.logo
            return null
        },

        header() {
            return {
                emoji: this.model.emoji,
                background: this.model.background || this.model.custom_background,
            }
        },

        ...mapState({
            company: state => state.corporate.company,
        }),
    },

    methods: {
        jump(idx) {
            this.$emit('jump', idx)
        },
    },
}
</script>

<style lang="scss" scoped>
.phone-buffer {
    background: #6c6c6c;
    padding: 50px 10px 30px 10px;
    min-height: 450px;
}

.campaign {
    background: white;
    border-radius: 5px;
    overflow: hidden;
    @include soft-shadow;

    &__header {
        width: 100%;
        padding-top: 50%;
    }

    &__sender {
        margin-top: -40px;
        text-align: center;
    }

    &__sender-logo {
        @include shadow;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-bottom: 10px;
        background: white;
    }

    &__sender-logo-placeholder {
        @include shadow;
        @include flex-align-center;
        display: inline-flex;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-bottom: 10px;
        background: #e5e5e5;

        i {
            color: #555555;
        }
    }

    &__sender-name {
        padding: 0 15px;
        text-transform: uppercase;
        font-family: 'Quicksand', sans-serif;
        font-weight: bold;
        font-size: 0.8rem;
        margin-bottom: 0;
    }

    &__description {
        padding: 15px;
        margin-bottom: 0;
        font-family: 'Quicksand', sans-serif;
        text-align: center;
        white-space: pre-wrap;
        font-size: 0.8rem;
    }

    &__vouchers {
        display: flex;
        margin-bottom: 20px;
        width: 100%;
        align-items: center;

        .ccp {
            flex: 1 1 37.5%;
        }

        &--many {
            flex-wrap: wrap;
        }
    }

    &__item {
        flex: 1 1 50%;
        margin-bottom: 20px;
    }

    &__gift {
        flex: 1 1 25%;
        text-align: center;

        img {
            max-width: 100%;
            max-height: 60px;
        }
    }

    &__cta {
        display: block;
        width: 100%;
        border-radius: 0;
        padding: 10px 10px;
        text-transform: uppercase;
        font-family: 'Quicksand', sans-serif;
        font-size: 0.8rem;
    }
}

.clickable:hover {
    cursor: pointer;
}
</style>
