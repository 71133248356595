<template lang="pug">
    .navbar-shadow(:style='`border-bottom: 3px solid ${shadow};`')
        .container
            b-navbar(toggleable='md' type='light navbar-header')
                b-navbar-brand
                    router-link(to='/')
                        img.brand1(alt='Nack' src='~landing/images/app-icon.png')
                        img.brand2(alt='Nack' src='~landing/images/nack-text-logo-blue.png')
                b-navbar-toggle(target='core-nav')
                    i.far.fa-bars
                b-collapse#core-nav(is-nav)
                    slot
</template>

<script>
export default {
    props: {
        shadow: {
            default: '#0982cd',
        },
    },
}
</script>

<style lang="scss">
.navbar-shadow {
    background-color: white;
}

.navbar-header {
    padding-top: 23px;
    padding-bottom: 23px;
    font-size: 0.875rem;

    .brand1 {
        width: 48px;
    }

    .brand2 {
        margin-left: 20px;
        height: 25px;
    }

    .navbar-nav > a {
        color: #707070;
        font-weight: 500;
        text-transform: uppercase;
        margin-right: 40px;
    }

    .user {
        margin-right: 20px;
        float: right;

        &__picture {
            display: block;
            width: 50px;
            height: 50px;
            border: 1px solid #E6E6E6;
            border-radius: 50%;
            @include shadow;
        }
    }

    @include media-breakpoint-only(lg) {
        font-size: 12px;

        .navbar-nav > a {
            margin-right: 30px;
        }

        .btn.btn--pill {
            width: 150px;
        }
    }

    @include media-breakpoint-only(md) {
        .navbar-nav > a:not(.corp) {
            display: none;
        }

        .btn.btn--pill {
            width: 200px;
        }
    }

    @include media-breakpoint-down(sm) {
        .navbar-nav > a{
            margin: 10px 0;
        }

        .btn.btn--pill {
            width: 200px;
        }
    }
}
</style>
