<template lang="pug">
    prepare
        template(slot-scope='props')
            .container.internal-page
                .card
                    .card__body.card__body--lg
                        h4 Presets
                            b-btn.float-right(variant='primary' @click='editCategory')
                                i.far.fa-plus
                                |  New Category

                        .category(v-for='category in presetCategories' :key='category.id')
                            h5.text-muted
                                | {{ category.name }}
                                a.text-muted(href='#' @click.prevent='editCategory(category.id)'): i.fa.fa-pencil.ml-3

                            .category__scope
                                small(v-if='category.consumer')
                                    i.fa.fa-user
                                    |  Consumer

                                small(v-if='category.corporate')
                                    i.fa.fa-building
                                    |  Corporate

                            .category__presets
                                draggable(@end='onSortPresets(category.id, $event)')
                                    a(
                                        v-for='preset in categoryPresets(category.id)'
                                        :key='preset.id'
                                        href='#'
                                        @click.prevent='editPreset(preset.id)'
                                    )
                                        gift-header.category__preset(
                                            :gift='preset'
                                            :class='{"category__preset--disabled": !preset.active}'
                                        )

                                    .category__preset.category__preset--new-preset(
                                        slot='footer'
                                        @click='editPreset(null, category.id)'
                                    ): i.fa.fa-plus
</template>

<script>
import Draggable from 'vuedraggable'
import _ from 'lodash'
import Q from 'q'
import { mapActions, mapState } from 'vuex'

import PresetCategoryForm from './preset-category-form'
import PresetForm from './preset-form'

export default {
    components: { Draggable },

    computed: {
        ...mapState({
            presetCategories: state => state.admin.presetCategories,
            presets: state => state.admin.presets,
        }),
    },

    methods: {
        prepare() {
            return Q.all([
                this.loadPresetCategories(),
                this.loadPresets(),
            ])
        },

        categoryPresets(id) {
            return _.sortBy(_.filter(this.presets, { category: id }), 'order')
        },

        editCategory(id) {
            this.$present(PresetCategoryForm, { values: this.presetCategories[id] })
        },

        editPreset(id, category) {
            this.$present(PresetForm, { values: { category, ...this.presets[id] } })
        },

        onSortPresets(categoryId, e) {
            if (e.oldIndex === e.newIndex) return
            const target = this.categoryPresets(categoryId)[e.oldIndex]
            const replacing = this.categoryPresets(categoryId)[e.newIndex]
            this.reorderPreset({ id: target.id, order: replacing.order })
        },

        ...mapActions(['loadPresetCategories', 'loadPresets', 'reorderPreset']),
    },
}
</script>

<style lang="scss" scoped>
.category {
    margin-top: 50px;

    &__scope {
        color: #777;

        > small {
            display: inline-block;
            margin-right: 20px;
        }
    }

    &__presets > div {
        display: flex;
        flex-wrap: wrap;
    }

    &__preset {
        display: flex;
        width: 250px;
        height: 150px;
        margin-top: 20px;
        margin-right: 20px;
        border: 1px solid #eaeaea;
        border-radius: 5px;

        &:hover {
            cursor: pointer;
        }

        &--disabled {
            opacity: 0.2;
            border-color: #992222;
        }

        &--new-preset {
            @include flex-align-center;
            display: inline-flex;
        }
    }
}
</style>
