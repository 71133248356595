<template lang="pug">
    div
        modal-header Add Funds

        form.form(@submit.prevent='submit' v-if='!loading')
            .modal-body
                p.lead.text-center Please add ${{ (requiredFunds/100) }} to start this campaign.

                .alert.alert-warning(v-if='alert') {{ alert }}

                #card-element

            .modal-footer
                b-btn(@click='$vuedals.close(this)') Cancel
                b-btn.ml-3(variant='primary' type='submit') Pay ${{ (requiredFunds/100) }}

        spinner.m-auto(v-else)
</template>

<script>
import { mapActions } from 'vuex'

import { loadStripe } from '@stripe/stripe-js'

export default {
    props: ['requiredFunds', 'callback'],

    data() {
        return {
            alert: false,
            loading: false,

            name: '',
            number: '',
            exp: '',
            cvv: '',
        }
    },

    methods: {
        async submit() {
            const result = await this.$stripe.createToken(this.$card)

            this.loading = true
            this.alert = null

            if (result.error) {
                this.alert = result.error.message
                this.loading = false
            } else {
                try {
                    await this.addFunds({ token: result.token.id, amount: this.requiredFunds })
                } catch (e) {
                    this.alert = this.$drf.parseError(e).squashed
                    this.loading = false
                    return
                }
                if (this.callback) this.callback()
                this.$vuedals.close(this)
            }
        },

        ...mapActions(['addFunds']),
    },

    async mounted() {
        this.$stripe = await loadStripe(window.STRIPE_PUBLISHABLE_KEY)
        const elements = this.$stripe.elements()
        this.$card = elements.create('card')
        this.$card.mount('#card-element')
        this.$card.on('change', (e) => {
            if (e.error) {
                this.alert = e.error.message
            } else {
                this.alert = null
            }
        })
    },
}
</script>
