<template lang="pug">
    .gl__step-content
        template(v-if='!submitted')
            div.pb-1.pt-2 Who would you like to send a coffee to?
            v-form(:context='this')
                v-field(
                    :model='model.phone_number'
                    field='input'
                    type='tel'
                    placeholder='Enter a mobile number'
                )

                b-btn(variant='primary' type='submit') Send Link
        template(v-else)
            div.text-primary A gift has been sent! Refresh this page to send another coffee.
</template>

<script>
import { FormMixin } from 'core/components/forms/mixin'

export default {
    mixins: [FormMixin],

    endpoint: '/api/gifts/send_link_to_phone/',

    fields: ['phone_number'],
}
</script>
