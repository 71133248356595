<template lang="pug">
    .redeem
        .text-center
            .shop-details
                .shop-details__logo
                    .shop-image.shop-image--lg: img(:src='shop.logo')
                .shop-details__text
                    .shop-details__title {{ shop.name }}
                    .shop-details__funny(v-if='!fixed') (great choice!)
                    .shop-details__funny(v-else) (great shop!)
                    a.redeem__cancel(href='#' v-if='!fixed' @click.prevent='$emit("cancel")') Want a different shop?

        .alert.alert-warning(v-if='alert') {{ alert }}

        spinner.mx-auto.mt-3(v-if='busy')

        template(v-else-if='!isAuthenticated && pendingCode')
            h5.text-center.mt-2.cta-1 Almost there!
            p.text-muted.text-center.cta-2 Enter the code we sent to your phone!

            .form
                .form-group
                    input.form-control(name='code' placeholder='000000' type='tel' v-model='code')
                    .invalid-feedback.d-block(v-if='codeError') {{ codeError }}

                .text-center
                    button.btn.btn-primary(@click='submitCode') Get Gift Card

        template(v-else-if='!isAuthenticated || !hasProfile')
            h5.text-center.mt-2.cta-1 We just need a few quick details
            p.text-muted.text-center.cta-2 (to make sure you don't lose your gift)

            .form
                .form-group
                    input.form-control(name='first_name' placeholder='First Name' v-model='firstName')
                    .invalid-feedback.d-block(v-if='firstNameError') {{ firstNameError }}

                .form-group
                    input.form-control(name='last_name' placeholder='Last Name' v-model='lastName')
                    .invalid-feedback.d-block(v-if='lastNameError') {{ lastNameError }}

                .form-group(v-if='!isAuthenticated')
                    input.form-control(name='phone' type='tel' placeholder='Phone Number' v-model='phone')
                    small.form-text.text-muted(v-if='!phoneError') Don't worry, we won't share it.
                    .invalid-feedback.d-block(v-else) {{ phoneError }}


                .text-center
                    button.btn.btn-primary(v-if='!isAuthenticated' @click='submitFull') Continue
                    button.btn.btn-primary(v-else @click='submitProfile(true)') Get Gift Card

        template(v-else)
            p.text-center.mt-3 You'll have $5 to spend on coffee, or anything else on their menu.
            p.text-center You won't have to spend it all at once.
            p.text-center Once you choose {{ shop.name }} for this coffee, it can't be changed.

            .text-center
                button.btn.btn-primary(@click='claimAndRedeem') Get Gift Card
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    props: ['voucher', 'shop', 'fixed', 'trackLinkAction'],

    data() {
        return {
            alert: false,
            busy: false,
            pendingCode: false,

            phone: '',
            firstName: '',
            lastName: '',
            code: '',

            phoneError: false,
            firstNameError: false,
            lastNameError: false,
            codeError: false,
        }
    },

    computed: {
        itemInfo() {
            const { bundleHash, giftHash, code } = this.$route.params
            if (bundleHash) {
                return {
                    hash_code: bundleHash,
                    kind: 'bundle',
                }
            } else if (giftHash) {
                return {
                    hash_code: giftHash,
                    kind: 'gift',
                }
            }
            return {
                hash_code: code,
                kind: 'code',
            }
        },

        ...mapGetters(['isAuthenticated', 'hasProfile']),
    },

    methods: {
        validateNames() {
            this.firstNameError = false
            this.lastNameError = false

            if (!this.firstName.trim()) this.firstNameError = 'Be sure to fill this out.'
            if (!this.lastName.trim()) this.lastNameError = 'Be sure to fill this out.'
            if (this.firstName.length > 30) this.firstNameError = 'Please keep this under 30 characters.'
            if (this.lastName.length > 150) this.lastNameError = 'Please keep this under 150 characters.'

            return this.firstNameError === false && this.lastNameError === false
        },

        submitFull() {
            if (!this.validateNames()) return
            this.busy = true
            this.startLogin({ instance: { phone_number: this.phone } }).then(() => {
                this.pendingCode = true
                this.busy = false
                if (this.trackLinkAction) this.trackLinkAction('provided_info')
            }, (e) => {
                const error = this.$drf.parseError(e)
                this.phoneError = error.squashed
                this.busy = false
            })
        },

        submitCode() {
            this.busy = true
            this.finishLogin({
                instance: {
                    phone_number: this.phone,
                    code: this.code,
                    session: true,
                },
            }).then(() => {
                if (this.hasProfile) {
                    this.claimAndRedeem()
                } else {
                    this.createProfile()
                }
            }, (e) => {
                const error = this.$drf.parseError(e)
                this.codeError = error.squashed
                this.busy = false
            })
        },

        submitProfile(track) {
            if (!this.validateNames()) return
            this.createProfile()
            if (track && this.trackLinkAction) this.trackLinkAction('provided_info')
        },

        createProfile() {
            this.busy = true
            this.updateProfile({
                instance: {
                    first_name: this.firstName,
                    last_name: this.lastName,
                },
            }).then(() => {
                this.claimAndRedeem()
            }, () => {
                this.alert = 'Something went wrong, please try refreshing.'
                this.busy = false
            })
        },

        claimAndRedeem() {
            this.busy = true
            if (this.voucher) {
                // The gift / bundle has already been claimed, so we're ready to redeem it
                this.actuallyRedeemVoucher(this.voucher)
            } else {
                this.claim(`/api/gifts/claim/${this.itemInfo.kind}/${this.itemInfo.hash_code}/`).then((response) => {
                    const gift = response.data
                    for (const v of gift.vouchers) {
                        if (this.fixed && v.status === 'redeemed') {
                            this.$router.push(`/pay/${v.id}`)
                            return
                        }

                        if (v.redeemable && v.status === 'new') {
                            this.actuallyRedeemVoucher(v)
                            return
                        }
                    }

                    // Looks like this was a gift that can only be sent to others
                    this.alert = 'Please download the Nack app to claim this gift.'
                }, (e) => {
                    const error = this.$drf.parseError(e)
                    if (error.status === 400) {
                        this.alert = error.squashed
                    } else {
                        this.alert = 'Something went wrong, please try refreshing.'
                    }
                })
            }
        },

        actuallyRedeemVoucher(voucher) {
            this.redeemVoucher({ voucher, vendor: this.shop }).then(() => {
                if (this.trackLinkAction) this.trackLinkAction('redeemed')
                this.$router.push(`/pay/${voucher.id}`)
            }, (e) => {
                this.alert = this.$drf.parseError(e).squashed
            })
        },

        ...mapActions(['startLogin', 'finishLogin', 'updateProfile', 'claim', 'redeemVoucher']),
    },
}
</script>

<style lang="scss" scoped>
.redeem {
    &__cancel {
        display: block;
        position: relative;
        top: -10px;
        font-size: 0.8rem;
        line-height: 0.9rem;
    }
}

.shop-image {
    margin: 0 auto;
}

.shop-details {
    text-align: center;
    font-size: 1.4rem;
    display: inline-flex;
    margin: 0 auto;

    &__logo {
        flex: 0;
    }

    &__text {
        flex: 1;
        align-self: center;
        padding: 0 20px;
    }

    &__title {
        padding-top: 10px;
    }

    &__funny {
        margin: -5px 0 10px 0;
        font-size: 0.8rem;
    }
}

.cta-2 {
    margin-top: -10px;
}

.form {
    margin: 0 auto;
    max-width: 250px;
}
</style>
