<template lang="pug">
    div
        modal-header Shops Near You

        .nearby-splash(v-if='!shops')
            template(v-if='error')
                .nearby-splash-content
                    i.far.fa-location-circle
                    h4 {{ error }}
                    a(href='#' @click.prevent='getLocation') Try Again
            template(v-else-if='!coords')
                .nearby-splash-content
                    i.fas.fa-location-circle
                    h4 Getting location...
            template(v-else)
                .nearby-splash-content
                    i.fas.fa-location-circle
                    h4 Finding shops...

        .shops(v-else)
            .shop(
                v-for='shop in shops'
                :key='shop.id'
            )
                .shop__inner
                    img.shop__logo(:src='shop.logo')
                .shop__name {{ shop.name }}

</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            error: null,
            coords: null,
            shops: null,
        }
    },

    methods: {
        getLocation() {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.coords = position.coords
                }, () => {
                    this.error = "We weren't able to get your location."
                })
            } else {
                this.error = "Your browser doesn't support location services."
            }
        },

        loadShops() {
            axios.get(`/api/vendors/?lat=${this.coords.latitude}&lon=${this.coords.longitude}`).then((response) => {
                this.shops = response.data
            })
        },
    },

    mounted() {
        this.getLocation()
    },

    watch: {
        coords() {
            this.loadShops()
        },
    },
}
</script>

<style lang="scss" scoped>
.nearby-splash {
    @include flex-align-center;
}

.nearby-splash-content {
    text-align: center;
    color: #4b4b4b;

    i {
        font-size: 5.0rem;
    }

    h4 {
        margin-top: 10px;
        font-weight: 400;
    }
}

.shops {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.shop {
    margin: 20px;

    &__inner {
        @include flex-align-center;
        padding: 4px;
        border: 1px solid #ddd;
        border-radius: 50%;
        width: 100px;
        height: 100px;
    }

    &__logo {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__name {
        text-align: center;
        font-size: 0.8rem;
        text-transform: uppercase;
        color: #4b4b4b;
        font-weight: 500;
        margin-top: 5px;
    }
}
</style>
