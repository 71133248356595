import Vue from 'vue'

// Third party
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

// Landing
import LandingHeader from 'landing/components/header'
import LandingFooter from 'landing/components/footer'

// Gifts
import GiftEmojis from 'gifts/components/gift-emojis'
import GiftBackground from 'gifts/components/gift-background'
import GiftHeader from 'gifts/components/gift-header'
import GetTheApp from 'gifts/components/get-the-app'

// Forms
import VForm from './forms/v-form'
import VFormGroup from './forms/v-form-group'
import VField from './forms/v-field'
import VSlot from './forms/v-slot'

// Core
import Dropdown from './dropdown'
import ErrorView from './error'
import FixedScroll from './fixed-scroll'
import CoreHeader from './header'
import ModalHeader from './modal-header'
import Avatar from './avatar'
import Phone from './phone'
import Prepare from './prepare'
import Spinner from './spinner'

Vue.component('multiselect', Multiselect)

Vue.component('v-form', VForm)
Vue.component('v-form-group', VFormGroup)
Vue.component('v-field', VField)
Vue.component('v-slot', VSlot)

Vue.component('gift-emojis', GiftEmojis)
Vue.component('gift-background', GiftBackground)
Vue.component('gift-header', GiftHeader)
Vue.component('get-the-app', GetTheApp)

Vue.component('dropdown', Dropdown)
Vue.component('error', ErrorView)
Vue.component('fixed-scroll', FixedScroll)
Vue.component('core-header', CoreHeader)
Vue.component('modal-header', ModalHeader)
Vue.component('avatar', Avatar)
Vue.component('phone', Phone)
Vue.component('prepare', Prepare)
Vue.component('spinner', Spinner)

Vue.component('LandingHeader', LandingHeader)
Vue.component('LandingFooter', LandingFooter)
