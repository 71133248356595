<template lang="pug">
    v-form(:context='this')
        modal-header {{ mode }} Emoji

        .modal-body
            .text-center
                v-field.d-inline-block(
                    :model='model.png'
                    label='PNG Version'
                    hint='Emoji PNGs must be 512 x 512.'
                )
                    label.mb-0(for='emoji-png')
                        a.emoji-preview.emoji-preview--placeholder(v-if='!pngImg'): i.fa.fa-file-upload
                        img.emoji-preview(v-else :src='pngImg' alt='PNG Version')
                    input(
                        id='emoji-png'
                        type='file'
                        @change='pngSelection = $event.target.files[0]; $event.target.value = null'
                    )

                v-field.d-inline-block.ml-5(
                    :model='model.svg'
                    label='SVG Version'
                    hint='The SVG version of the emoji.'
                )
                    label.mb-0(for='emoji-svg')
                        a.emoji-preview.emoji-preview--placeholder(v-if='!svgImg'): i.fa.fa-file-upload
                        img.emoji-preview(v-else :src='svgImg' alt='SVG Version')
                    input(
                        id='emoji-svg'
                        type='file'
                        @change='svgSelection = $event.target.files[0]; $event.target.value = null'
                    )

            v-field(
                :model='model.name'
                field='input'
                label='Name'
                placeholder='coffee cup'
            )

            v-field(
                :model='model.tags'
                label='Tags'
                hint='These will be used to help users find the emoji when searching.'
            )
                multiselect(
                    v-model='model.tags.value'
                    placeholder=''
                    :options='[]'
                    multiple
                    taggable
                    @tag='addTag'
                )
                    template(slot='noOptions') Start typing to create a tag.

            v-field(
                v-if='id'
                :model='model.active'
                hint='Determines if the emoji should show up in the app.'
            )
                b-form-checkbox(v-model='model.active.value') Enabled

            template(v-if='values && values.code')
                hr
                .metadata
                    .meta
                        h6 Code
                        span {{ values.code }}
                    .meta
                        h6 Unicode Version
                        span {{ values.unicode_version }}
                    .meta
                        h6 Category
                        span {{ values.category }}
                    .meta
                        h6 Diversity
                        span {{ values.diversity || 'N/A' }}
                    .meta
                        h6 Gender
                        span {{ values.gender || 'N/A' }}
                small.text-muted This information is read-only because it is specific to actual emoji.

        .modal-footer
            b-btn.mt-2(variant='primary' type='submit') Save
</template>

<script>
import _ from 'lodash'

import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    mixins: [ModalFormMixin],

    fields: [
        'name',
        'png',
        'svg',
        { name: 'tags', default: () => [] },
        { name: 'active', default: true },
    ],

    dispatch: 'saveEmoji',

    data() {
        return {
            pngSelection: null,
            png: null,
            pngImg: this.values ? this.values.png : null,

            svgSelection: null,
            svg: null,
            svgImg: this.values ? this.values.svg : null,
        }
    },

    methods: {
        addTag(text) {
            this.model.tags.value = _.uniq([...this.model.tags.value, text])
        },

        clean(data) {
            delete data.png
            delete data.svg
            if (this.png) data.png = this.png
            if (this.svg) data.svg = this.svg
            return data
        },
    },

    watch: {
        pngSelection(val) {
            if (!val) return
            this.png = val
            const fr = new FileReader()
            fr.onload = () => {
                this.pngImg = fr.result
            }
            fr.readAsDataURL(val)
        },

        svgSelection(val) {
            if (!val) return
            this.svg = val
            const fr = new FileReader()
            fr.onload = () => {
                this.svgImg = fr.result
            }
            fr.readAsDataURL(val)
        },
    },
}
</script>

<style lang="scss" scoped>
.emoji-preview {
    display: block;
    width: 100px;
    height: 100px;
    border: 1px solid transparent;

    &--placeholder {
        @include flex-align-center;
        font-size: 3.0rem;
        border: 1px solid #eaeaea;

        i {
            color: #777;
        }
    }
}

#emoji-png, #emoji-svg {
    display: none;
}

.metadata {
    display: flex;
    justify-content: space-between;

    .meta {
        color: #777;
    }
}
</style>
