import axios from 'axios'
import _ from 'lodash'
import Vue from 'vue'

import { jsonToFormData } from 'core/util/server'

const initialState = window.STATE

const instanceId = (instance) => {
    if (instance === null) return undefined
    return typeof instance === 'object' ? instance.id : instance
}

const getEndpoint = (endpoint, id) => {
    // Get the actual id
    id = instanceId(id)

    // Ensure the endpoint has a trailing slash
    if (endpoint.substr(endpoint.length - 1, 1) !== '/') endpoint += '/'

    // Add the id if this is a detail URL
    if (id) endpoint += `${id}/`

    return endpoint
}

export const crudStore = (plural, endpoint, singular, initialKey, sortScope) => {
    // Determine alternate versions of the word
    const capPlural = plural.charAt(0).toUpperCase() + plural.slice(1)
    if (!singular) singular = plural.substr(0, plural.length - 1)
    const capSingular = singular.charAt(0).toUpperCase() + singular.slice(1)

    return {
        state: {
            [plural]: initialState[initialKey] ? _.keyBy(initialState[initialKey], 'id') : {},
        },

        mutations: {
            [`set${capPlural}`](state, instances) {
                state[plural] = _.keyBy(instances, 'id')
            },

            [`set${capSingular}`](state, instance) {
                Vue.set(state[plural], instance.id, instance)
            },

            [`delete${capSingular}`](state, instance) {
                const id = instanceId(instance)
                Vue.delete(state[plural], id)
            },
        },

        getters: {
            [`${singular}List`]: state => _.toArray(state[plural]),
            [`${singular}Count`]: (state, getters) => getters[`${singular}List`].length,
        },

        actions: {
            [`load${capPlural}`]({ commit }, options) {
                const request = axios.get(options?.url || getEndpoint(endpoint))
                request.then((response) => {
                    commit(`set${capPlural}`, response.data)
                })
                return request
            },

            [`load${capSingular}`]({ commit }, id) {
                const request = axios.get(getEndpoint(endpoint, id))
                request.then((response) => {
                    commit(`set${capSingular}`, response.data)
                })
                return request
            },

            [`load${capPlural}IfNeeded`]({ commit, state }, options) {
                if (state[plural] && !_.isEmpty(state[plural])) return true
                const request = axios.get(options?.url || getEndpoint(endpoint))
                request.then((response) => {
                    commit(`set${capPlural}`, response.data)
                })
                return request
            },

            [`save${capSingular}`]({ commit }, { instance, options }) {
                let method = 'post'
                const { id } = instance
                options = {
                    patch: true,
                    ...options,
                }

                // Determine the method and get the url
                if (id) method = options.patch ? 'patch' : 'put'
                const url = getEndpoint(endpoint, id)

                // Check if we need to use form data
                let payload = instance
                for (const val of Object.values(instance)) {
                    if (val instanceof Blob) {
                        payload = jsonToFormData(instance)
                        break
                    }
                }

                // Submit the request
                const request = axios({ method, url, data: payload })
                request.then((response) => {
                    commit(`set${capSingular}`, response.data)
                })
                return request
            },

            [`destroy${capSingular}`]({ commit }, id) {
                const request = axios.delete(getEndpoint(endpoint, id))
                request.then(() => {
                    commit(`delete${capSingular}`, id)
                })
                return request
            },

            [`reorder${capSingular}`]({ dispatch, commit, state }, { id, order }) {
                const target = state[plural][id]
                const context = sortScope ? _.filter(state[plural], { [sortScope]: target[sortScope] }) : state[plural]
                const orderedItems = _.sortBy(context, 'order')

                // Adjust the order of the other items in the collection
                if (order > target.order) {
                    // Bump everything between (including the target) up
                    for (const item of orderedItems) {
                        if (item.order > target.order && item.order <= order) {
                            item.order -= 1
                        }
                    }
                } else {
                    // Bump everything below where we are going down
                    for (const item of orderedItems) {
                        if (item.order >= order) {
                            item.order += 1
                        }
                    }
                }
                commit(`set${capPlural}`, state[plural])

                // Update the target
                return dispatch(`save${capSingular}`, { instance: { id, order } })
            },
        },
    }
}

export const combineStores = (stores) => {
    const props = ['state', 'mutations', 'getters', 'actions', 'modules']

    // Create the initial empty state
    const unified = {}
    _.each(props, (prop) => {
        unified[prop] = {}
    })

    // Add each store to the unified store
    _.each(stores, (store) => {
        _.each(props, (prop) => {
            if (store[prop]) unified[prop] = { ...unified[prop], ...store[prop] }
        })
    })

    return unified
}

export const api = url => (c, { instance }) => axios.post(url, instance)
