<template lang="pug">
    div
        modal-header Share Campaign

        .modal-body
            .text-center(v-if='!bulk')
                p Share this link with your recipients so they can claim their coffees.
                h5.text-center.d-none.d-md-block {{ campaign.link }}
                h6.text-center.d-md-none {{ campaign.link }}
                button.btn.btn-primary.my-2(@click='copyLink')
                    template(v-if='copied !== "link"')
                        i.far.fa-clipboard
                        |  Copy Promotional Link
                    template(v-else)
                        i.fas.fa-clipboard
                        |  Copied!
                p.text-muted.mt-2 This link can be shared with your recipients by texting, emailing, posting on social media, etc.

                hr

                .mt-4
                    h6 Want to send someone a link that can only be used once?
                    p Click this button to generate a unique link and have it copied to your clipboard.
                    button.btn.btn-primary(@click='copyUnique')
                        template(v-if='generating')
                            i.fas.fa-spin.fa-spinner
                            |  Generating...
                        template(v-else-if='copied !== "unique"')
                            i.far.fa-clipboard
                            |  Generate Single-Use Link
                        template(v-else)
                            i.fas.fa-clipboard
                            |  Copied!
                    .mt-2: a(href='#' @click.prevent='bulk = true' style='font-size: 0.8rem') Need a bunch of single use links?

                hr

                .mt-4
                    h6 Need to text, but don't want to use your personal number?
                    p Copy and bookmark this link so you can easily text out your campaign from Nack's official number.
                    button.btn.btn-primary(@click='copyEasyText')
                        template(v-if='copied !== "easyText"')
                            i.far.fa-comment-alt-lines
                            |  Copy EasyText Link
                        template(v-else)
                            i.fas.fa-comment-alt-lines
                            |  Copied!

            .text-center(v-else)
                template(v-if='!bulkData')
                    p We can generate an Excel file of single-use links for you.<br>How many would you like?

                    input.form-control.text-center(v-model='bulkQuantity' placeholder='0' style='max-width: 100px; margin: 0 auto')

                    button.btn.btn-primary.mt-3(@click='bulkGenerate')
                        template(v-if='bulkGenerating')
                            i.fas.fa-spin.fa-cog
                            |  Generating...
                        template(v-else)
                            i.far.fa-cog
                            |  Generate Codes

                    .mt-2: a(href='#' @click.prevent='bulk = false' style='font-size: 0.8rem') Just need one?
                template(v-else)
                    p Great news, your codes are ready!
                    a.btn.btn-primary.mt-3(:href='bulkData' download='nack_codes.xlsx')
                        i.fas.fa-download
                        |  Download Codes
</template>

<script>
import axios from 'axios'
import * as clipboard from 'clipboard-polyfill/build/clipboard-polyfill.promise'

export default {
    props: ['campaign'],

    data() {
        return {
            copied: null,
            generating: false,

            bulk: false,
            bulkQuantity: '',
            bulkGenerating: false,
            bulkData: null,
        }
    },

    methods: {
        copyLink() {
            const dt = new clipboard.DT()
            dt.setData('text/plain', this.campaign.link)
            dt.setData('text/html', `<a href="${this.campaign.link}" target="_blank">Click here to get your coffee!</a>`)
            clipboard.write(dt)
            this.copied = 'link'
        },

        copyEasyText() {
            clipboard.writeText(`${this.campaign.link}#text`)
            this.copied = 'easyText'
        },

        copyUnique() {
            this.generating = true
            axios.post(`/api/corporate/campaigns/${this.campaign.id}/generate_single_use_code/`).then((response) => {
                const dt = new clipboard.DT()
                dt.setData('text/plain', response.data.link)
                dt.setData('text/html', `<a href="${response.data.link}" target="_blank">Click here to get your coffee!</a>`)
                clipboard.write(dt)
                this.generating = false
                this.copied = 'unique'
            })
        },

        bulkGenerate() {
            this.bulkGenerating = true
            axios.post(`/api/corporate/campaigns/${this.campaign.id}/generate_bulk_single_use_codes/`, {
                quantity: this.bulkQuantity,
            }, {
                responseType: 'arraybuffer',
            }).then((response) => {
                const excelBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                this.bulkData = window.URL.createObjectURL(excelBlob)
                this.bulkGenerating = false
            }, () => {
                this.bulkGenerating = false
            })
        },
    },

    watch: {
        copied(val) {
            if (val) {
                setTimeout(() => {
                    this.copied = null
                }, 3000)
            }
        },
    },
}
</script>
