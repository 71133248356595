<template lang="pug">
    section.campaigns
        h1.text-center.pt-3 Campaigns

        slick(:options='slickOptions')
            .slide
                img(alt='Campaign message editor' src='~landing/images/campaign-message.png')

                .slide__title
                    i.fas.fa-comment
                    span Message
                .slide__text Craft the message you<br>want to promote

            .slide
                img(alt='Coffee quantity picker' src='~landing/images/campaign-budget.png')

                .slide__title
                    i.fas.fa-hand-holding-usd
                    span Budget
                .slide__text Set your budget and choose how<br>many coffees you want to send

            .slide
                img(alt='Craft a coffee code' src='~landing/images/campaign-coffee-code.png')

                .slide__title
                    i.fas.fa-share
                    span Share
                .slide__text Share coffees with<br>coffee codes or links.

            .slide
                img(alt='Grow your brand' src='~landing/images/campaign-branding.png')

                .slide__title
                    i.fas.fa-flag
                    span Marketing
                .slide__text When a recipient shares a coffee<br>your brand is shared with it

        .text-center.mt-4
            router-link.btn.btn--dark.btn--pill(to='/corporate') Start a Campaign
</template>

<script>
import Slick from 'vue-slick'

export default {
    components: { Slick },

    data() {
        return {
            slickOptions: {
                centerMode: true,
                centerPadding: '20%',
                slidesToShow: 1,
                arrows: false,
                focusOnSelect: true,

                // Ugly fix for flickering
                // useTransform: false,
                // easing: 'swing',
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.campaigns {
    background: $nack-primary;
    color: white;
    padding-left: 0;
    padding-right: 0;
}

.slide {
    text-align: center;
    margin-top: 40px;
    padding: 0 10%;

    img {
        width: 100%;
    }

    &__title {
        font-size: 1.8rem;
        text-transform: uppercase;
        font-weight: 800;
        display: flex;
        justify-content: center;
        margin-top: 40px;

        i {
            font-size: 42px;
        }

        span {
            margin-left: 10px;
        }
    }

    &__text {
        font-weight: 500;
        margin-top: 10px;
    }
}
</style>
