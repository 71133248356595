<template lang="pug">
    div
        div(@click='open = true')
           slot(name='activator')
        .vue-dropdown(v-if='open' :style='adjustments')
            .vue-dropdown__content(v-on-clickaway='close' :class='right ? "vue-dropdown__content--right" : ""')
                slot(name='content')
                    .vue-dropdown__padding
                        slot
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
    props: {
        right: Boolean,

        relTop: {
            type: Number,
            default: 0,
        },

        relLeft: {
            type: Number,
            default: 0,
        },
    },

    mixins: [clickaway],

    data() {
        return {
            open: false,
        }
    },

    computed: {
        adjustments() {
            return {
                top: `${this.relTop}px`,
                left: `${this.relLeft}px`,
            }
        },
    },

    methods: {
        close() {
            this.open = false
        },
    },
}
</script>

<style lang="scss">
.vue-dropdown {
    position: relative;
    z-index: 4;

    &__content {
        @include shadow;
        position: absolute;
        top: 0;
        left: 0;
        background: #fcfcfc;
        border-radius: 12px;
    }

    &__content--right {
        left: auto;
        right: 0;
    }

    &__padding {
        padding: 10px;
    }

    &__list {
        padding: 0;
        margin: 0;

        li {
            display: block;

            &:not(:last-child) {
                border-bottom: 1px solid #eaeaea;
            }

            &:first-child {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }

            &:last-child {
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
            }

            a {
                display: block;
                padding: 15px;
                color: #333;
                white-space: nowrap;
            }

            &:hover {
                background: #f4f4f4;

                a {
                    text-decoration: none;
                }
            }
        }
    }
}
</style>
