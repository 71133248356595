<template lang="pug">
    core-header(shadow='#c2c2c2')
        b-navbar-nav.ml-auto
            slot
        .user
            dropdown(right :relTop='5')
                a(href='#' @click.prevent='' slot='activator')
                    img.user__picture(:src='company.logo' :alt='company.name' v-if='company.logo')
                    .user__picture(v-else)
                ul.vue-dropdown__list(slot='content')
                    li: a(href='#' @click.prevent='changePassword') Change Password
                    li: a(href='#' @click.prevent='logout') Log out
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import ChangePasswordForm from 'accounts/components/change-password-form'

export default {
    components: { ChangePasswordForm },

    computed: {
        ...mapState({
            company: state => state.corporate.company,
        }),
    },

    methods: {
        changePassword() {
            this.$present(ChangePasswordForm)
        },

        ...mapMutations(['logout']),
    },
}
</script>
