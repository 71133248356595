import axios from 'axios'
import _ from 'lodash'

import { crudStore, combineStores } from 'core/util/vuex'

const giftsStore = combineStores([
    crudStore('gifts', '/api/gifts/my_gifts/'),

    {
        getters: {
            normalizeGift: () => (gift) => {
                const g = { ...gift }

                // Determine sender information
                g.sender = g.sender ? {
                    name: `${g.sender.first_name} ${g.sender.last_name}`,
                    name2: null,
                    photo: g.sender.photo,
                    user: g.sender,
                } : {
                    name: g.campaign.sender_name,
                    name2: g.campaign.sender_name2,
                    photo: g.campaign.sender_photo,
                }

                return g
            },

            vouchers: (state) => {
                const vouchers = {}
                _.each(state.gifts, (g) => {
                    _.each(g.vouchers, (v) => {
                        vouchers[v.id] = { gift: g.id, ...v }
                    })
                })
                return vouchers
            },

            giftCards: (state, getters) => {
                const giftCards = {}
                _.each(getters.vouchers, (v) => {
                    if (v.gift_card) {
                        giftCards[v.gift_card.id] = {
                            gift: state.gifts[v.gift],
                            voucher: v,
                            ...v.gift_card,
                        }
                    }
                })
                return giftCards
            },
        },

        mutations: {
            markVoucherAsRedeemed(state, { gift_id, voucher_id, giftCard }) { // eslint-disable-line camelcase
                const gift = state.gifts[gift_id]
                const voucher = _.find(gift.vouchers, { id: voucher_id })
                voucher.status = 'redeemed'
                voucher.gift_card = giftCard
            },
        },

        actions: {
            claim({ commit }, url) {
                const request = axios.post(url)
                request.then((response) => {
                    commit('setGift', response.data)
                })
                return request
            },

            redeemVoucher({ getters, commit }, { voucher, vendor }) {
                const request = axios.post('/api/gifts/redeem_voucher/', {
                    voucher: voucher.id,
                    vendor: vendor.id,
                })
                request.then((response) => {
                    commit('markVoucherAsRedeemed', {
                        gift_id: getters.vouchers[voucher.id].gift,
                        voucher_id: voucher.id,
                        giftCard: response.data,
                    })
                })
                return request
            },
        },
    },
])

export default giftsStore
