<template lang="pug">
    .auth
        .auth__photo
        .auth__content.text-center
            v-form(:context='this' v-if='!submitted')
                h5 What's your phone number?
                v-field(
                    :model='model.phone_number'
                    field='input'
                    placeholder='Phone Number'
                    type='tel'
                )
                b-btn.btn-block.mt-2(variant='primary' type='submit') Continue

            sms-code(v-else :phone-number='model.phone_number.value')
</template>

<script>
import { FormMixin } from 'core/components/forms/mixin'
import SmsCode from './sms-code'

export default {
    mixins: [FormMixin],

    components: { SmsCode },

    fields: ['phone_number'],

    dispatch: 'startLogin',

    created() {
        if (this.$store.getters.isAuthenticated) this.$router.push(this.$route.query.redirect || '/')
    },
}
</script>
