<template lang="pug">
    .container.internal-page
        .card
            .card__body.card__body--lg
                h4 Backgrounds
                    b-btn.float-right(variant='primary' @click='editBackground')
                        i.far.fa-plus
                        |  Add Background

                div
                    draggable(@end='onSortBackgrounds')
                        a(
                            v-for='b in backgroundsList'
                            :key='b.id'
                            href='#'
                            @click.prevent='editBackground(b.id)'
                        )
                            gift-background.background(
                                :class='{"background--disabled": !b.active}'
                                :background='b'
                                thumb
                            )
</template>

<script>
import Draggable from 'vuedraggable'
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'

import BackgroundForm from './background-form'

export default {
    components: { Draggable },

    computed: {
        backgroundsList() {
            return _.sortBy(this.backgrounds, 'order')
        },

        ...mapState({
            backgrounds: state => state.admin.backgrounds,
        }),
    },

    methods: {
        editBackground(id) {
            this.$present(BackgroundForm, { values: this.backgrounds[id] })
        },

        onSortBackgrounds(e) {
            if (e.oldIndex === e.newIndex) return
            const target = this.backgroundsList[e.oldIndex]
            const replacing = this.backgroundsList[e.newIndex]
            this.reorderBackground({ id: target.id, order: replacing.order })
        },

        ...mapActions(['reorderBackground']),
    },
}
</script>

<style lang="scss" scoped>
.background {
    width: 64px;
    height: 64px;
    border-radius: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;

    &--disabled {
        opacity: 0.3;
    }
}
</style>
