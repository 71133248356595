<template lang="pug">
    prepare
        template(slot-scope='props')
            .splash(v-if='Object.keys(campaigns).length === 0')
                .splash__content
                    i.fa.fa-gift
                    h3 You don't have any campaigns yet!
                    .splash__actions
                        b-btn(variant='primary' to='/corporate/edit-campaign/') Start Campaign
                        a.btn.btn-secondary(href='https://youtu.be/xbFk-ZzU33A' target='_blank') Learn about gifting with Nack

            .internal-page(v-else)
                .container
                    .row
                        .col-md-3.order-md-2
                            .card.card__body.card__body--lg.mb-3
                                div: strong {{ company.name }}
                                small Balance: ${{ (company.balance / 100).toLocaleString() }}
                        .col-md-9.order-md-1
                            campaign-list.mb-3
</template>

<script>
import { mapActions, mapState } from 'vuex'

import CampaignList from './campaign-list'

export default {
    components: { CampaignList },

    computed: {
        ...mapState({
            campaigns: state => state.corporate.campaigns,
            company: state => state.corporate.company,
        }),
    },

    methods: {
        prepare() {
            return this.loadCampaigns()
        },

        ...mapActions(['loadCampaigns']),
    },
}
</script>
