<template lang="pug">
    div
        modal-header Change Password

        v-form(:context='this')
            .modal-body
                v-field(
                    :model='model.old_password'
                    label='Current Password'
                    field='input'
                    type='password'
                    help="For your security, what's your current password?"
                    focus
                )

                v-field(
                    :model='model.new_password1'
                    label='New Password'
                    field='input'
                    type='password'
                    help="Great, what do you want your new password to be?"
                )

                v-field(
                    :model='model.new_password2'
                    label='Confirm Password'
                    field='input'
                    type='password'
                    help="Enter it once more, just to be sure."
                )

            .modal-footer
                b-btn(@click='$vuedals.close(this)') Cancel
                b-btn(variant='primary' type='submit') Submit
</template>

<script>
import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    mixins: [ModalFormMixin],

    endpoint: '/api/accounts/change_password/',

    fields: ['old_password', 'new_password1', 'new_password2'],
}
</script>
