<template lang="pug">
    .card
        .card__body.card__body--lg.editor__card-body
            h5.editor__title How many gifts would you like to send?
            p.editor__subtitle.
                Each gift has {{ model.vouchers.length | pluralize('coffee', 'coffees') }}.
                Recipients can only claim a gift once.

            .form.text-center
                a.quantity-btn(v-if='!model.enabled' href='#' @click.prevent='' @mousedown='startHold(-1)' @mouseup='stopHold' @mouseleave='stopHold'): i.fas.fa-minus-circle
                v-form-group.d-inline-block(
                    :error='errors.quantity'
                )
                    b-form-input.quantity(v-model='model.quantity' :disabled='model.enabled')
                a.quantity-btn(v-if='!model.enabled' href='#' @click.prevent='' @mousedown='startHold(1)' @mouseup='stopHold' @mouseleave='stopHold'): i.fas.fa-plus-circle

            .text-center
                small.text-muted Please pause the campaign to change its budget.

            .budget-label.budget-label--small
                span {{ totalCoffees.toLocaleString() }} Coffees x ${{ (model.per_voucher_fee / 100) }}:
                span ${{ (totalCoffees * (model.per_voucher_fee / 100)).toLocaleString() }}
            .budget-label.budget-label--small
                span Gift Fee:
                span(v-if='model.per_gift_fee > 0') ${{ (model.quantity * (model.per_gift_fee / 100)).toLocaleString() }}
                span(v-else) Waived
            .budget-label
                span Total Budget:
                span ${{ budget }}
            .float-right
                b-btn(variant='primary' @click='$emit("next")') Next
</template>

<script>
export default {
    props: ['model'],

    data() {
        return {
            errors: {},
        }
    },

    computed: {
        totalCoffees() {
            const { quantity } = this.model
            return this.model.vouchers.length * quantity
        },

        budget() {
            const { quantity } = this.model
            const coffeesPerGift = this.model.vouchers.length
            const coffeeCost = coffeesPerGift * (this.model.per_voucher_fee / 100) * quantity
            const quantityFee = (this.model.per_gift_fee / 100) * quantity
            return (coffeeCost + quantityFee).toLocaleString()
        },
    },

    methods: {
        startHold(amount) {
            let changed = 0
            this.model.quantity += amount

            const setHoldInterval = (frequency) => {
                clearInterval(this.holdInterval)
                this.holdInterval = setInterval(() => {
                    this.model.quantity += amount

                    // Increase the speed if they keep holding it down
                    changed += 1
                    if (changed > 1000 && frequency > 1) {
                        setHoldInterval(1)
                    } else if (changed > 300 && frequency > 3) {
                        setHoldInterval(3)
                    } else if (changed > 200 && frequency > 10) {
                        setHoldInterval(10)
                    } else if (changed > 50 && frequency > 20) {
                        setHoldInterval(20)
                    }
                }, frequency)
            }

            this.holdTimeout = setTimeout(() => {
                setHoldInterval(40)
            }, 500)
        },

        stopHold() {
            clearTimeout(this.holdTimeout)
            clearInterval(this.holdInterval)
        },

        validate() {
            return this.model.quantity > 0
        },
    },

    watch: {
        'model.quantity': function onQuantityChange(val) {
            const quantity = parseInt(val, 10)
            if (Number.isNaN(quantity) || quantity < 1) {
                this.model.quantity = 1
            } else {
                this.model.quantity = quantity
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.form .form-control.quantity {
    display: inline-block;
    color: $nack-primary;
    font-size: 3.0rem;
    width: 200px;
    padding: 20px;
    height: 80px;
    text-align: center;
}

.quantity-btn i {
    position: relative;
    top: -5px;
    font-size: 2.0rem;
    color: $nack-primary;
    margin: 10px;
}

.budget-label {
    text-align: right;
    margin-bottom: 10px;

    span:first-child {
        font-weight: 500;
        color: $nack-primary;
        margin-right: 5px;
    }

    span:last-child {
        color: #767676;
    }

    &--small {
        font-size: 0.8rem;
        margin-bottom: 5px;

        span:first-child {
            color: #767676;
        }
    }
}
</style>
