<template lang="pug">
    .card
        .card__body.card__body--lg.editor__card-body
            h5.editor__title Any vendor restrictions?
            p.editor__subtitle Recipients can normally redeem a coffee anywhere.

            .form
                b-form-radio-group(v-model='allVendors' stacked)
                    b-form-radio(:value='true') Let them get a coffee from anywhere
                    b-form-radio(:value='false' disabled style='opacity:0.5') Make them get coffee from a specific coffee shop (Coming soon)

                v-form-group.mt-3(
                    v-if='!allVendors'
                    :error='errors.fixed_vendor'
                    style='width: 300px'
                )
                    multiselect(
                        :value='model.fixed_vendor'
                        @input='changeFixed'
                        track-by='id'
                        label='name'
                        placeholder='Search for a shop...'
                        :options='options'
                        :loading='loading'
                        @search-change='search'
                        :show-labels='false'
                    )
                        span(slot='noResult') No shops matched

            .float-right
                b-btn(variant='primary' @click='$emit("next")') Next
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

export default {
    props: ['model'],

    data() {
        return {
            allVendors: !this.model.fixed_vendor,
            errors: {},
            loading: false,
            options: this.model.fixed_vendor ? [this.model.fixed_vendor] : [],
        }
    },

    methods: {
        search(s) {
            this.loading = true
            axios.get(`/api/vendors/?search=${s}`).then((response) => {
                this.loading = false
                this.options = _.uniqBy(this.options.concat(response.data), 'id')
            })
        },

        changeFixed(item) {
            if (!item) return
            this.model.fixed_vendor = item
        },

        validate() {
            this.errors = {}

            if (!this.allVendors) {
                if (!this.model.fixed_vendor) {
                    this.errors.fixed_vendor = 'Please choose a shop.'
                    return false
                }
            } else {
                this.model.fixed_vendor = null
            }
            return true
        },
    },
}
</script>
