<template lang="pug">
    v-form(:context='this')
        modal-header {{ mode }} Preset

        template(v-if='step == "emojis"')
            .modal-body
                Legend.col-form-label Choose Emojis for the Preset
                emoji-picker(v-model='model.emoji.value')
            .modal-footer
                b-btn(variant='primary' type='button' @click='step = "background"') {{ model.emoji.value.length ? "Continue" : "Skip" }}

        template(v-else-if='step == "background"')
            .modal-body
                Legend.col-form-label Choose a Background for the Preset
                background-picker.mt-3(v-model='model.background.value' :emoji='model.emoji.value')
            .modal-footer
                b-btn(v-if='model.background.value' variant='primary' type='button' @click='step = "edit"') Continue

        template(v-else)
            .modal-body
                gift-header.header-preview(:gift='header')
                .text-center
                    a.change-header(href='#' @click.prevent='step = "emojis"') Change

                v-field(
                    :model='model.name'
                    field='input'
                    placeholder='Name'
                    label='Name'
                    hint='A short name to help users understand what this preset is about.'
                )

                v-field(
                    :model='model.suggested_message'
                    field='input'
                    placeholder='Suggested Message'
                    label='Suggested Message'
                    hint='The app will automatically suggest this message to the user when they select this preset.'
                )

                v-field(
                    :model='model.category'
                    field='select'
                    label='Category'
                    :options='presetCategoryList'
                )

                v-field(
                    v-if='id'
                    :model='model.active'
                    hint='Determines if the preset should be visible within the category.'
                )
                    b-form-checkbox(v-model='model.active.value') Enabled

            .modal-footer
                b-btn.mt-2(variant='primary' type='submit') Continue
</template>

<script>
import { mapGetters } from 'vuex'

import { ModalFormMixin } from 'core/components/forms/mixin'

import EmojiPicker from './emoji-picker'
import BackgroundPicker from './background-picker'

export default {
    mixins: [ModalFormMixin],

    components: { EmojiPicker, BackgroundPicker },

    fields: [
        'name',
        'category',
        { name: 'emoji', default: () => [] },
        'background',
        'suggested_message',
        { name: 'active', default: true },
    ],

    dispatch: 'savePreset',

    data() {
        // Determine the current step
        let step = 'edit'
        if (!this.values?.emoji?.length && !this.values?.background) step = 'emojis'

        return { step }
    },

    computed: {
        header() {
            return {
                background: this.model.background.value,
                emoji: this.model.emoji.value,
            }
        },

        ...mapGetters(['presetCategoryList']),
    },
}
</script>

<style lang="scss" scoped>
.header-preview {
    margin: 0 auto;
    width: 250px;
    max-width: 100%;
    border-radius: 14px;
    @include shadow;
}

.change-header {
    display: inline-block;
    font-size: 0.8rem;
    color: #767676;
    margin: 5px 0 15px 0;
}
</style>
