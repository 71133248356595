<template lang="pug">
    gift-background.gift-header(:background='background' ref='background')
        gift-emojis(v-if='emojiSize' :emoji='emojis' :size='emojiSize')
</template>

<script>
export default {
    props: {
        gift: {
            type: Object,
        },

        maxEmojiSize: {
            type: Number,
            default: 64,
        },
    },

    data() {
        return {
            availableWidth: null,
        }
    },

    computed: {
        emojis() {
            return this.gift.emoji
        },

        emojiSize() {
            if (!this.availableWidth || !this.emojis.length) return false
            return Math.floor(Math.min(this.maxEmojiSize, this.availableWidth / this.emojis.length))
        },

        background() {
            return this.gift.background
        },
    },

    mounted() {
        this.availableWidth = this.$refs.background.$el.offsetWidth - 42 // 40 = padding, 2 = border
    },
}
</script>

<style lang="scss" scoped>
.gift-header {
    @include flex-align-center;
    padding: 20px;
    text-align: center;
    height: 150px;
    max-height: 100%;
}
</style>
