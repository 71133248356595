<script>
export default {
    props: ['model', 'field'],

    computed: {
        formGroupData() {
            if (!this.model) throw new Error('Ensure all fields are registered in the fields list!')
            return false
        },
    },

    render(createElement) {
        let valueProp = 'value'
        if (['radio-group'].indexOf(this.field) > -1) valueProp = 'checked'
        if (['checkbox-group'].indexOf(this.field) > -1) valueProp = 'checked'

        return createElement(`b-form-${this.field || 'input'}`, {
            props: {
                [valueProp]: this.model.value,
                state: this.model.error ? false : null,
                ...this.$attrs,
            },
            on: {
                input: (val) => { this.model.value = val },
            },
            nativeOn: {
                focus: () => { this.model.error = null },
            },
            scopedSlots: this.$scopedSlots,
        })
    },
}
</script>
