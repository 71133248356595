<template lang="pug">
    .vp(v-if='!chosenShop')
        h5.vp__header Choose a shop to get a <strong class="vp__gift-card-callout">$5 gift card</strong>
        template(v-if='showExplanation')
            .vp__instructions You can buy any items (☕️ 🥪 🍩) from their menu using your phone!
            .vp__instructions Don't worry if you aren't at the shop yet, you'll be able to use the card later too.
        .text-center(v-else): a.btn-text(@click.prevent='showExplanation = true' href='#') (How does this work?)

        prepare(ref='prepare')
            template(slot-scope='props')
                .mt-4
                    .scale(v-for='scale in shopsByScale' :key='scale.id' v-if='scale.shops.length')
                        .scale__header
                            i.fas.scale__icon(:class='`fa-${scale.icon}`')
                            .scale__name {{ scale.name }}
                        .shops
                            .shop(v-for='shop in scale.shops' :key='shop.id' @click='chooseShop(shop)')
                                .shop-image: img(:src='shop.logo')
                                .shop__name {{ shop.name.toUpperCase() }}

                    .vp__no-results(v-if='!shops.length') Hmm, we couldn't find any shops.

                    template(v-if='(!attemptGeolocation || geolocationError) && !search')
                        .scale
                            .scale__header
                                i.fas.scale__icon.fa-compass
                                .scale__name Local and Regional Shops
                            .geo-prompt
                                template(v-if='!geolocationError')
                                    .geo-prompt__text We need your location to find shops near you
                                    .text-center: a.btn-text(@click.prevent='loadWithGeolocation' href='#') Show local shops
                                template(v-else)
                                    Label.geo-prompt__text {{ geolocationError }}
                                    .text-center: a.btn-text(@click.prevent='loadWithGeolocation' href='#') Try again

                    template(v-if='geoLoading')
                        .scale
                            .scale__header
                                i.fas.scale__icon.fa-compass
                                .scale__name Local and Regional Shops
                            .geo-prompt
                                .geo-prompt__text Looking for local and regional shops...

                    template(v-if='notifyNoLocalShops')
                        .scale
                            .scale__header
                                i.fas.scale__icon.fa-compass
                                .scale__name Local Shops
                            .geo-prompt
                                .geo-prompt__text It looks like we don't have any local shops in your area (yet)
                                .text-center: a.btn-text(@click.prevent='requestShop' href='#') Request a shop
                    template(v-else-if='resultsUsedLocation || search')
                        .geo-prompt.m-t-20
                            .geo-prompt__text Not seeing the shop you're looking for?
                            .text-center: a.btn-text(@click.prevent='requestShop' href='#') Request a shop

    .vp(v-else)
        h5.vp__header(v-if='fixed') They gave you a <strong class="vp__gift-card-callout">$5 gift card</strong>
        redeem-voucher(:voucher='voucher' :shop='chosenShop' :fixed='fixed' @cancel='chosenShop = null' :trackLinkAction='trackLinkAction')
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

import RedeemVoucher from './redeem-voucher'

export default {
    props: ['voucher', 'fixed', 'trackLinkAction'],

    components: { RedeemVoucher },

    data() {
        return {
            location: null,
            attemptGeolocation: false,
            geolocationError: null,
            geoLoading: false,
            showExplanation: false,

            shops: [],
            chosenShop: null,
            search: '',
            resultsUsedLocation: false,
            scales: [
                { id: 'national', name: 'National Shops', icon: 'globe-americas' },
                { id: 'regional', name: 'Regional Shops', icon: 'globe' },
                { id: 'local', name: 'Local Shops', icon: 'compass' },
            ],
        }
    },

    computed: {
        shopsByScale() {
            return _.map(this.scales, scale => ({
                ...scale,
                shops: _.sortBy(_.filter(this.shops, { scale: scale.id }), 'name'),
            }))
        },

        notifyNoLocalShops() {
            if (this.resultsUsedLocation) {
                return !_.find(this.shops, { scale: 'local' })
            }
            return false
        },
    },

    methods: {
        prepare() {
            return new Promise((resolve, reject) => {
                if (this.fixed) {
                    axios.get(`/api/vendors/${this.fixed}/`).then((response) => {
                        const shop = response.data
                        this.shops = [shop]
                        this.keyedShops = { [shop.id]: shop }
                        this.resultsUsedLocation = false
                        this.chooseShop(shop)
                    }, reject)
                    return
                }

                let url = '/api/vendors/'
                let usedLocation = false

                const makeRequest = () => {
                    axios.get(url).then((response) => {
                        this.shops = response.data
                        this.keyedShops = { ...this.keyedShops, ..._.keyBy(this.shops, 'id') }
                        this.resultsUsedLocation = usedLocation
                        resolve()
                    }, reject)
                }

                if (this.search) {
                    url += `?search=${encodeURI(this.search.trim())}`
                } else if (this.location) {
                    url += `?lat=${this.location.latitude}&lon=${this.location.longitude}`
                    usedLocation = true
                }

                if (!this.search && !this.location && this.attemptGeolocation) {
                    this.getLocation().then(() => {
                        if (this.location) {
                            this.prepare().then(resolve, reject)
                        } else {
                            makeRequest()
                        }
                    })
                } else {
                    makeRequest()
                }
            })
        },

        getLocation() {
            return new Promise((resolve, reject) => {
                if ('geolocation' in navigator) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        this.location = position.coords
                        resolve()
                    }, () => {
                        this.geolocationError = "We weren't able to get your location."
                        reject()
                    })
                } else {
                    this.geolocationError = "Your browser doesn't support location services."
                    reject()
                }
            })
        },

        loadWithGeolocation() {
            this.geoLoading = true
            this.attemptGeolocation = true
            this.prepare().then(() => {
                this.geoLoading = false
            }, () => {
                this.geoLoading = false
            })
        },

        chooseShop(shop) {
            this.chosenShop = shop
            if (this.trackLinkAction) this.trackLinkAction('chose_shop')
        },

        requestShop() {
            window.Intercom('showNewMessage', "Hello, I'd like to request a coffee shop!")
        },
    },
}
</script>

<style lang="scss" scoped>
.vp {
    &__header {
        text-align: center;
        font-weight: 400;
        margin-bottom: 20px;
    }

    &__instructions {
        text-align: center;
        color: #777;
        margin: 10px 0;
    }

    &__gift-card-callout {
        color: $nack-primary;
        display: block;
        font-size: 2.0rem;
    }
}

.scale {
    margin-bottom: 30px;

    &__header {
        display: flex;
        color: #163B58;
        margin-bottom: 15px;
    }

    &__icon {
        font-size: 24px;
        margin-right: 10px;
    }

    &__name {
        font-size: 18px;
        font-weight: 800;
    }
}

.shops {
    display: flex;
    flex-wrap: wrap;
}

.shop {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @media(min-width: 800px) {
        width: 25%;
    }

    @media(min-width: 1200px) {
        width: 20%;
    }

    &:hover {
        cursor: pointer;
    }

    &__name {
        color: #B2B2B2;
        font-size: 18px;
        font-weight: 800;
        text-align: center;
        margin-top: 4px;
    }
}

.geo-prompt {
    &__text {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        color: #B2B2B2;
    }
}
</style>
