<template lang="pug">
    .chains
        template(v-for='chain, idx in data')
            .chain(:key='idx')
                template(v-for='user, cidx in chain')
                    i.fa.fa-arrow-right.chain__user-divider(v-if='cidx > 0' :key='`divider${user.id}${cidx}`')
                    .chain__user(:key='`user${user.id}${cidx}`')
                        template(v-if='user.name && user.username')
                            avatar(:sender='user')
                            p.mb-0(v-b-tooltip :title='`@${user.username}`') {{ user.name }}
                            p.chain__timestamp {{ user.date | timeFromNow }}
                            img.chain__vendor-logo(
                                v-if='user.vendor_logo'
                                :src='user.vendor_logo'
                                v-b-tooltip
                                :title='`Redeemed at ${user.vendor_name}`'
                            )
                        template(v-else)
                            div(
                                v-b-tooltip
                                :title='`${chain[cidx - 1].name} shared a coffee, but the recipient has not claimed it yet.`'
                                style='opacity: 0.5'
                            )
                                avatar(:sender='emptyUser')
                                p.mb-0 Pending
                                p.chain__timestamp {{ user.date | timeFromNow }}
            hr(v-if='idx < data.length - 1' :key='`hr${idx}`')
</template>

<script>
import _ from 'lodash'

export default {
    props: ['chains'],

    data() {
        return {
            emptyUser: {
                name: '',
                photo: null,
            },
        }
    },

    computed: {
        data() {
            return _.map(this.chains, c => _.orderBy(c.chain, 'depth'))
        },
    },
}
</script>

<style lang="scss" scoped>
.chain {
    display: flex;
    justify-content: flex-start;

    &__user {
        text-align: center;
        position: relative;
    }

    &__timestamp {
        font-size: 0.6rem;
        margin-bottom: 0;
    }

    &__user-divider {
        font-size: 1.0rem;
        align-self: center;
        padding: 0 20px;
    }

    &__vendor-logo {
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        border-radius: 50%;
    }
}
</style>
