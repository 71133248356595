<template lang="pug">
    .coffees
        LandingHeader

        prepare
            template(slot-scope='props')
                .coffees__content
                    get-the-app
                    .splash.splash--inline(v-if='!Object.keys(vouchers).length')
                        .splash__content
                            i.fas.fa-coffee
                            h3 You don't have any coffees yet!
                            p Hopefully someone will send you one soon!
                    template(v-else)
                        my-coffees-item(v-for='voucher in vouchers' :voucher='voucher' :key='voucher.id')
</template>

<script>
import Q from 'q'
import { mapActions, mapGetters } from 'vuex'

import MyCoffeesItem from './my-coffees-item'

export default {
    components: { MyCoffeesItem },

    computed: {
        ...mapGetters(['vouchers']),
    },

    methods: {
        prepare() {
            return Q.all([
                this.loadGifts(),
                this.loadEmojisIfNeeded({ url: '/api/emojis/' }),
            ])
        },

        ...mapActions(['loadGifts', 'loadEmojisIfNeeded']),
    },
}
</script>

<style lang="scss" scoped>
.coffees {
    min-height: 100vh;
    background: #eaeaea;

    &__content {
        padding: 20px;
    }
}
</style>
