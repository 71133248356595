<template lang="pug">
    div
        template(v-if='isSessionUpgraded')
            navbar
                router-link(to='/admin/dashboard') Dashboard
                router-link(to='/admin/companies') Companies
                router-link(to='/admin/presets') Presets
                router-link(to='/admin/emoji') Emoji
                router-link(to='/admin/backgrounds') Backgrounds
                router-link(to='/admin/vendors') Vendors
            prepare(:method='prepare')
                router-view
        upgrade-session(v-else)
</template>

<script>
import Q from 'q'
import { mapActions, mapGetters } from 'vuex'

import UpgradeSession from 'accounts/components/upgrade-session'
import accountsStore from 'accounts/store'
import Navbar from 'core/components/internal-header'

export default {
    components: { Navbar, UpgradeSession },

    computed: {
        ...mapGetters(['hasCompany', 'isSessionUpgraded']),
    },

    methods: {
        prepare() {
            return Q.all([
                this.loadEmojis(),
                this.loadBackgrounds(),
            ])
        },

        ...mapActions(['loadEmojis', 'loadBackgrounds']),
    },

    beforeRouteEnter(to, from, next) {
        if (!accountsStore.state.user.is_admin) {
            next('/')
        } else {
            next()
        }
    },

    mounted() {
        document.body.className = 'corporate'
    },

    beforeDestroy() {
        document.body.className = 'website'
    },
}
</script>
