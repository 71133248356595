<template lang="pug">
    div
        modal-header Issue Credit

        v-form(:context='this')
            .modal-body
                .company
                    img(:src='company.logo' v-if='company.logo')
                    p {{ company.name }}

                v-field(
                    :model='model.amount'
                    label='Amount'
                    field='input'
                    placeholder='100.00'
                    help="How much credit would you like to add?"
                    focus
                )

            .modal-footer
                b-btn(@click='$vuedals.close(this)') Cancel
                b-btn(variant='primary' type='submit') Submit
</template>

<script>
import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    mixins: [ModalFormMixin],

    props: ['company'],

    fields: ['amount'],

    methods: {
        getEndpoint() {
            return `/api/admin/companies/${this.company.id}/add_credit/`
        },

        clean(data) {
            data.company = this.company.id
            data.amount = Math.floor(data.amount * 100)
            return data
        },

        onSubmitSuccess(data) {
            this.company.balance = data.new_balance
            ModalFormMixin.methods.onSubmitSuccess.call(this, data)
        },
    },
}
</script>

<style lang="scss" scoped>
.company {
    text-align: center;

    img {
        width: 75px;
        border-radius: 50%;
    }

    p {
        font-size: 0.8rem;
        font-weight: 500;
    }
}
</style>
