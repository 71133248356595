<template lang="pug">
    router-link.campaign-row(tag='tr' :to='`/corporate/campaigns/${campaign.id}`')
        td
            campaign-status(:campaign='campaign')
            button.btn.btn-primary.btn-sm.mt-2(@click='copyLink')
                i.fa.fa-share
                |  Share
        td
            gift-header.campaign-row__header(:gift='campaign' :maxEmojiSize='32' style='height: 85px')
        td {{ campaign.internal_label }}
        td {{ campaign.sender_name }}
        td
            .campaign-row__progress
                .progress.mt-2
                    .progress-bar.bg-primary(
                        role='progressbar'
                        :style='`width: ${campaign.progress}%`'
                        :aria-valuenow='campaign.progress'
                        aria-valuemin='0'
                        aria-valuemax='100'
                    )
                small.text-muted {{ campaign.progress }}%
                .campaign-row__progress-detail.text-muted {{ campaign.numClaimed.toLocaleString() }} / {{ campaign.numTotal.toLocaleString() }}
</template>

<script>
import { mapGetters } from 'vuex'

import CampaignStatus from './campaign-status'
import CopyCampaignLink from '../campaign-viewer/copy-campaign-link'

export default {
    props: ['id'],

    components: { CampaignStatus },

    computed: {
        campaign() {
            return this.annotatedCampaign(this.id)
        },

        ...mapGetters(['annotatedCampaign']),
    },

    methods: {
        copyLink(e) {
            e.stopImmediatePropagation()
            this.$present(CopyCampaignLink, { campaign: this.campaign })
        },
    },
}
</script>

<style lang="scss" scoped>
.campaign-row {
    &:hover {
        cursor: pointer;
        background: #f4f4f4;
    }

    > td {
        vertical-align: middle;
    }

    &__header {
        border-radius: 12px;
        overflow: hidden;
    }

    &__progress {
        display: flex;
        position: relative;

        .progress {
            flex: 1;
        }

        small {
            margin-left: 10px;
            padding-top: 6px;
        }
    }

    &__progress-detail {
        position: absolute;
        top: 30px;
        right: 0;
        font-size: 0.8rem;
    }
}
</style>
