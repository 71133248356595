<template lang="pug">
    fixed-scroll(:distance='3000' @scroll='handleScroll')
        section.section--tall.hiw
            .container
                .row
                    .col-md-6.order-md-2
                        h1 How it Works
                        .steps
                            .step(:class='{"step--active": step == 1}')
                                .step__number: span 1
                                .step__label Log in with your phone number
                            .step(:class='{"step--active": step == 2}')
                                .step__number: span 2
                                .step__label See what your friends are sharing
                            .step(:class='{"step--active": step == 3}')
                                .step__number: span 3
                                .step__label Send Random Acts of Coffee
                            .step(:class='{"step--active": step == 4}')
                                .step__number: span 4
                                .step__label Redeem your coffees at your favorite shop

                    .col-md-6
                        .hiw__phone
                            transition(name='step-fade')
                                phone(v-if='step == 1' :key='1')
                                    img(alt='Welcome to Nack' src='~landing/images/log-in.jpg')
                                phone(v-if='step == 2' :key='2')
                                    img(alt='Nack News Feed' src='~landing/images/news-feed.jpg')
                                phone(v-if='step == 3' :key='3')
                                    img(alt='Someone sent a coffee' src='~landing/images/send-rac.jpg')
                                phone(v-if='step == 4' :key='4')
                                    img(alt='Pay for coffees at local shops' src='~landing/images/redeem.jpg')
</template>

<script>
export default {
    data() {
        return {
            progress: 0,
        }
    },

    computed: {
        step() {
            const p = this.progress
            if (p < 0.25) return 1
            if (p < 0.5) return 2
            if (p < 0.75) return 3
            return 4
        },
    },

    methods: {
        handleScroll(progress) {
            this.progress = progress
        },
    },
}
</script>

<style lang="scss" scoped>
.hiw {
    h1 {
        color: $nack-dark;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    .steps {
        margin-top: 40px;
    }

    .step {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        &__number {
            @include flex-align-center;
            display: inline-flex;
            width: 42px;
            height: 42px;
            flex: 0 0 auto;
            background-color: #dbdbdb;
            border-radius: 50%;
            transition: background-color 0.25s;

            span {
                color: white;
                font-weight: 800;
                font-size: 1.4rem;
            }
        }

        &__label {
            flex: 1;
            color: #c1c1c1;
            margin-left: 40px;
            transition: color 0.25s ease-out, font-size 0.25s ease-out;
            font-size: 1.1rem;
            font-weight: 500;

            @include media-breakpoint-down(sm) {
                margin-left: 10px;
            }
        }

        &--active {
            @include media-breakpoint-down(sm) {
                display: flex;
            }

            .step__number {
                background-color: $nack-primary;
            }

            .step__label {
                color: black;
                font-size: 1.3rem;
            }
        }
    }

    &__phone {
        position: relative;
        width: 250px;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            top: -70px;
        }

        > .phone {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
</style>

<style lang="scss">
.step-fade-enter-active {
    animation-name: step-fade-in;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

.step-fade-leave-active {
    animation-name: step-fade-out;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

@keyframes step-fade-in {
    from { opacity: 0 }
    to { opacity: 1  }
}

@keyframes step-fade-out {
    from { opacity: 1  }
    to { opacity: 0 }
}

</style>
