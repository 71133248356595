import { combineStores, crudStore } from 'core/util/vuex'

const adminStore = combineStores([
    crudStore('companies', '/api/admin/companies/'),

    crudStore('backgrounds', '/api/admin/backgrounds/'),
    crudStore('emojis', '/api/admin/emojis/'),

    crudStore('presetCategories', '/api/admin/preset_categories/', 'presetCategory'),
    crudStore('presets', '/api/admin/presets/', null, null, 'category'),

    crudStore('vendors', '/api/admin/vendors/'),
    crudStore('vendorLocations', '/api/admin/vendor_locations/'),
])

export default adminStore
