<template lang="pug">
    div(v-if='ready')
        slot
    error(v-else-if='error' :title='error' message='')
    .prepare(v-else :class='loadingClass')
        spinner
</template>

<script>
export default {
    props: ['method', 'loadingClass'],

    data() {
        return {
            ready: false,
            error: false,
        }
    },

    methods: {
        prepare() {
            this.ready = false
            this.error = false
            const promise = this.method ? this.method() : this.$parent.prepare()
            if (promise === true) {
                this.ready = true
            } else {
                promise.then(() => {
                    this.ready = true
                }).catch((e) => {
                    this.error = this.$drf.parseError(e).translated.overallError
                })
            }
        },
    },

    created() {
        this.prepare()
    },
}
</script>

<style lang="scss" scoped>
.prepare {
    @include flex-align-center;
    @include cover-parent;
}
</style>
