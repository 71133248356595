<template lang="pug">
    b-form-group(
        :horizontal='horizontal'
        :label='label'
        :label-cols='labelCols'
        :description='hint && !model.error ? hint : null'
    )
        multiselect(
            v-if='field && field == "select"'
            :value='options.find(e => e.id == model.value)'
            @input='model.value = $event.id'
            track-by='id'
            label='name'
            :options='options'
            :show-labels='false' v-bind='$attrs'
        )
            span(slot="noResult") Oops! Nothing matches that.
            slot
        v-slot(v-else-if='field' :class='cls' :model='model' :field='field' :options='options' v-bind='$attrs')
            slot
        slot(v-else)
        b-form-invalid-feedback(force-show v-if='model.error') {{ model.error }}
</template>

<script>
export default {
    props: ['model', 'field', 'label', 'horizontal', 'labelCols', 'hint', 'cls', 'options'],
}
</script>
