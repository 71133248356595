<template lang="pug">
    img(
        v-if='image'
        :src='image'
        :style='style'
    )
    .initials(
        v-else
        :style='style'
    ): span(
        :style='{"font-size": `${size / 2.5}px`}'
    ) {{ initials }}
</template>

<script>
export default {
    props: {
        sender: {},
        user: {},
        campaign: {},

        size: {
            type: Number,
            default: 60,
        },

        noShowProfile: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        image() {
            if (this.sender) return this.sender.photo
            if (this.user) return this.user.photo
            if (this.campaign) return this.campaign.sender_photo
            return null
        },

        name() {
            if (this.sender) return this.sender.name
            if (this.user) return `${this.user.first_name} ${this.user.last_name}`
            if (this.campaign) return this.campaign.sender_name
            return ''
        },

        initials() {
            return this.name.split(' ').map(p => p.substr(0, 1)).join('').substr(0, 2)
        },

        style() {
            return {
                width: `${this.size}px`,
                height: `${this.size}px`,
                borderRadius: `${this.size / 2}px`,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
img {
    background-color: white;
    border-width: 2;
    border-color: white;
}

.initials {
    display: inline-flex;
    background-color: $nack-primary;
    color: white;
    border-width: 2;
    border-color: white;
    align-items: center;
    justify-content: center;
}
</style>
