<template lang="pug">
    prepare
        template(slot-scope='props')
            .internal-page.campaign
                .container
                    .row
                        .col-md-9
                            h3.campaign__title
                                span.d-inline-block {{ campaign.internal_label }}
                                campaign-status.d-inline-block.ml-3(:campaign='campaign' tag='h4')
                                .campaign__actions(v-if='!loading')
                                    button.btn.btn-primary(v-if='!campaign.enabled' @click='enable') {{ campaign.numClaimed ? "Resume" : "Start" }}
                                    button.btn.btn-warning(v-else @click='disable') Pause
                                    button.btn.btn-primary(@click='copyLink')
                                        i.fa.fa-share
                                        |  Share
                                    router-link.btn.btn-secondary(:to='`/corporate/edit-campaign/${campaign.id}/`') Edit
                                spinner.float-right(v-else)

                            .text-muted {{ campaign.link }}

                            .campaign__progress.mt-2
                                .progress
                                    .progress-bar.bg-primary(
                                        role='progressbar'
                                        :style='`width: ${campaign.progress}%`'
                                        :aria-valuenow='campaign.progress'
                                        aria-valuemin='0'
                                        aria-valuemax='100'
                                    )
                                span.text-muted {{ campaign.progress }}%
                            .campaign-row__progress-detail.text-muted {{ campaign.numClaimed.toLocaleString() }} / {{ campaign.numTotal.toLocaleString() }} {{ campaign.numTotal == 1 ? "gift" : "gifts" }} claimed
                            .campaign-row__progress-detail.text-muted {{ views.toLocaleString() }} {{ views == 1 ? "view" : "views" }}

                            .splash.splash--inline(v-if='campaign.enabled || campaign.numClaimed > 0')
                                .splash__content(v-if='campaign.numClaimed === 0 && views === 0')
                                    i.fas.fa-chart-bar
                                    h3 Check back once you've sent out your campaign!

                                .campaign__dashboard(v-else)
                                    .card.card__body.card__body--lg
                                        h4.card__title Campaign Activity
                                        ClaimsOverTime(:claims='dashboard.claims_over_time' :height='300' :campaign='campaign')

                                    .card.card__body.card__body--lg.mt-4(v-if='campaign.numClaimed > 0')
                                        h4.card__title Gift Recipients
                                        Chains(:chains='dashboard.chains')

                            .splash.splash--inline(v-else)
                                .splash__content
                                    i.fal.fa-pause-circle
                                    h5 Your campaign is ready to roll
                                    h3 Click Start to launch it

                        .col-md-3
                            campaign-preview(:model='campaign')
</template>

<script>
import axios from 'axios'
import Q from 'q'
import { mapActions, mapGetters } from 'vuex'

import AddFunds from '../add-funds'
import CampaignPreview from '../campaign-editor/campaign-preview'
import CampaignStatus from '../dashboard/campaign-status'
import ClaimsOverTime from './claims-over-time'
import CopyCampaignLink from './copy-campaign-link'
import Chains from './chains'

export default {
    components: {
        CampaignPreview, CampaignStatus, ClaimsOverTime, Chains,
    },

    data() {
        return {
            campaign: null,
            loading: false,
            dashboard: {},
        }
    },

    computed: {
        views() {
            let views = 0
            for (const point of this.dashboard.claims_over_time) {
                if (point.views) views += point.views
            }
            return views
        },

        ...mapGetters(['annotatedCampaign']),
    },

    methods: {
        prepare() {
            return Q.all([
                this.loadCampaign(this.$route.params.id).then(() => {
                    this.getCampaignData()
                }),
                axios.get(`/api/corporate/campaigns/${this.$route.params.id}/dashboard/`).then((response) => {
                    this.dashboard = response.data
                }),
            ])
        },

        getCampaignData() {
            this.campaign = this.annotatedCampaign(this.$route.params.id)
        },

        enable() {
            this.loading = true
            this.enableCampaign(this.campaign.id).then(() => {
                this.loading = false
                this.getCampaignData()
                this.$ga.event('campaign-viewer', 'enable', this.campaign.id)
            }, (error) => {
                this.loading = false
                const requiredFunds = error.response.data.INSUFFICIENT
                if (requiredFunds) {
                    this.$present(AddFunds, {
                        requiredFunds,
                        callback: () => {
                            this.enable()
                        },
                    })
                    this.$ga.event('campaign-viewer', 'prompt-add-funds', `${requiredFunds / 100}`)
                }
            })
        },

        disable() {
            this.loading = true
            this.disableCampaign(this.campaign.id).then(() => {
                this.loading = false
                this.getCampaignData()
                this.$ga.event('campaign-viewer', 'disable', this.campaign.id)
            }, () => {
                this.loading = false
            })
        },

        copyLink() {
            this.$present(CopyCampaignLink, { campaign: this.campaign })
        },

        ...mapActions(['loadCampaign', 'enableCampaign', 'disableCampaign']),
    },
}
</script>

<style lang="scss" scoped>
.campaign {
    &__title, &__subtitle {
        color: #787878;
    }

    &__progress {
        display: flex;

        span {
            margin-left: 10px;
            position: relative;
            top: -4px;
        }
    }

    &__dashboard {
        width: 100%;
    }

    &__actions {
        float: right;

        > button, a {
            float: right;
            margin-left: 20px;
        }

        @include media-breakpoint-down(sm) {
            float: none;
            margin: 20px 0;

            > button, a {
                float: none;
                margin-left: 0;
                margin-right: 20px;
            }
        }
    }
}

.progress {
    flex: 1;
    background-color: #ccc;
    max-width: 250px;
}
</style>
