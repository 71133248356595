<template lang="pug">
    v-form(:context='this' v-if='!submitted')
        h5 Please enter the code we sent you.
        v-field(
            :model='model.code'
            field='input'
            placeholder='Code'
            type='tel'
        )
        b-btn.btn-block.mt-2(variant='primary' type='submit') Continue
</template>

<script>
import { FormMixin } from 'core/components/forms/mixin'

export default {
    mixins: [FormMixin],

    props: ['phoneNumber'],

    fields: ['code'],

    dispatch: 'finishLogin',

    methods: {
        clean(data) {
            data.phone_number = this.phoneNumber
            data.session = true
            return data
        },

        onSubmitSuccess() {
            this.$router.push(this.$route.query.redirect || '/')
        },
    },
}
</script>
