<template lang="pug">
    section.section--tall.getit
        .container
            .row
                .col-md-6
                    .text-center
                        img.getit__logo(alt='Nack on the App Store' src='~landing/images/app-icon.png')

                        h1 Get the App

                    p.
                        Kindness is contagious, and coffee is universal. Start sharing cups of coffee today,
                        and effortlessly connect with the people in your life.

                    .getit__stores
                        a(href='https://itunes.apple.com/us/app/nack/id1439154311?mt=8' target='_blank')
                            img.getit__store(alt='Download on the App Store' src='~landing/images/app-store.svg')

                        a(
                            href='https://play.google.com/store/apps/details?id=com.getnack.barista&hl=en_US'
                            target='_blank'
                        )
                            img(alt='Get it on Google Play' src='~landing/images/google-play-badge.png')

                .col-md-6
                    .getit__phone
                        phone
                            img(alt='Nack News Feed' src='~landing/images/news-feed.jpg')
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.getit {
    color: white;
    background: $nack-dark;

    &__logo {
        width: 40%;
    }

    h1 {
        margin: 40px 0;
    }

    &__phone {
        width: 50%;
        margin: 0 auto;

        @include media-breakpoint-only(md) {
            width: 75%;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 40px;
        }
    }

    &__stores {
        margin-top: 40px;

        a {
            @include shadow;
            display: inline-block;
            width: 40%;

            img {
                width: 100%;
            }

            &:first-child {
                width: 36%;
                margin-right: 20%;
                border-radius: 14px;
            }

            &:last-child {
                border-radius: 10px;
            }
        }
    }
}
</style>
