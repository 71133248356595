<template lang="pug">
    component.mb-0(:is='tag')
        .badge(:class='`badge-${status.color}`') {{ status.name }}
</template>

<script>
const statuses = {
    pending: { name: 'Pending', color: 'secondary' },
    paused: { name: 'Paused', color: 'secondary' },
    active: { name: 'Active', color: 'primary' },
    finished: { name: 'Finished', color: 'success' },
}

export default {
    props: {
        campaign: {},
        tag: {
            default: 'h5',
        },
    },

    computed: {
        status() {
            return statuses[this.campaign.status]
        },
    },
}
</script>
