<script>
import _ from 'lodash'
import moment from 'moment'
import { Line } from 'vue-chartjs'

export default {
    extends: Line,

    props: ['claims', 'campaign'],

    mounted() {
        // Determine the period of the chart
        const start = moment(this.campaign.created).minutes(0).seconds(0).milliseconds(0)
        let end = moment().minutes(0).seconds(0).milliseconds(0)
        if (!this.campaign.enabled || this.campaign.quantity_remaining === 0) end = moment(this.claims[this.claims.length - 1].hour * 1000)
        if (start.unix() === end.unix()) start.subtract(1, 'hour')

        // Create the data, filling in empty spaces as necessary
        const claimsData = []
        const viewsData = []
        const claims = _.keyBy(this.claims, 'hour')
        const current = start
        while (current <= end) {
            claimsData.push({
                x: current.unix() * 1000,
                y: claims[current.unix()] ? (claims[current.unix()].claims || 0) : 0,
            })
            viewsData.push({
                x: current.unix() * 1000,
                y: claims[current.unix()] ? (claims[current.unix()].views || 0) : 0,
            })
            current.add(1, 'hour')
        }

        this.renderChart({
            datasets: [{
                label: 'Gifts Claimed',
                data: claimsData,
                fill: false,
                borderColor: '#043c5b',
            }, {
                label: 'Gifts Viewed',
                data: viewsData,
                fill: false,
                borderColor: '#019bf4',
            }],
        }, {
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        displayFormats: {
                            millisecond: 'M/D hA',
                            second: 'M/D hA',
                            minute: 'M/D hA',
                            hour: 'M/D hA',
                            day: 'M/D hA',
                            week: 'M/D hA',
                            month: 'M/D hA',
                            quarter: 'M/D hA',
                            year: 'M/D hA',
                        },
                    },
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'Number of Gifts',
                    },
                    ticks: {
                        beginAtZero: true,
                        callback: value => (value % 1 === 0 ? value : null),
                    },
                }],
            },
            legend: {
                display: false,
            },
            responsive: true,
            maintainAspectRatio: false,
        })
    },
}
</script>
