<template lang="pug">
    prepare
        template(slot-scope='props')
            .internal-page
                .container
                    .card.card__body.card__body--lg
                        h4.card__title.text-left Companies

                        table.table.table-responsive-lg.table--card
                            thead
                                th Logo
                                th Name
                                th Balance
                                th Sign-up Date
                                th
                            tbody
                                tr.company(
                                    v-for='company in sortedCompanies'
                                    :key='company.id'
                                )
                                    td: img(:src='company.logo')
                                    td {{ company.name }}
                                    td ${{ (company.balance / 100).toLocaleString() }}
                                    td {{ company.created | timeFromNow }}
                                    td
                                        a.btn.btn-primary.mr-2(
                                            href='#'
                                            @click.prevent='promptCredit(company)'
                                            v-b-tooltip title='Issue Credit'
                                            v-if='!assisting'
                                        ): i.fas.fa-hand-holding-usd
                                        a.btn.btn-primary(
                                            href='#'
                                            @click.prevent='assist(company.id)'
                                            v-b-tooltip title='Access Campaign Tools'
                                            v-if='!assisting'
                                        ): i.fas.fa-user-check
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

import CreditForm from './credit-form'

export default {
    data() {
        return {
            assisting: false,
        }
    },

    computed: {
        sortedCompanies() {
            return _.sortBy(this.companies, c => -moment(c.created).unix())
        },

        ...mapState({
            companies: state => state.admin.companies,
        }),
    },

    methods: {
        prepare() {
            return this.loadCompanies()
        },

        assist(companyId) {
            this.assisting = true
            axios.post(`/api/admin/companies/${companyId}/assist/`).then(() => {
                window.location = '/corporate'
            }, () => {
                this.assisting = false
            })
        },

        promptCredit(company) {
            this.$present(CreditForm, { company })
        },

        ...mapActions(['loadCompanies']),
    },
}
</script>

<style lang="scss" scoped>
.company {
    img {
        width: 75px;
        border-radius: 50%;
    }

    td {
        vertical-align: middle;
    }
}
</style>
