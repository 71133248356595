import Vue from 'vue'
import Router from 'vue-router'
import VueScrollTo from 'vue-scrollto'

import store from 'core/store'

import GiftLinkPage from 'gifts/components/gift-link-page'
import MyCoffeesPage from 'gifts/components/my-coffees-page'
import PayPage from 'gifts/components/pay-page'
import LoginPage from 'accounts/components/login-page'

import Home from 'landing/components/home/home'
import Terms from 'landing/components/terms'
import Privacy from 'landing/components/privacy'

import CorporateController from 'corporate/components/controller'
import CorporateDashboard from 'corporate/components/dashboard/dashboard-page'
import CorporateCampaignEditor from 'corporate/components/campaign-editor/editor-page'
import CorporateCampaignViewer from 'corporate/components/campaign-viewer/campaign-page'

import AdminController from 'admin/components/controller'
import AdminDashboard from 'admin/components/dashboard/dashboard-page'
import AdminCompanies from 'admin/components/companies/companies-page'
import AdminPresets from 'admin/components/presets/presets-page'
import AdminEmoji from 'admin/components/emoji/emoji-page'
import AdminBackgrounds from 'admin/components/backgrounds/backgrounds-page'
import AdminVendors from 'admin/components/vendors/vendors-page'
import AdminVendor from 'admin/components/vendors/vendor-page'

Vue.use(Router)

const router = new Router({
    mode: 'history',

    base: '/',

    routes: [
        { path: '/g/:giftHash', component: GiftLinkPage },
        { path: '/b/:bundleHash', component: GiftLinkPage },
        { path: '/c/:code', component: GiftLinkPage },
        { path: '/my-coffees', component: MyCoffeesPage, meta: { requireLogin: true } },
        { path: '/pay/:voucher', component: PayPage, meta: { requireLogin: true } },

        { path: '/login', component: LoginPage },

        {
            path: '/corporate',
            component: CorporateController,
            meta: { requireLogin: true },
            children: [
                { path: '/', redirect: '/corporate/dashboard' },
                { path: 'dashboard', component: CorporateDashboard },
                { path: 'campaigns/:id', component: CorporateCampaignViewer },
                { path: 'edit-campaign', component: CorporateCampaignEditor },
                { path: 'edit-campaign/:id', component: CorporateCampaignEditor },
            ],
        },

        {
            path: '/admin',
            component: AdminController,
            meta: { requireLogin: true },
            children: [
                { path: '/', redirect: '/admin/dashboard' },
                { path: 'dashboard', component: AdminDashboard },
                { path: 'companies', component: AdminCompanies },
                { path: 'presets', component: AdminPresets },
                { path: 'backgrounds', component: AdminBackgrounds },
                { path: 'emoji', component: AdminEmoji },
                { path: 'vendors', component: AdminVendors },
                { path: 'vendors/:id', component: AdminVendor },
            ],
        },

        { path: '/terms', component: Terms },
        { path: '/privacy', component: Privacy },
        { path: '/', component: Home },
    ],
})

router.beforeEach((to, from, next) => {
    // Gets the meta value by checking the entire tree
    const metaValue = (key) => {
        let val
        for (const m of to.matched) {
            if (typeof m.meta[key] !== 'undefined') val = m.meta[key]
        }
        return val
    }

    // Redirect to login when required and not authenticated
    if (metaValue('requireLogin') && !store.getters.isAuthenticated) {
        if (to.path.substr(0, 6) !== '/login') {
            return next({ path: '/login', query: { redirect: to.path } })
        }
        return next()
    }

    // Continue routing
    return next()
})

router.afterEach(() => {
    window.scrollTo(0, 0)
    if (window.location.hash) {
        setTimeout(() => {
            VueScrollTo.scrollTo(window.location.hash)
        }, 100)
    }
    window.Intercom('update')
})

export default router
