<template lang="pug">
    core-header(:shadow='shadow')
        b-navbar-nav.ml-auto
            router-link(to='/#what' v-scroll-to='"#what"') What's Nack
            router-link(to='/#how' v-scroll-to='"#how"') How it Works
            router-link(to='/#contact' v-scroll-to='"#contact"') Contact
            router-link(to='/my-coffees') My Coffees
            router-link.corp(to='/corporate') Campaign Tools
        router-link.btn.btn--accent.btn--pill(to='/#get' v-scroll-to='"#get"') Get the app
</template>

<script>
export default {
    props: {
        shadow: {
            default: '#0982cd',
        },
    },
}
</script>
