<template lang="pug">
    v-form(:context='this')
        modal-header {{ mode }} Vendor

        .modal-body
            .text-center
                v-field(
                    :model='model.logo'
                    label='Logo'
                    hint='Logos must be 512 x 512 and in PNG format.'
                )
                    label.mb-0(for='vendor-logo')
                        a.logo-preview.logo-preview--placeholder(v-if='!logoImg'): i.fa.fa-file-upload
                        img.logo-preview(v-else :src='logoImg' alt='Logo Image')
                    input(
                        id='vendor-logo'
                        type='file'
                        @change='logoSelection = $event.target.files[0]; $event.target.value = null'
                    )

            .row
                .col-md-9
                    v-field(
                        :model='model.name'
                        field='input'
                        label='Name'
                        placeholder='Starbucks'
                    )

                .col-md-3
                    v-field(
                        :model='model.color'
                        field='input'
                        label='Brand Color'
                        placeholder='#123abc'
                    )

            .row
                .col-md-6
                    v-field(
                        :model='model.scale'
                        field='select'
                        label='Scale'
                        :options='scaleOptions'
                    )

                .col-md-6
                    v-field(
                        :model='model.code_type'
                        field='select'
                        label='Code Type'
                        :options='codeOptions'
                    )

            v-field(
                :model='model.active'
                hint='Determines if the vendor should show up in the app.<br>Note: The vendor will always be hidden if they do not have any gift cards remaining.'
            )
                b-form-checkbox(v-model='model.active.value') Enabled

        .modal-footer
            b-btn.mt-2(variant='primary' type='submit') Save
</template>

<script>
import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    mixins: [ModalFormMixin],

    fields: [
        'logo',
        'name',
        'scale',
        'color',
        'code_type',
        { name: 'active', default: true },
    ],

    dispatch: 'saveVendor',

    data() {
        return {
            logoSelection: null,
            logo: null,
            logoImg: this.values ? this.values.logo : null,

            scaleOptions: [
                { id: 'national', name: 'National' },
                { id: 'regional', name: 'Regional' },
                { id: 'local', name: 'Local' },
            ],

            codeOptions: [
                { id: 'QR_CODE', name: 'QR Code (Dunkin Style)' },
                { id: 'PDF_417', name: 'PDF 417 (Starbucks Style)' },
                { id: 'TEXT', name: 'None (Text Only)' },
            ],
        }
    },

    methods: {
        clean(data) {
            delete data.logo
            if (this.logo) data.logo = this.logo
            return data
        },
    },

    watch: {
        logoSelection(val) {
            if (!val) return
            this.logo = val
            const fr = new FileReader()
            fr.onload = () => {
                this.logoImg = fr.result
            }
            fr.readAsDataURL(val)
        },
    },
}
</script>

<style lang="scss" scoped>
.logo-preview {
    display: block;
    width: 100px;
    height: 100px;
    border: 1px solid #eaeaea;
    border-radius: 50%;

    &--placeholder {
        @include flex-align-center;
        font-size: 3.0rem;

        i {
            color: #777;
        }
    }
}

#vendor-logo {
    display: none;
}
</style>
