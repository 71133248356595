<script>
import _ from 'lodash'
import moment from 'moment'
import { Line } from 'vue-chartjs'

export default {
    extends: Line,

    props: ['data', 'days'],

    computed: {
        datasets() {
            // Metrics
            const metrics = [
                { id: 'new_users', name: 'New Users', color: '#019bf4' },
                { id: 'paid_sent', name: 'Paid Consumer Coffees Sent', color: '#aaddaa' },
                { id: 'total_consumer_sent', name: 'Total Consumer Coffees Sent', color: '#71abf4' },
                { id: 'campaign_coffees_claimed', name: 'Campaign Coffees Claimed', color: '#043c5b' },
                { id: 'total_claimed', name: 'Total Coffees Claimed', color: '#ccccdd' },
            ]

            // Determine the period of the chart
            const start = moment().subtract(this.days, 'days').hours(0).minutes(0)
                .seconds(0)
                .milliseconds(0)
            const end = moment().hours(0).minutes(0).seconds(0)
                .milliseconds(0)

            // Create the data, filling in empty spaces as necessary
            const values = _.keyBy(this.data, 'date')
            return _.map(metrics, (m) => {
                const data = []
                const current = start.clone()
                while (current <= end) {
                    data.push({
                        x: current.unix() * 1000,
                        y: values[current.format('YYYY-MM-DD')] ? values[current.format('YYYY-MM-DD')][m.id] : 0,
                    })
                    current.add(1, 'day')
                }
                return {
                    data,
                    fill: false,
                    label: m.name,
                    backgroundColor: m.color,
                    borderColor: m.color,
                }
            })
        },
    },

    methods: {
        render() {
            this.renderChart({
                datasets: this.datasets,
            }, {
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            displayFormats: {
                                millisecond: 'M/D',
                                second: 'M/D',
                                minute: 'M/D',
                                hour: 'M/D',
                                day: 'M/D',
                                week: 'M/D',
                                month: 'M/D',
                                quarter: 'M/D',
                                year: 'M/D',
                            },
                        },
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: value => (value % 1 === 0 ? value : null),
                        },
                    }],
                },
                responsive: true,
                maintainAspectRatio: false,
            })
        },
    },

    mounted() {
        this.render()
    },

    watch: {
        data() {
            this.render()
        },
    },
}
</script>
