<template lang="pug">
    v-form(:context='this')
        modal-header(v-if='!corporate') {{ mode }} Background

        .modal-body
            Legend.col-form-label.pt-0.pb-3(v-if='corporate') Yeah, break the mold! Let's design your custom background.

            .text-center
                Legend.col-form-label.pt-0 Preview
                template(v-if='preview')
                    gift-background.preview-area(v-if='!corporate' :background='preview')
                    gift-header.preview-area(v-else :gift='header')
                .preview-area.preview-area--invalid(v-else)

            .text-center.mb-3
                b-form-radio-group(
                    v-model='kind'
                    :options='kinds'
                    buttons
                    button-variant='primary'
                )

            .editor-area
                v-field(:model='model.colors' v-if='kind == "color" || kind == "gradient"')
                    template(v-if='kind == "color"')
                        Legend.col-form-label Color
                        .color-input
                            b-form-input(
                                v-model='model.colors.value[0]'
                                placeholder='#019bf4'
                                autocomplete='off'
                                @focus='colorFocusIdx = null'
                            )
                            a.color-input__toggle(
                                href='#'
                                @click.prevent='colorFocusIdx = colorFocusIdx === null ? 0 : null'
                            ): i.fas.fa-palette
                            swatches(
                                v-if='colorFocusIdx === 0'
                                :value='model.colors.value[0]'
                                @input='$set(model.colors.value, 0, $event.hex.toLowerCase()), colorFocusIdx = null'
                            )
                    template(v-else)
                        template(v-for='color, idx in model.colors.value')
                            Legend.col-form-label Gradient Color {{ idx + 1 }} {{ idx > 1 ? "(Optional)" : "" }}
                            .color-input
                                b-form-input.mb-2(
                                    v-model='model.colors.value[idx]'
                                    placeholder='#019bf4'
                                    autocomplete='off'
                                    @focus='colorFocusIdx = null'
                                )
                                a.color-input__toggle(
                                    href='#'
                                    @click.prevent='colorFocusIdx = colorFocusIdx === null ? idx : null'
                                ): i.fas.fa-palette
                                swatches(
                                    v-if='colorFocusIdx === idx'
                                    :value='model.colors.value[idx]'
                                    @input='$set(model.colors.value, idx, $event.hex.toLowerCase()), colorFocusIdx = null'
                                )
                template(v-else)
                    v-field.d-inline-block(
                        :model='model.image'
                        label='Image'
                        hint='Must be a JPEG no larger than 1000 x 600 pixels.'
                    )
                        label.mb-0(for='image-input')
                            .btn.btn-primary(v-if='imageImg') Change Image
                            .btn.btn-primary(v-else) Select Image
                        input.d-none(
                            id='image-input'
                            type='file'
                            @change='imageSelection = $event.target.files[0]; $event.target.value = null'
                        )

                    v-field.d-inline-block(
                        v-if='!corporate'
                        :model='model.thumb'
                        label='Thumbnail'
                        hint='This will be displayed on the background selection screen.'
                    )
                        label.mb-0(for='thumb-input')
                            .btn.btn-primary(v-if='thumbImg') Change Thumbnail
                            .btn.btn-primary(v-else) Select Thumbnail
                        input.d-none(
                            id='thumb-input'
                            type='file'
                            @change='thumbSelection = $event.target.files[0]; $event.target.value = null'
                        )

                template(v-if='kind === "gradient"')
                    b-btn.btn-block.mt-2(variant='primary' type='button' @click='invertGradient') Invert Gradient
                    small.form-text.text-muted Reverses the order of the colors.

                v-field.mt-3(
                    v-if='kind == "gradient"'
                    :model='model.radial'
                    hint='Uses a radial gradient instead of a linear one.'
                )
                    b-form-checkbox(v-model='model.radial.value') Radial Gradient

                v-field.mt-3(
                    v-if='kind == "image"'
                    :model='model.tiled'
                    hint='Tiles the image to fill the available space.'
                )
                    b-form-checkbox(v-model='model.tiled.value') Tiled Background

                v-field.mt-3(
                    v-if='id'
                    :model='model.active'
                    hint='Determines if the background should show up in the app.'
                )
                    b-form-checkbox(v-model='model.active.value') Enabled

        .modal-footer(v-if='!corporate')
            b-btn.mt-2(variant='primary' type='submit') Save
        .modal-footer(v-else)
            b-btn.mt-2.mr-auto(variant='primary' type='button' @click='$emit("back")') Choose existing background
            b-btn.mt-2(variant='primary' type='button' @click='finishCorporate') Continue
</template>

<script>
import _ from 'lodash'
import { Swatches } from 'vue-color'

import { ModalFormMixin } from 'core/components/forms/mixin'
import ImageCropper from 'core/components/image-cropper'

export default {
    props: {
        corporate: {
            type: Boolean,
        },

        emoji: {
            type: Array,
        },
    },

    mixins: [ModalFormMixin],

    components: { Swatches },

    fields: [
        { name: 'colors', default: () => [''] },
        'radial',
        'image',
        'thumb',
        'tiled',
        { name: 'active', default: true },
    ],

    dispatch: 'saveBackground',

    data() {
        // Determine the kind
        let kind = 'color'
        if (this.values) {
            if (this.values.colors && this.values.colors.length > 1) kind = 'gradient'
            if (this.values.image) kind = 'image'
        }

        return {
            kind,
            colorFocusIdx: null,

            imageSelection: null,
            image: null,
            imageImg: this.values ? this.values.image : null,

            thumbSelection: null,
            thumb: null,
            thumbImg: this.values ? this.values.thumb : null,

            kinds: [
                { text: '<div class="px-2"><i class="fas fa-brush mr-1"></i> Solid Color</div>', value: 'color' },
                { text: '<div class="px-2"><i class="fas fa-palette mr-1"></i> Gradient</div>', value: 'gradient' },
                { text: '<div class="px-2"><i class="fas fa-image mr-1"></i> Image</div>', value: 'image' },
            ],
        }
    },

    computed: {
        preview() {
            const data = this.clean(this.fieldValues())
            const validated = this.validate(data)
            if (validated !== true && validated !== 'Please select a thumbnail.') return false
            if (this.imageImg) data.image = this.imageImg
            return data
        },

        header() {
            return {
                emoji: this.emoji,
                background: this.preview,
            }
        },
    },

    methods: {
        clean(data) {
            delete data.image
            delete data.thumb
            if (this.image) data.image = this.image
            if (this.thumb) data.thumb = this.thumb
            if (data.colors) data.colors = data.colors.filter(c => !!c)
            return data
        },

        validate(data) {
            if (this.kind === 'color' || this.kind === 'gradient') {
                for (const color of data.colors) {
                    if (!color.match(/^#[0-9a-fA-F]{6}$/)) return `${color} doesn't look like a hex color.`
                }

                if (this.kind === 'color' && data.colors.length !== 1) return 'Please choose a color.'
                if (this.kind === 'gradient' && data.colors.length < 2) return 'Gradients need at least two colors.'
            } else if (this.kind === 'image') {
                if (!this.imageImg) return 'Please select an image.'
                if (!this.thumbImg && !this.corporate) return 'Please select a thumbnail.'
            }
            return true
        },

        updateColors() {
            if ((this.kind !== 'gradient' && this.kind !== 'color')) return
            const newColors = this.model.colors.value.filter(c => !!c)
            if (this.kind === 'gradient' || (this.kind === 'color' && newColors.length === 0)) newColors.push('')
            if (!_.isEqual(newColors, this.model.colors.value)) this.model.colors.value = newColors
        },

        invertGradient() {
            this.model.colors.value = [...this.model.colors.value].reverse()
        },

        finishCorporate() {
            this.alert = null
            const data = this.clean(this.fieldValues())
            const validated = this.validate(data)
            if (validated !== true) {
                this.alert = validated
            } else {
                if (!data.image && this.kind === 'image') data.image = this.values.image
                this.$emit('finish', data)
            }
        },
    },

    watch: {
        imageSelection(val) {
            if (!val) return
            const fr = new FileReader()
            fr.onload = () => {
                this.image = fr.result
                this.imageImg = fr.result
            }
            fr.readAsDataURL(val)
        },

        thumbSelection(val) {
            if (!val) return
            this.$present(ImageCropper, {
                width: 300,
                height: 300,
                quality: 128 / 300,
                file: val,
                callback: (blob, img) => {
                    this.thumb = img
                    this.thumbImg = img
                },
            })
            this.thumbSelection = null
        },

        kind(val) {
            if (val === 'color' || val === 'gradient') {
                this.model.image.value = null
                this.model.tiled.value = null
                this.image = null
                this.imageImg = null

                if (!this.model.colors.value) this.model.colors.value = ['']
                if (val === 'color') {
                    this.model.radial.value = null
                    this.model.colors.value = this.model.colors.value.splice(0, 1)
                } else {
                    this.model.radial.value = false
                }
                this.updateColors()
            } else if (val === 'image') {
                this.model.colors.value = null
                this.model.radial.value = null
                this.model.tiled.value = false
            }
        },

        'model.colors.value': function colorsChanged() {
            this.updateColors()
        },
    },
}
</script>

<style lang="scss" scoped>
.preview-area {
    margin: 0 auto 20px auto;
    width: 250px;
    height: 150px;
    max-width: 100%;
    border-radius: 14px;
    @include shadow;

    &--invalid {
        background: #e5e5e5;
    }
}

.editor-area {
    margin: 0 auto;
    width: 250px;
    max-width: 100%;
}

.color-input {
    position: relative;
    display: flex;
    align-items: center;

    .form-control {
        flex: 1 1 auto;
    }

    .color-input__toggle {
        flex: 0 0 auto;
        color: #d3d3d3;
        font-size: 1.4rem;
        margin-left: 10px;

        &:hover {
            color: #767676;
        }
    }

    .vc-swatches {
        position: absolute;
        top: 100%;
        z-index: 1;
    }
}
</style>
