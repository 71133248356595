<template lang="pug">
    div
        gift-header.background-preview(
            v-if='value'
            :gift='header'
        )

        div
            a(
                v-for='b in backgroundsList'
                :key='b.id'
                href='#'
                @click.prevent='$emit("input", b.id)'
            )
                gift-background.background(:background='b' thumb)
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
    props: ['emoji', 'value'],

    computed: {
        backgroundsList() {
            if (this.backgrounds[0]?.active === undefined) {
                return _.sortBy(this.backgrounds, 'order')
            }
            return _.sortBy(_.filter(this.backgrounds, { active: true }), 'order')
        },

        header() {
            return {
                emoji: this.emoji,
                background: this.value,
            }
        },

        ...mapState({
            backgrounds: state => state.admin.backgrounds,
        }),
    },
}
</script>

<style lang="scss" scoped>
.background-preview {
    margin: 0 auto 20px auto;
    width: 250px;
    max-width: 100%;
    border-radius: 14px;
    @include shadow;
}

.background {
    width: 64px;
    height: 64px;
    border-radius: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
}
</style>
