import axios from 'axios'
import _ from 'lodash'

import { parseError } from 'core/util/drf'

export const FormMixin = {
    props: ['values', 'submitSuccessCallback'],

    data() {
        const values = this.values || {}
        const data = {
            alert: null,
            model: {},
            id: values.id,
            submitting: false,
            destroyed: true,
            submitted: false,
        }

        const fields = this.$options.fields.concat(['id'])
        _.each(fields, (f) => {
            let name
            let value = null
            if (typeof f === 'string') {
                name = f
            } else {
                ({ name } = f)
                value = typeof f.default === 'function' ? f.default.call(this) : f.default
            }

            if (typeof values[name] !== 'undefined') value = values[name]

            data.model[name] = { value: _.clone(value), error: null }
        })

        return data
    },

    computed: {
        mode() {
            return this.id ? 'Edit' : 'Add'
        },
    },

    methods: {
        submit() {
            if (this.submitting) return
            const data = this.clean(this.fieldValues())
            if (data === false) return
            this.clearErrors()
            this.sendToServer(data)
        },

        fieldValues() {
            const values = {}
            _.each(this.model, (value, key) => {
                values[key] = value.value
            })
            return values
        },

        clean(data) {
            return data
        },

        sendToServer(data) {
            this.submitting = true
            let request

            if (this.$options.endpoint) {
                request = axios.post(this.$options.endpoint, data)
            } else if (this.$options.dispatch) {
                request = this.$store.dispatch(this.$options.dispatch, { instance: data })
            } else if (this.getEndpoint) {
                request = axios.post(this.getEndpoint(), data)
            }

            request
                .then((response) => {
                    try {
                        this.handleSuccess(response)
                    } catch (e) {
                        console.error(e) // eslint-disable-line
                        throw e
                    }
                })
                .catch(error => this.handleError(error))
                .then(() => { this.submitting = false })
        },

        handleSuccess(response) {
            this.submitted = true
            if (this.$options.commit) this.commit(response.data, response)
            this.onSubmitSuccess(response.data, response)
        },

        commit(data, response) {
            this.$store.commit(this.$options.commit, data, response)
        },

        handleError(error, destroy = false) {
            const err = parseError(error)

            if (err.translated.overallError) this.setAlert(err.translated.overallError)
            _.each(err.translated.fieldErrors, (msg, field) => this.setFieldError(field, msg))

            if (!destroy) {
                this.onSubmitFailure(error.response.data, error.response, error, err)
            } else {
                this.onDestroyFailure(error.response.data, error.response, error, err)
            }
        },

        setFieldError(field, error) {
            if (Object.keys(this.model).indexOf(field) === -1) {
                console.warn(`Could not display error for ${field}: ${error}`) // eslint-disable-line
            } else {
                this.model[field].error = error
            }
        },

        setAlert(error) {
            this.alert = error
        },

        clearErrors() {
            _.each(this.model, (field) => { field.error = null })
            this.alert = null
        },

        destroy() {
            if (this.submitting) return
            this.clearErrors()
            this.sendDestroyToServer(this.fieldValues())
        },

        sendDestroyToServer(data) {
            this.submitting = true
            this.$store.dispatch(this.$options.dispatchDestroy, data)
                .then(response => this.handleDestroySuccess(data, response))
                .catch(error => this.handleError(error, true))
                .then(() => { this.submitting = false })
        },

        handleDestroySuccess(data, response) {
            this.destroyed = true
            this.submitted = true
            this.onDestroySuccess(data, response)
            this.$emit('destroy', data, response)
        },

        onSubmitSuccess(data, response) {
            if (this.submitSuccessCallback) this.submitSuccessCallback(data, response)
            this.$emit('submit', data, response)
        },

        onSubmitFailure(data, response, error) {}, // eslint-disable-line

        onDestroySuccess(data, response) {}, // eslint-disable-line

        onDestroyFailure(data, response) {}, // eslint-disable-line
    },
}

export const ModalFormMixin = {
    ...FormMixin,

    props: FormMixin.props.concat(['skipClose']),

    methods: {
        ...FormMixin.methods,

        onSubmitSuccess(...args) {
            if (!this.skipClose) this.$vuedals.close(this)
            FormMixin.methods.onSubmitSuccess.apply(this, args)
        },

        onDestroySuccess(...args) {
            if (!this.skipClose) this.$vuedals.close(this)
            FormMixin.methods.onSubmitSuccess.apply(this, args)
        },
    },
}

export default FormMixin
