<template lang="pug">
    b-form-group(
        :description='error ? null : description'
        :invalid-feedback='error'
        :state='!error'
        v-bind='$attrs'
    )
        slot
</template>

<script>
export default {
    props: ['description', 'error'],
}
</script>
