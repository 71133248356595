<template lang="pug">
    section.rcs(ref='el')
        .container
            .rcs__content
                img.rcs__shop(
                    alt='Coffee Shop'
                    src='~landing/images/coffee-shop.svg'
                    :class='{"rcs__shop--in": shop}'
                )
                h1 Are you a coffee shop?
                p.
                    Do you own a coffee shop or know someone who does?
                    If you’re looking to improve the customer experience and work directly with Nack,
                    contact us using this form.

                v-form(:context='this' v-if='!submitted')
                    .row
                        .col-md-7
                            v-field(
                                :model='model.name'
                                placeholder='Coffee Shop Name'
                                field='input'
                            )
                        .col-md-5
                            v-field(
                                :model='model.city'
                                placeholder='City + State'
                                field='input'
                            )

                    .rcs__cta
                        button.btn.btn--dark.btn--pill(type='submit') Start Conversation

                .text-center.py-5(v-else)
                    h2 We started a message for you.
                    h4 Be sure to send it!
</template>

<script>
import { FormMixin } from 'core/components/forms/mixin'

export default {
    mixins: [FormMixin],

    fields: ['name', 'city'],

    data() {
        return {
            shop: false,
        }
    },

    methods: {
        checkScroll() {
            const rect = this.$refs.el.getBoundingClientRect()
            this.shop = rect.top < window.innerHeight
        },

        clean(data) {
            this.clearErrors()
            if (!data.name) {
                this.model.name.error = 'Please fill this out.'
                return false
            }
            if (!data.city) {
                this.model.city.error = 'Please fill this out.'
                return false
            }
            return data
        },

        sendToServer(data) {
            window.Intercom('showNewMessage', `Hello, I'm from ${data.name} in ${data.city}. I'd like Nack to partner with my shop.`)
            this.submitted = true
            return true
        },
    },

    mounted() {
        this.checkScroll()
        window.addEventListener('scroll', this.checkScroll)
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.checkScroll)
    },
}
</script>

<style lang="scss" scoped>
.rcs {
    color: white;
    border-top: 18px solid white;
    background: $nack-primary;
    position: relative;
    overflow: hidden;

    &__content {
        @include media-breakpoint-up(lg) {
            width: 45%;
            margin-right: auto;
        }
    }

    &__shop {
        @include media-breakpoint-up(lg) {
            position: absolute;
            bottom: 0;
            right: -50%;
            width: 40%;
        }

        @include media-breakpoint-only(lg) {
            width: 45%;
        }

        @include media-breakpoint-only(md) {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 50%;
            margin-bottom: 40px;
        }

        @include media-breakpoint-down(sm) {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 75%;
            margin-bottom: 40px;
        }

        &--in {
            @include media-breakpoint-up(lg) {
                right: -1.75%;
                animation: shop-animation 0.75s ease-out;
            }
        }
    }

    &__cta {
        text-align: center;
        margin-top: 20px;
    }

    p {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@keyframes shop-animation {
    0%   { right: -50% }
    100% { right: -1.75% }
}
</style>
