<template lang="pug">
    div
        .card.editor
            .card__body
                h5.editor__title Let's choose the design of your campaign.
                p.editor__subtitle Who is your target audience?

                .multipicker.pt-3.pb-4
                    .multipicker__item(
                        v-for='opt in audienceOptions'
                        :key='opt.value'
                        @click='audience = opt.value'
                        :class='{"multipicker__item--active": audience == opt.value}'
                    )
                        .multipicker__icon: i(:class='opt.icon')
                        .multipicker__label {{ opt.name }}

                p.text-muted.text-center
                    |Select your audience to narrow down your options.
                    <br>
                    | You can also
                    |
                    a(href='#' @click.prevent='designCustom') design your own.

        .card.mt-3
            .card__body.card__body--lg
                .categories
                    .category(v-for='category in filteredCategories' :key='category.id')
                        h5.text-muted {{ category.name }}
                        .category__presets
                            a.category__preset(
                                v-for='preset in category.presets'
                                :key='preset.id'
                                href='#'
                                @click.prevent='choosePreset(preset)'
                            )
                                gift-header(
                                    :gift='preset'
                                    :maxEmojiSize='56'
                                )
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import HeaderDesigner from './header-designer'

export default {
    props: ['model'],

    data() {
        return {
            audience: null,
            audienceOptions: [
                { icon: 'fal fa-globe-americas', name: 'Everyone', value: null },
                { icon: 'fal fa-user', name: 'Customers', value: 'customers' },
                { icon: 'fal fa-id-badge', name: 'Employees', value: 'employees' },
                { icon: 'fal fa-tag', name: 'Marketing', value: 'marketing' },
            ],
        }
    },

    computed: {
        filteredCategories() {
            if (!this.audience) return this.presetCategories
            return _.filter(this.presetCategories, c => c.corporate_flags.indexOf(this.audience) > -1)
        },

        ...mapState({
            presetCategories: state => state.corporate.presetCategories,
            presets: state => state.corporate.presets,
        }),
    },

    methods: {
        choosePreset(preset) {
            this.model.emoji = preset.emoji
            this.model.background = preset.background
            if (!this.model.message) this.model.message = preset.suggested_message
            this.$emit('next')
        },

        designCustom() {
            this.$present(HeaderDesigner, {
                model: this.model,
                callback: (data) => {
                    this.model.emoji = data.emoji
                    this.model.background = data.background
                    this.model.custom_background = data.custom_background
                    this.$emit('next')
                },
            })
        },

        validate() {
            return !!this.model.emoji && (!!this.model.background || !!this.model.custom_background)
        },
    },
}
</script>

<style lang="scss" scoped>
.category {
    margin-top: 20px;

    &__presets {
        display: flex;
        flex-wrap: wrap;
    }

    &__preset {
        display: flex;
        width: 160px;
        height: 96px;
        margin-bottom: 20px;
        margin-right: 20px;
        border: 1px solid #eaeaea;
        border-radius: 12px;
        overflow: hidden;

        > div {
            width: 100%;
            height: 100%;
            min-height: auto;
        }
    }
}

#banner-image {
    display: none;
}

#banner-image-label {
    color: $nack-primary-l-3;

    &:hover {
        cursor: pointer;
    }
}
</style>
