<template lang="pug">
    prepare
        template(slot-scope='props')
            .internal-page
                .container
                    h3.card__title.text-left
                        | {{ vendor.name }}
                        a.text-muted.ml-3(href='#' @click.prevent='editVendor'): i.fa.fa-pencil

                    .row.mt-3
                        .col-md-4
                            .card.card__body.card__body--lg
                                h4.card__title.mb-3 Gift Cards

                                strong Available
                                span {{ vendor.cards_available }}

                                strong.mt-3 Used
                                span {{ vendor.cards_total - vendor.cards_available }}

                                strong.mt-3 Total
                                span {{ vendor.cards_total }}

                                button.btn.btn-primary.mt-3(@click='importCards')
                                    i.fa.fa-plus.mr-2
                                    | Import Cards
                        .col-md-8
                            .card.card__body.card__body--lg
                                h5.card__title Locations

                                table.table.table-responsive-lg.table--card
                                    thead
                                        th Name
                                        th Street Address
                                        th City
                                        th State
                                        th Zip Code
                                        th Coordinates
                                    tbody
                                        tr.location(
                                            v-for='location in locationList'
                                            :key='location.id'
                                            @click='editLocation(location)'
                                            :class='{"location--disabled": !location.active}'
                                        )
                                            td {{ location.name }}
                                            td {{ location.street_address }}
                                            td {{ location.city }}
                                            td {{ location.state }}
                                            td {{ location.zip_code }}
                                            td {{ getCoordinates(location.point) }}

                                .text-right
                                    button.btn.btn-primary(@click='editLocation')
                                        i.fa.fa-plus.mr-2
                                        | Add Location
</template>

<script>
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'

import VendorForm from './vendor-form'
import VendorLocationForm from './vendor-location-form'
import ImportGiftCardsForm from './import-gift-cards-form'

const coordinatesRegex = /\(([\d.-]+) ([\d.-]+)\)/

export default {
    data() {
        return {
            locations: {},
        }
    },

    computed: {
        locationList() {
            return _.sortBy(this.locations, 'street_address')
        },

        vendor() {
            return this.vendors[this.$route.params.id]
        },

        ...mapState({
            vendors: state => state.admin.vendors,
        }),
    },

    methods: {
        prepare() {
            return this.loadVendor(this.$route.params.id).then((response) => {
                this.locations = _.keyBy(response.data.locations, 'id')
            })
        },

        importCards() {
            this.$present(ImportGiftCardsForm, {
                vendor: this.vendor,
                submitSuccessCallback: () => {
                    this.prepare()
                },
            })
        },

        getCoordinates(point) {
            const match = point.match(coordinatesRegex)
            return `${match[2]}, ${match[1]}`
        },

        editVendor() {
            this.$present(VendorForm, {
                values: this.vendor,
            })
        },

        editLocation(location) {
            this.$present(VendorLocationForm, {
                values: location,
                vendor: this.vendor,
                submitSuccessCallback: (l) => {
                    this.$set(this.locations, l.id, l)
                },
            })
        },

        ...mapActions(['loadVendor']),
    },
}
</script>

<style lang="scss" scoped>
.location {
    &:hover {
        background: #f4f4f4;
        cursor: pointer;
    }

    &--disabled {
        opacity: 0.4;
    }
}
</style>
