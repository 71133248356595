<template lang="pug">
    div
        template(v-if='hasCompany && isSessionUpgraded')
            navbar
                router-link(to='/corporate/dashboard') Dashboard
            prepare(:method='prepare')
                router-view
        create-company-page(v-else-if='!hasCompany')
        upgrade-session(v-else)
</template>

<script>
import Q from 'q'
import { mapActions, mapGetters } from 'vuex'

import UpgradeSession from 'accounts/components/upgrade-session'
import Navbar from 'core/components/internal-header'
import CreateCompanyPage from './create-company-page'

export default {
    components: { CreateCompanyPage, Navbar, UpgradeSession },

    computed: {
        ...mapGetters(['hasCompany', 'isSessionUpgraded']),
    },

    methods: {
        prepare() {
            return Q.all([
                this.loadEmojisIfNeeded({ url: '/api/emojis/' }),
                this.loadBackgrounds({ url: '/api/corporate/backgrounds/' }),
            ])
        },

        ...mapActions(['loadEmojisIfNeeded', 'loadBackgrounds']),
    },

    mounted() {
        document.body.className = 'corporate'
    },

    beforeDestroy() {
        document.body.className = 'website'
    },
}
</script>
