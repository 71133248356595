<template lang="pug">
    .get-app
        img.get-app__logo(alt='Nack on the App Store' src='~landing/images/app-icon.png')
        div
            | Make your friend's day by surprising them with a coffee! Download the Nack app, it's quick and easy!
            a.btn.btn--accent(:href='appUrl' target='_blank') Get Nack
</template>

<script>
import { isIOS, isAndroid } from 'core/util/os'

export default {
    computed: {
        appUrl() {
            if (isIOS) {
                return 'https://itunes.apple.com/us/app/nack/id1439154311?mt=8'
            } else if (isAndroid) {
                return 'https://play.google.com/store/apps/details?id=com.getnack.barista&hl=en_US'
            }
            return 'https://getnack.com#get'
        },
    },
}
</script>

<style lang="scss" scoped>
.get-app {
    @include shadow;
    display: flex;
    background: $nack-primary;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 20px;

    img {
        width: 60px;
        height: 60px;
        flex: 0 0 60px;
        margin-top: 10px;
    }

    div {
        flex: 1;
        padding: 10px;
        color: white;
    }

    a {
        display: block;
        margin-top: 10px;
        max-width: 150px;
    }

    @media(min-width: 700px) {
        padding: 20px;
        align-items: center;

        img {
            margin: 0 10px;
        }

        a {
            margin-top: 10px;
        }
    }
}
</style>
