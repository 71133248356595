<template lang="pug">
    .emoji-picker
        .text-center(v-if='value.length')
            div: gift-emojis(:emoji='value')
            a.clear-emojis(href='#' @click.prevent='clear') Clear
        multiselect(
            v-model='selection'
            :options='filteredEmojiList'
            :internal-search='false'
            @search-change='processEntry'
            placeholder='Type or search...'
            :reset-after='true'
            ref='multiselect'
        )
            template(slot="singleLabel" slot-scope="props")
                .emoji-item
                    img(:src='props.option.svg' :key='props.option.svg')
                    span {{ props.option.name }}
            template(slot="option" slot-scope="props")
                .emoji-item
                    img(:src='props.option.svg' :key='props.option.svg')
                    span {{ props.option.name }}
</template>

<script>
import _ from 'lodash'
import { onlyEmoji } from 'emoji-aware'
import { mapState } from 'vuex'

export default {
    props: ['value'],

    data() {
        return {
            selection: null,
            filter: '',
        }
    },

    computed: {
        emojiList() {
            return _.sortBy(this.emojis, 'order')
        },

        filteredEmojiList() {
            if (!this.filter) return this.emojiList.filter(e => e.category === 'people').slice(0, 100)
            const filter = this.filter.toLowerCase()
            return _.filter(this.emojiList, (e) => {
                if (!e.active) return false
                for (const tag of e.tags) {
                    if (tag.indexOf(filter) !== -1) return true
                }
                return false
            }).slice(0, 100)
        },

        ...mapState({
            emojis: state => state.admin.emojis,
        }),
    },

    methods: {
        processEntry(entry) {
            // TODO: Check if the user entered actual emoji
            const detected = onlyEmoji(entry)
            if (detected.length) {
                // Find the IDs for the entered emojis
                const ids = []
                for (const enteredEmoji of detected) {
                    const code = Array.from(enteredEmoji)
                        .map(v => v.codePointAt(0).toString(16))
                        .filter(c => c !== '200d' && c !== 'fe0f')
                        .join('-')
                    const emoji = _.find(this.emojis, { code })
                    if (emoji) ids.push(emoji.id)
                }

                // Add the emojis
                this.addEmojis(ids)
                this.selection = -1
                setTimeout(() => { this.selection = null }, 100)
                this.$refs.multiselect.$refs.search.blur()
                return
            }

            // Looks like they didn't, let's filter the emoji
            this.filter = entry
        },

        addEmojis(ids) {
            this.$emit('input', this.value.concat(ids).slice(0, 10))
        },

        clear() {
            this.$emit('input', [])
        },
    },

    watch: {
        selection(val) {
            if (val === -1) return
            if (val && val !== -1) this.addEmojis([val.id])
            this.selection = null
        },
    },
}
</script>

<style lang="scss" scoped>
.emoji-item {
    img {
        width: 24px;
        height: 24px;
    }

    span {
        margin-left: 10px;
    }
}

.clear-emojis {
    display: inline-block;
    font-size: 0.8rem;
    color: #767676;
    margin: 5px 0 15px 0;
}
</style>
