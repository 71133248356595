<template lang="pug">
    .container.internal-page
        .card
            .card__body.card__body--lg
                h4 Emoji
                    b-btn.float-right(variant='primary' @click='editEmoji')
                        i.far.fa-plus
                        |  Add Emoji

                .emojis
                    a(
                        v-for='e in emojis'
                        href='#'
                        @click.prevent='editEmoji(e.id)'
                        :class='{"disabled": !e.active}'
                    )
                        img(:src='e.svg')
</template>

<script>
import { mapState } from 'vuex'

import EmojiForm from './emoji-form'

export default {
    computed: {
        ...mapState({
            emojis: state => state.admin.emojis,
        }),
    },

    methods: {
        editEmoji(id) {
            this.$present(EmojiForm, { values: this.emojis[id] })
        },
    },
}
</script>

<style lang="scss" scoped>
.emojis {
    a {
        margin-right: 10px;
        margin-bottom: 10px;

        &.disabled {
            opacity: 0.3;
        }

        img {
            width: 64px;
            height: 64px;
        }
    }
}
</style>
