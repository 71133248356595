<template lang="pug">
    .emojis.d-inline-block
        img.emoji(
            v-for='svg, idx in svgs'
            :key='svg + idx'
            :src='svg'
            :style='emojiStyle'
        )
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
    props: {
        emoji: {
            type: Array,
        },

        size: {
            type: Number,
            default: 64,
        },
    },

    computed: {
        svgs() {
            if (!this.emoji.length) return []
            if (typeof this.emoji[0] === 'number') {
                return _.map(this.emoji, id => this.allEmoji[id].svg)
            } else if (typeof this.emoji[0] === 'string') {
                return this.emoji
            }
            return _.map(this.emoji, e => e.svg)
        },

        emojiStyle() {
            return {
                width: `${this.size}px`,
                height: `${this.size}px`,
            }
        },

        ...mapState({
            allEmoji: state => state.admin.emojis,
        }),
    },
}
</script>
