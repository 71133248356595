import axios from 'axios'

import router from 'core/router'
import { api, combineStores } from 'core/util/vuex'

const initialState = window.STATE

const accountsStore = combineStores([
    {
        state: {
            user: initialState.user,
            upgraded: initialState.upgraded,
        },

        getters: {
            isAuthenticated: state => !!state.user,
            isSessionUpgraded: state => !!state.upgraded,
            isAdmin: state => state.user && state.user.is_admin,
            hasProfile: state => !!state.user && !!state.user.username,
        },

        mutations: {
            setAuth(state, data) {
                state.user = data.user
                state.upgraded = data.upgraded
            },

            patchCurrentUser(state, user) {
                state.user = { ...state.user, ...user }
            },

            logout(state) {
                state.user = null
                router.push('/')
                axios.post('/api/accounts/logout/')
            },
        },

        actions: {
            startLogin: api('/api/accounts/login/start/'),

            finishLogin({ commit }, { instance }) {
                const request = axios.post('/api/accounts/login/finish/', instance)
                request.then((response) => {
                    commit('setAuth', response.data)
                })
                return request
            },

            upgradeSession({ commit }, { instance }) {
                const request = axios.post('/api/accounts/login/upgrade/', instance)
                request.then((response) => {
                    commit('setAuth', response.data)
                })
                return request
            },

            changePassword: api('/api/accounts/change_password/'),

            requestPasswordReset: api('/api/accounts/reset_password/'),

            completePasswordReset({ commit }, { instance }) {
                const request = axios.post('/api/accounts/complete_reset_password/', instance)
                request.then((response) => {
                    commit('setAuth', response.data)
                })
                return request
            },

            updateProfile({ commit }, { instance }) {
                const request = axios.post('/api/accounts/update_profile/', instance)
                request.then((response) => {
                    commit('patchCurrentUser', response.data)
                })
                return request
            },
        },
    },
])

export default accountsStore
