<template lang="pug">
    v-form(:context='this' v-if='!result')
        modal-header Import {{ vendor.name }} Gift Cards

        .modal-body
            .text-center
                v-field(
                    :model='model.cards'
                    label='Select the excel sheet of gift cards'
                    hint='Please use the import gift cards template.'
                )
                    input(type='file' ref='cards')

        .modal-footer
            b-btn.mt-2(variant='primary' type='submit') Import

    div(v-else)
        modal-header {{ vendor.name }} Gift Cards Imported!

        .modal-body
            h1.text-center.text-success {{ result.added.toLocaleString() }} cards added
            .text-center
                span.text-muted {{ result.skipped.toLocaleString() }} cards skipped

        .modal-footer
            b-btn.mt-2(variant='primary' type='button' @click='$vuedals.close(this)') Great!
</template>

<script>
import axios from 'axios'

import { ModalFormMixin, FormMixin } from 'core/components/forms/mixin'
import { jsonToFormData } from 'core/util/server'

export default {
    mixins: [ModalFormMixin],

    props: ['vendor'],

    fields: [
        'cards',
    ],

    data() {
        return {
            result: null,
        }
    },

    methods: {
        sendToServer() {
            this.submitting = true
            const request = axios({
                method: 'post',
                url: `/api/admin/vendors/${this.vendor.id}/load_gift_cards/`,
                data: jsonToFormData({ cards: this.$refs.cards.files[0] }),
            })

            request
                .then((response) => {
                    try {
                        this.handleSuccess(response)
                    } catch (e) {
                        console.error(e) // eslint-disable-line
                        throw e
                    }
                })
                .catch(error => this.handleError(error))
                .then(() => { this.submitting = false })
        },

        onSubmitSuccess(data) {
            this.result = data
            FormMixin.methods.onSubmitSuccess.call(this, data)
        },
    },
}
</script>

<style lang="scss" scoped>
input {
    margin: 20px 0 20px 110px;
}
</style>
