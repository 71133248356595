<template lang="pug">
    v-form(:context='this')
        modal-header {{ mode }} Preset Category

        .modal-body
            v-field(
                :model='model.name'
                field='input'
                placeholder='Name'
                label='Name'
            )

            v-field(
                :model='model.consumer'
                hint='Determines if the category should show up in the app when sending a coffee.'
            )
                b-form-checkbox(v-model='model.consumer.value') Enabled for Consumers

            v-field(
                :model='model.corporate'
                hint='Determines if the category should show up in the corporate tools when designing a campaign.'
            )
                b-form-checkbox(v-model='model.corporate.value') Enabled for Corporate

            v-field(
                label='Corporate Targets'
                v-if='model.corporate.value'
                :model='model.corporate_flags'
                :options='corporateTargets'
                field='checkbox-group'
                hint='The category will still show up under "Everyone" even if you don\'t choose any targets.'
            )

        .modal-footer
            b-btn.mt-2(variant='primary' type='submit') Continue
</template>

<script>
import { ModalFormMixin } from 'core/components/forms/mixin'

export default {
    mixins: [ModalFormMixin],

    fields: [
        'name',
        { name: 'consumer', default: true },
        { name: 'corporate', default: true },
        { name: 'corporate_flags', default: () => [] },
    ],

    dispatch: 'savePresetCategory',

    data() {
        return {
            corporateTargets: [
                { text: 'Customers', value: 'customers' },
                { text: 'Employees', value: 'employees' },
                { text: 'Marketing', value: 'marketing' },
            ],
        }
    },

    methods: {
        clean(data) {
            if (!data.corporate) data.corporate_flags = []
            return data
        },
    },
}
</script>
