<template lang="pug">
    .form
        modal-header Create your own Design

        template(v-if='step == "emojis"')
            .modal-body
                Legend.col-form-label Choose some awesome emojis
                emoji-picker(v-model='emoji')
            .modal-footer
                b-btn(variant='primary' type='button' @click='pickBackground') {{ emoji.length ? "Continue" : "Skip" }}

        template(v-else-if='step == "background"')
            .modal-body
                Legend.col-form-label Nice choice! Now pair {{ emoji.length > 1 ? "them" : "it" }} with a sweet background.
                background-picker.mt-3(v-model='background' :emoji='emoji')
            .modal-footer
                b-btn.mr-auto(variant='primary' type='button' @click='changeBackgroundType("custom")') Custom Background
                b-btn(v-if='background' variant='primary' type='button' @click='finish') Continue

        template(v-else)
            background-form(
                corporate
                :values='custom_background'
                :emoji='emoji'
                @back='changeBackgroundType("builtin")'
                @finish='finishCustomBackground'
            )
</template>

<script>
import EmojiPicker from 'admin/components/presets/emoji-picker'
import BackgroundPicker from 'admin/components/presets/background-picker'
import BackgroundForm from 'admin/components/backgrounds/background-form'

export default {
    props: ['model', 'callback'],

    components: { EmojiPicker, BackgroundPicker, BackgroundForm },

    data() {
        return {
            step: 'emojis',
            emoji: [...this.model.emoji],
            background: this.model.background,
            custom_background: this.model.custom_background,
        }
    },

    computed: {
        header() {
            return {
                background: this.model.background.value,
                emoji: this.model.emoji.value,
            }
        },
    },

    methods: {
        pickBackground() {
            this.step = this.custom_background ? 'custom' : 'background'
        },

        changeBackgroundType(t) {
            if (t === 'builtin') {
                this.custom_background = null
                this.step = 'background'
            } else if (t === 'custom') {
                this.background = null
                this.step = 'custom'
            }
        },

        finishCustomBackground(custom) {
            this.custom_background = custom
            this.finish()
        },

        finish() {
            this.$vuedals.close(this)
            this.callback({
                emoji: this.emoji,
                background: this.background,
                custom_background: this.custom_background,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.header-preview {
    margin: 0 auto;
    width: 250px;
    max-width: 100%;
    border-radius: 14px;
    @include shadow;
}

.change-header {
    display: inline-block;
    font-size: 0.8rem;
    color: #767676;
    margin: 5px 0 15px 0;
}
</style>
